import { BaseApiClient } from "./BaseApiClient"

export class NotificationReadApiClient extends BaseApiClient<IFootprint> {

  private _notificationId: number;

  constructor(store: Store<IAppGlobalStore>, notificationId: number) {
    super(store);
    this._notificationId = notificationId;
  }

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    //console.log(`api/v1/notifications/${this._notificationId}/read`);
    return `api/v1/notifications/${this._notificationId}/read`;
  }
}
