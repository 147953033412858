import crypto from 'crypto'
import { MyProfileApiClient } from '../actions/clients/MyProfileApiClient';
import Constraints from './Constraints';
class Utils {

  static toImageUrl(url?: string): string {
    if (!url) {
      const endpoint = process.env.REACT_APP_API_ENDPOINT;
      return `${endpoint}/images/user.png`;
    }

    try {
      if (url.match(/^blob/)) {
        return url;
      }
    } catch (error) {
      //console.log(error);
    }

    const endpoint = process.env.REACT_APP_API_ENDPOINT;
    return `${endpoint}/${url}`;
  }

  static getAgeRange(): any {
    return [{id:20, name:'20代'}, {id:30, name:'30代'}, {id:40, name:'40代'}, {id:50, name:'50代'}, {id:60, name:'60代'}, {id:70, name:'70代'}]
  }

  static getLastActiveAt(): any {
    return [
      {id:'online', name:'オンライン'},
      {id:'within_24hours', name:'24時間以内'},
      {id:'within_3days', name:'3日以内'},
      {id:'within_1week', name:'1週間以内'},
      {id:'within_1month', name:'1ヶ月以内'},
      {id:'over_1month', name:'1ヶ月以上'},
    ]
  }

  static getLoginStatus(member: any) {
    try{
      const active_at = Utils.getLastActiveAt();
      for( var item in active_at ) {
        if(member.login_status === active_at[item].name) {
          return active_at[item].id;
        }
      }
    }catch(e) {
      return "";
    }
  }

  static getLoginStatusColor(member: any) {
      const login_status = Utils.getLoginStatus(member);
      var color = "#A0A0A0";
      switch (login_status) {
        case 'online':
          color = "#ffc107";
          break;
        case 'within_24hours':
          color = "#ffc107";
          break;
        case 'within_3days':
          color = "#ffc107";
          break;
        case 'within_1week':
          color = "#ff8c00";
          break;
        default:
          break;
      }
      return color
  }

  static setItem( key: string, value: any ) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static getItem( key: string ) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (error) {
    }
    return {};
  }

  static removeItem( key: string ) {
    localStorage.removeItem(key);
  }

  static clearItems() {
    localStorage.clear();
  }

  static clearLists() {
    const array = ["refresh_token", "id_token",  "MyProfile"];
    for (var i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if(array.includes(key) === false) {
        localStorage.removeItem(key);
      }
    }
  }

  static offerRelation = {
    none : 0,
    from_offer: 1,
    offerd: 2,
    matching: 3,
    from_reoffer: 4,   // 自分から逆オファー済み
    roffered: 5       // 被逆オファー
  };

  static ageVerificationStatus = {
    not_verified: 0,
    verifying: 1,
    verified: 2,
    rejected: 3
  };

  static formatDate(dt) {
    try {
      //var y = dt.getFullYear();
      var m = ('00' + (dt.getMonth()+1)).slice(-2);
      var d = ('00' + dt.getDate()).slice(-2);
      var h = ('00' + dt.getHours()).slice(-2);
      var minute = ('00' + dt.getMinutes()).slice(-2);
      var dayOfWeek = dt.getDay();	// 曜日(数値)
      var dayOfWeekStr = [ "日", "月", "火", "水", "木", "金", "土" ][dayOfWeek]
      return (m + '/' + d + '(' + dayOfWeekStr + ') ' + h + ':' + minute);
    } catch(e) {
      return ''
    }
  }

  static setJson( key: string, value: any ) {
    localStorage.setItem(key, Utils.encrypt( JSON.stringify(value) ));
  }

  static getJson( key: string ) {
    try {
      return JSON.parse( Utils.decrypt(localStorage.getItem(key)) );
    } catch (error) {
      //console.log(error);
    }
    return {};
  }

  static removeJson( key: string ) {
    localStorage.removeItem(key);
  }

  // 暗号化キー
  static encKeys() {
    return Constraints.encKeys();
  }

  // https://www.greptips.com/posts/1345/
  // 暗号化
  static encrypt(originalText) {
      const encKeys = Utils.encKeys();
      const key = encKeys['key'];
      const algorithm = encKeys['algorithm'];
      const delimiter = encKeys['delimiter'];

      const iv = crypto.randomBytes(16);
      const cipher = crypto.createCipheriv(algorithm, key, iv);
      const encrypted = cipher.update(originalText, 'utf8', 'base64') + cipher.final('base64');
      const ivWithEncrypted = iv.toString('base64') + delimiter + encrypted;
      return ivWithEncrypted;
  }

  // 復号化
  static decrypt(ivWithEncrypted) {
      const encKeys = Utils.encKeys();
      const key = encKeys['key'];
      const algorithm = encKeys['algorithm'];
      const delimiter = encKeys['delimiter'];

      const [iv, encrypted] = ivWithEncrypted.split(delimiter);
      const decipher = crypto.createDecipheriv(algorithm, key, Buffer.from(iv, 'base64'));
      const decrypted = decipher.update(encrypted, 'base64', 'utf8') + decipher.final('utf8');
      return decrypted;
  }

  static async getMyProfile(globalStore) {
    const myProfile = await new MyProfileApiClient(globalStore).get();
    //console.log("==== myProfile");
    //console.log(myProfile);
    Utils.setJson('MyProfile', myProfile);
    return myProfile;
  }

  static loadMyProfile() {
    return Utils.getJson('MyProfile');
  }

  static convertMinuteToMilliseconds(min: number) {
    return min * 60 * 1000;
  }
}

export default Utils;
