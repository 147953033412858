import React from 'react';
import { Button, Form, Nav, Navbar } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BaseForm';
import { MyProfileApiClient } from '../../actions/clients/MyProfileApiClient';
import { ProfileTweetApiClient } from '../../actions/clients/profile/ProfileTweetApiClient';
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import LoadingIndicator from '../common/LoadingIndicator';

interface IState extends common.IState {
}

class MyProfileTweetPage extends common.BaseForm<IMember, IProps, IState> {
  private title = "つぶやき編集";

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    //document.title = this.title;;

    const myProfile = await new MyProfileApiClient(this.props.globalStore).get();
    myProfile.tweet_text = myProfile.tweet?.content

    this.setState({ resource: myProfile });
  }

  render() {
    return (
      <React.Fragment>
        <Navbar className="justify-content-center" fixed="top" bg="dark" variant="dark">
          <Navbar.Collapse className="container-fluid">
            <Nav>
              <Button onClick={(e: any) => { e.preventDefault(); this.props.history.push('/myprofile'); }} className="bg-dark" >
                <FontAwesomeIcon icon={faChevronLeft}  size="sm"  style={{ color: "#A1A1A1" }}/>
              </Button>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Brand>{this.title}</Navbar.Brand>
          <Navbar.Collapse className="container-fluid">
            <Nav className="ml-auto">
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <div style={{ height: 10 }}></div>

        <LoadingIndicator watch={this.state.resource}>
          <Form>
            <Form.Group>
              <Form.Label>つぶやき</Form.Label>
              <Form.Control type="text" name="tweet_text" onChange={(e) => this.changeResource(e)}
                    value={this.state.resource?.tweet_text}
                    isInvalid={!!this.state.errors?.tweet_text}
                    style={{ backgroundColor: "#090d13", color: "#fff" }} />
            </Form.Group>
            <span style={{ textAlign: "right" }} >
            <Button type="submit" disabled={!this.state.resource?.tweet_text} onClick={(e: any) => this.submit(e)}>送信</Button>
            </span>
          </Form>
        </LoadingIndicator>

      </React.Fragment>
    );
  }

  // つぶやき更新
  async submit(e: any) {
    e.preventDefault();

    var params = {
      "member": {
        "tweet": this.state.resource.tweet_text
      }
    };

    const props = this.props
    await new ProfileTweetApiClient(this.props.globalStore).update(params);
    //if (data.result == true) {
        Swal.fire({
          title: '',
          text: "つぶやきを更新しました",
          type: 'success',
        }).then((result) => {
          props.history.push('/myprofile');
        })
    /*} else {
      Swal.fire({
        title: '',
        text: data.message,
        type: 'success',
      })
    }*/

  }

}

export default withRouter(AppDataStore.withStores(MyProfileTweetPage));
