import React from 'react';
import * as common from '../common/BaseComponent';
import { Nav, NavItem, NavLink } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faHeart, faUser, faEnvelope, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import Utils from '../../utils/Utils';
import ReactHtmlParser from 'react-html-parser';

interface IProps extends common.IProps {
  selectIndex?: number;
}

interface IState extends common.IState {
}

class Footer extends common.BaseComponent<IProps, IState> {
  private tab = [];
  private tabs = [{
      route: "/search",
      icon: faSearch,
      label: "さがす",
      key: "",
    },{
      route: "/offers",
      icon: faHeart,
      label: "いいね",
      key: "has_new_offer",
    },{
      route: "/rooms",
      icon:  faEnvelope,
      label: "メッセージ",
      key: "has_new_message",
    },{
      route: "/bbs",
      icon:  faFileAlt,
      label: "掲示板",
      key: ""
    },{
      route: "/mypage",
      icon: faUser,
      label: "マイページ",
      key: "has_new_notification"
    }]

  constructor(props: IProps) {
    super(props);
    this.state = {
      myProfile :  Utils.loadMyProfile()
    };
  }

  componentDidMount() {
    //const myProfile = Utils.loadMyProfile();
    //this.setState({ myProfile :  Utils.loadMyProfile() });
  }

  // バッヂ表示
  private showBadge(key) {
    try{
      if(this.state.myProfile[key]===true) {
        return ReactHtmlParser('<span class="badge badge-primary footer_nav_badge"> </span>');
      }
    } catch(e) {}
    return '';
  }

  render() {
    return (
      <React.Fragment>
      <nav className="navbar fixed-bottom navbar-light" role="navigation" style={{ maxHeight: "55px", borderTop: "solid 0.2px gray" }}>
        <Nav className="w-100">
          <div className=" d-flex flex-row justify-content-around w-100">
          {
            this.tabs.map((tab, index) =>(
              <NavItem key={`tab-${index}`}>
                <NavLink href={tab.route} className="nav-link" active={index === this.props.selectIndex} >

                  <div className="row d-flex flex-column justify-content-center align-items-center">
                    <FontAwesomeIcon size="lg" icon={tab.icon} />
                    <div style={{ fontSize: "10px" }}>
                      {this.showBadge(tab.key)}
                      {tab.label}
                    </div>
                  </div>

                </NavLink>
              </NavItem>
            ))
          }
          </div>
        </Nav>
      </nav>

      </React.Fragment>
    );
  }
}

export default withRouter(AppDataStore.withStores(Footer));
