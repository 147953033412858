import React from 'react';
import { Button, Image, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import MyPageHeader from './MyPageHeader';
import Footer from '../nav/Footer';
import Masonry from "react-masonry-css"
import IMyProfile from "../resources/IMyProfile"
import { ScrollView } from "@cantonjs/react-scroll-view";
import Utils from '../../utils/Utils';
import { MyProfileApiClient } from '../../actions/clients/MyProfileApiClient';
import { ProfileTodaysApiClient } from '../../actions/clients/profile/ProfileTodaysApiClient';
import { MemberDetailApiClient } from '../../actions/clients/MemberDetailApiClient';
import ReactHtmlParser from 'react-html-parser';
import FireBaseLogEventManager from '../../utils/FireBaseLogEventManager';
import FireBaseLogEvent from '../../utils/enums/FireBaseLogEvent';

interface IState extends common.IState {
  isDialogOpen: boolean;
  myProfileData: IMyProfile
}

class MyPage extends common.BasePage<common.IProps<common.IParams>, IState> {

  events = [
    {
      "title": "お知らせ",
      "image": "/images/mypage/icon-notify.png",
      "url": "/notification",
      "key": "has_new_notification"
    },
    {
      "title": "お気に入り",
      "image": "/images/mypage/icon-favorite.png",
      "url": "/favorite",
      "key": ""
    },
    {
      "title": "足あと",
      "image": "/images/mypage/foot.png",
      "url": "/footprint",
      "key": "has_new_footprint"

    },
    {
      "title": "ポイント購入",
      "image": "/images/mypage/icon-purchase-point.png",
      "url": "/point",
      "key": ""
    },
    {
      "title": "設定",
      "image": "/images/mypage/icon-setting.png",
      "url": "/setting",
      "key": ""
    },
    {
      "title": "ヘルプ",
      "image": "/images/mypage/icon-help.png",
      "url": "/help",
      "key": ""
    }
  ]
  // 1行に表示するカラム数
  breakpointColumnsObj = {
    default: 3
  }

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = { isDialogOpen: false, isMemberDialogOpen: false,
                    memberButtonName: "　　　", memberButtonDisabled: true,
                    myProfileData: Utils.loadMyProfile() };
  }

  async componentDidMount() {
    const myProfile = await Utils.getMyProfile(this.props.globalStore);
    //console.log(myProfile);
    this.setState({ myProfileData: myProfile });
    this.setMemberStatus();
  }

  // バッヂ表示
  private showBadge(key) {
    try{
      if(this.state.myProfileData[key]===true) {
        return ReactHtmlParser('<span class="badge badge-primary footer_nav_badge"> </span>');
      }
    } catch(e) {}
    return '';
  }

  render() {
    return (
      <React.Fragment>
        <MyPageHeader onPreview={() => this.onPreview()} />

        <div style={{ height: 0 }}></div>

        <Row>
          <Col xs={12} md={12}>
            <div className="boxP">
              <div className="boxP1">
                <Image width={80} height={80} style={{ height: "auto" }} src={Utils.toImageUrl(this.state.myProfileData?.profile_image_authenticated)} />
                <br />
                {this.state.myProfileData?.point}ポイント
              </div>
              <div className="boxP2">
                <div className="box_profile_text">
                  &nbsp;{this.state.myProfileData?.name}&nbsp;&nbsp;{this.state.myProfileData?.age}歳&nbsp;&nbsp;{this.state.myProfileData?.prefecture}
                </div>
              </div>
              <div className="boxP3">
                今日会える<br />
                  <center>
                    <label class="todaySwitchWrapper">
                      <input type="checkbox" class="switchInput" checked={this.state.myProfileData?.today_ok} onChange={(e: any) => this.toggleToday()}/>
                      <div class="todaySwitchDisplay">
                        <div class="switchStatus centerPlace">{this.state.myProfileData?.today_ok? "ON" : "OFF"}</div>
                        <div class="switchBall"></div>
                      </div>
                    </label>
                  </center>

              </div>
            </div>
          </Col>
        </Row>

        <div style={{ height: 5 }}></div>
        <Row>
          <Col xs={12} md={12}>
            <div className="boxA">
              <div className="box1">
                <Button className="profile_button button-dark" bf="dark" onClick={(e: any) => this.toMyProfile(e)}>プロフ編集</Button>
              </div>
              <div className="box2">
                <Button className="member_button button-dark" disabled={this.state.memberButtonDisabled}
                  onClick={(e: any) => this.toAgeOrMember(e)}>
                  {this.state.memberButtonName}
                </Button>
              </div>
            </div>
          </Col>
        </Row>

        <div style={{ height: 20 }}></div>

        <Row>
          <Col xs={12} md={12}>
            <Masonry
              breakpointCols={this.breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {this.events.map(e => {
                return e.url === '/point' && this.state.myProfileData.sex === '女性' ? (
                  <div className='event-card' key={e.url}>
                    <Image width={40} height={40} src={e.image} />
                    <h3>{this.showBadge(e.key)}{e.title}</h3>
                  </div>
                ) : (
                  <div className='event-card' key={e.url}>
                    <LinkContainer to={e.url}>
                      <Image width={40} height={40} src={e.image} />
                    </LinkContainer>
                    <LinkContainer to={e.url}>
                      <h3>{this.showBadge(e.key)}{e.title}</h3>
                    </LinkContainer>
                  </div>
                )
              })}
            </Masonry>
          </Col>
        </Row>

        <div style={{ height: 10 }}></div>

        <ScrollView  style={{ height: '300px', paddingLeft: "10px" }}>
          <span className="mypageinfo">
            ■安心・安全の取り組み<br />
            安心・安全な出会いをサポートするために以下の取り組みを行っております。<br /><br />
            １．年齢確認書類提出の必須化<br />
            サービスの利用には公的書類での年齢確認を必須としております。<br /><br />
            ２．違反行為への速やかな対応<br />
            利用規約に基づいて、違反行為や違反報告のあった会員に対して速やかに強制退会などの対応をいたします。<br /><br />
            ３．24時間365日のパトロール監視<br />
            24時間365日体制で監視パトロールをおこない、不正利用者への適切な対応や、公序良俗に反する内容の画像や文章、あからさまに性的またわいせつなコンテンツの配信を削除・注意しています。<br /><br />
            ■お相手に直接お会いになる際は<br />
            ・直接お会いになる際は、なるべく人気の多い場所や店舗を選びましょう（個室や人気のない場所、地下のお店など閉鎖的な場所は避けましょう）。<br />
            ・お相手が信頼できる人だと確信できるまでは、あなたの個人情報は教えないようにしてください。<br />
            ・判断力が落ちるほどの飲酒は控えましょう。<br />
            ・犯罪の可能性が高い場合は、すぐに警察へ届けてください。<br />
            <br /><br /><br />
          </span>
        </ScrollView>

        <Footer selectIndex={4} />
      </React.Fragment>
    );
  }

  private setMemberStatus() {
    this.setState({ memberButtonDisabled: false });

    var buttonName = '';
    switch (this.state.myProfileData.age_verification) {
      case Utils.ageVerificationStatus.not_verified:
        buttonName = '年齢未確認';
        break;
      case Utils.ageVerificationStatus.rejected:
        buttonName = '年齢未確認';
        break;
      case Utils.ageVerificationStatus.verifying:
        buttonName = '年齢審査中';
        break;
      case Utils.ageVerificationStatus.verified:
        buttonName = '年齢確認済';
        if(this.state.myProfileData.sex === '女性') {
          this.setState({ memberButtonDisabled: true });
        }
        if(this.state.myProfileData.sex === '男性') {
          if (this.state.myProfileData.paid === false) {
            buttonName = '無料会員';
          } else {
            if (this.state.myProfileData.premium_plan_enabled === false) {
              buttonName = '有料会員';
            } else {
              buttonName = 'プレミアム会員';
              this.setState({ memberButtonDisabled: true });
            }
          }
        }
        break;
      default:
        break;
    }
    this.setState({ memberButtonName: buttonName });
  }

  private toAgeOrMember(e: any) {
    e.preventDefault();

    switch (this.state.myProfileData.age_verification) {
      case Utils.ageVerificationStatus.not_verified:
        this.props.history.push('/ageverificationimageselect');
        break;
      case Utils.ageVerificationStatus.rejected:
        this.props.history.push('/ageverificationimageselect');
        break;
      case Utils.ageVerificationStatus.verifying:
        //buttonName = '年齢審査中';
        return;
      case Utils.ageVerificationStatus.verified:
        //buttonName = '年齢確認済';
        if(this.state.myProfileData.sex === '女性') {
          return;
        }
        if(this.state.myProfileData.sex === '男性') {
          if (this.state.myProfileData.paid === false) {
            //buttonName = '無料会員';

            // log event
            FireBaseLogEventManager.sendLogEvent(FireBaseLogEvent.myPageNormalMemberClick);

            this.props.history.push('/memberpurchase');
          } else {
            if (this.state.myProfileData.premium_plan_enabled === false) {
              //buttonName = 'プレミアム購入';
              this.props.history.push('/memberpurchase');
            } else {
              //buttonName = 'プレミアム会員';
              //this.setState({ memberButtonDisabled: true });
              this.props.history.push('/memberpurchase');
            }
          }
        }
        break;
      default:
        break;
    }
  }

  async toggleToday() {
    await new ProfileTodaysApiClient(this.props.globalStore).update({});
    const myProfile = await new MyProfileApiClient(this.props.globalStore).get();
    //console.log(myProfile);
    this.setState({ myProfileData: myProfile });
  }

  private async onPreview() {
    //this.props.history.push(`/members/${this.state.myProfileData?.id}`);

    const member = await new MemberDetailApiClient(this.props.globalStore, this.state.myProfileData?.id).get();
    this.props.history.push({
      pathname: `/members/${member?.id}`,
      state: { member: member }
    });

  }

  // ダイアログを開く
  private openMemberDialog = () => {
    this.setState({isMemberDialogOpen: true});
  }

  // ダイアログからのコールバックでダイアログを閉じてあげる
  private closeMemberDialog = () => {
    this.setState({isMemberDialogOpen: false});
  }

  // プロフィール編集
  private toMyProfile(e: any) {
    e.preventDefault();
    this.props.history.push('/myprofile');
  }

}

export default withRouter(AppDataStore.withStores(MyPage));
