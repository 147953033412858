import { IMember } from "../../resources/IMember";
import { BaseApiClient } from "./BaseApiClient"

export class MemberDetailApiClient extends BaseApiClient<IMember> {
  private _memberId: number;

  constructor(store: Store<IAppGlobalStore>, memberId: member) {
    super(store);
    this._memberId = memberId;
  }

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    //console.log(`api/v1/members/${this._memberId}`);
    return `api/v1/members/${this._memberId}`;
  }
}
