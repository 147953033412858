import * as common from '../common/BasePage';
import { Col, Row } from 'react-bootstrap';
import AppDataStore from '../../stores/AppDataStore';
import { withRouter } from 'react-router';
import { IAppConfigData } from '../../stores/IAppConfigData';
import React from 'react';
import MaintenancePageHeader from './MaintenancePageHeader';

interface IParams extends common.IParams {
}

interface IProps extends common.IProps<IParams> {
}

interface IState extends common.IState {
  isOpen: boolean;
  message: string;
  configData?: IAppConfigData;
}

class MaintenancePage extends common.BasePage<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isOpen: false,
      message: '',
      configData: props.globalStore.get('configData')
    };
  }

  componentDidMount() {
    this.loadMaintenanceConfig();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    if (!this.state.configData?.isLoaded)
      this.loadMaintenanceConfig();
  }

  private loadMaintenanceConfig() {
    const configData = this.props.globalStore.get('configData');

    if (!configData.isLoaded || !configData.config) return;

    this.setState({
      isOpen: Boolean(configData.config[this.getMaintenanceKey()]),
      message: configData.config.web_maintenance_message ?? '',
      configData: configData
    });
  }

  private getMaintenanceKey() {
    if( window.location.host.indexOf('staging') !== -1 || window.location.host.indexOf('localhost') !== -1) {
      return 'web_is_maintenance_on_develop';
    }
    return 'web_is_maintenance_on_production';
  }

  render() {
    return (
      <React.Fragment>
        <MaintenancePageHeader />

        <Row>
          <Col xs={12} md={12}  className="maintenance-top">
            {this.state.message}
          </Col>
        </Row>

      </React.Fragment>
    );
  }
}

export default withRouter(AppDataStore.withStores(MaintenancePage));
