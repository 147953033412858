import { BaseApiClient } from "../BaseApiClient"

export class ResetPasswordPhoneApiClient extends BaseApiClient<IOption> {

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    return 'api/v1/members/passwords/sms/verification';
  }
}
