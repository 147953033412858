import * as React from 'react';
import { Form, Col, Row, Button } from 'react-bootstrap';
import ReactModal from 'react-modal';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import MasterDataSelector from '../common/form/MasterDataSelector';
//import MasterDataSelectorMultiple from '../common/form/MasterDataSelectorMultiple';
import GeneralDataSelector from '../common/form/GeneralDataSelector';
import GeneralCheckBox from '../common/form/GeneralCheckBox';
import { InputEventReflector } from '../common/InputEventReflector';
import Utils from '../../utils/Utils';
import { IMemberSearchCondition } from '../../resources/IMemberSearchCondition';
import { ScrollView } from "@cantonjs/react-scroll-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface Props {
  /** このダイアログを表示するなら true */
  isOpen: boolean;
  /** このダイアログを閉じるときのコールバック */
  onClose?: () => void;

  condition?: IMemberSearchCondition;
}

interface State {
  condition: IMemberSearchCondition;
}

class SearchConditionDialog extends React.Component<Props, State> {
  private _reflector: InputEventReflector;
  private _age_range = Utils.getAgeRange()
  private _last_active_at = Utils.getLastActiveAt()

  constructor(props: Props) {
    super(props);
    this._reflector = new InputEventReflector();
    this.state = { condition: this.props.condition || {} };
    ReactModal.setAppElement('body');
  }

  componentDidMount() {
    this.setState({ scrollHeight: window.innerHeight * 0.8 });

    const option = this.props.globalStore.get('masterData').option;

    // マスタのキー
    const profile_keys = ["body_types", "annual_incomes", "drinking_types", "smoking_types", "heights", "prefectures", "desired_dating_plans", "hometowns", "occupations"]
    // 該当するプロフィールの項目
    const profile_ids = ["body_type", "annual_income", "drinking_type", "smoking_type", "height", "prefecture", "desired_dating_plan", "hometown", "occupation"]

    try {
      // APIからは項目名で来るのでマスタのidに置き換える
      for(const k in profile_keys) {
        const profile_id = profile_ids[k];
        var values = option[profile_keys[k]];
        // 職業は性別で分かれているので選択肢を先に取得しておく
        if ( profile_id === "occupation" ) {
          if (this.resource?.sex === '男性') {
            values = option["occupations"]["male"];
          } else {
            values = option["occupations"]["female"];
          }
          this.setState({ occupations: values});
        }
        try {
          const opt = values.filter(opt => opt['name'] === this.state.resource[profile_id])[0]
          if (opt) {
            this.state.resource[profile_id] = opt['id'];
          }
        } catch (error) {
          //console.log(error);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  }

  /*
  async componentDidUpdate(prevProps) {
    //console.log("componentDidUpdate");
    //console.log("componentDidUpdate");
    // 典型的な使い方(props を比較することを忘れないでください)
    if (this.props.member.id !== prevProps.member.id) {

    }
    ////console.log(this.props.globalStore.get('memberSearchCondition'));
  }
  */

  // スタイルのカスタマイズ
  private customStyles: ReactModal.Styles = {
    // ダイアログ内のスタイル（中央に表示）
    content: {
      width: '80%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: "#090d13",
      color: "#fff"
    },
    // 親ウィンドウのスタイル（ちょっと暗くする）
    overlay: {
      background: 'rgba(0, 0, 0, 0.2)'
    }
  }

  //public render(): React.ReactNode {
  render() {

    return <div>
      <ReactModal
        isOpen={this.props.isOpen}
        onAfterOpen={this.handleOpen}
        onRequestClose={this.handleClose}
        style={this.customStyles}
        contentLabel="Settings"
      >
      <Row>
        <Col xs={11} md={11}>
        </Col>
        <Col xs={1} md={1}>
          <Button onClick={(e: any) => this.handleClose(e)} className="bg-dark"
            style={{ justifyContent: "right", marginRight: '0.0em', marginTop: '0.2em'}}>
            <FontAwesomeIcon icon={faTimes}  size="sm" style={{ color: "#A1A1A1" }} />
          </Button>
        </Col>
      </Row>
      <ScrollView style={{ height: this.state.scrollHeight, paddingLeft: "1px" }}>

        <Form>
        <Row>
          <Col xs={6} md={6} key={1}>
            <Form.Group>
              <GeneralCheckBox property="profile_image_approved" label="今日会える" onClick={(e) => this.changeCondition(e)} />
            </Form.Group>
          </Col>
          <Col xs={6} md={6} key={2}>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={6} key={1}>
            <Form.Group>
              <Form.Label>年齢〜</Form.Label>
              <Form.Control className="bg-dark text-light" type="number" name="age" onChange={(e) => this.changeResource(e)}
                value={this.state.resource?.age} isInvalid={!!this.state.errors?.height}/>
            </Form.Group>
          </Col>
          <Col xs={6} md={6} key={2}>
            <Form.Group>
            <Form.Label>〜年齢</Form.Label>
            <Form.Control className="bg-dark text-light" type="number" name="age" onChange={(e) => this.changeResource(e)}
              value={this.state.resource?.age} isInvalid={!!this.state.errors?.height}/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={6} key={1}>
            <Form.Group>
              <Form.Label>身長〜</Form.Label>
              <Form.Control className="bg-dark text-light" type="number" name="height" onChange={(e) => this.changeResource(e)}
                value={this.state.resource?.height} isInvalid={!!this.state.errors?.height}/>
            </Form.Group>
          </Col>
          <Col xs={6} md={6} key={2}>
            <Form.Group>
            <Form.Label>〜身長</Form.Label>
            <Form.Control className="bg-dark text-light" type="number" name="height" onChange={(e) => this.changeResource(e)}
              value={this.state.resource?.height} isInvalid={!!this.state.errors?.height}/>
            </Form.Group>
          </Col>
        </Row>
          <Row>
            <Col xs={6} md={6} key={1}>
              <Form.Group>
                <Form.Label>住んでいる地域</Form.Label>
                <MasterDataSelector property="prefecture_id_in" masterDataKey="prefectures" value={this.state.condition?.prefecture_id_in} onChanged={(e) => this.changeCondition(e)}/>
              </Form.Group>
            </Col>
            <Col xs={6} md={6} key={2}>
              <Form.Group>
              <Form.Label>出身地</Form.Label>
                <MasterDataSelector property="hometown_id_in" masterDataKey="prefectures" value={this.state.condition?.hometown_id_in} onChanged={(e) => this.changeCondition(e)}/>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group>
            <Form.Label>年収</Form.Label>
            <MasterDataSelector property="annual_income_id_eq" masterDataKey="annual_incomes" value={this.state.condition?.annual_income_id_eq} onChanged={(e) => this.changeCondition(e)}/>
          </Form.Group>
          <Form.Group>
            <Form.Label>体型</Form.Label>
              <MasterDataSelector property="body_type" masterDataKey="body_types" value={this.state.resource?.body_type}
             errors={this.state.errors?.body_type} onChanged={(e) => this.changeResource(e, { type: 'number' })}/>
            </Form.Group>
          <Form.Group>
            <Form.Label>お酒</Form.Label>
            <MasterDataSelector property="drinking_type" masterDataKey="drinking_types" value={this.state.condition?.drinking_type} onChanged={(e) => this.changeCondition(e)}/>
          </Form.Group>
          <Form.Group>
            <Form.Label>タバコ</Form.Label>
            <MasterDataSelector property="smoking_type" masterDataKey="smoking_types" value={this.state.condition?.smoking_type} onChanged={(e) => this.changeCondition(e)}/>
          </Form.Group>

          <Form.Group>
            <Form.Label>職業</Form.Label>

            <GeneralDataSelector property="occupation"
            masterDataKey={`occupations.${this.state.resource?.sex}`}
            data={this.state.occupations}
            value={this.state.resource?.occupation}
            errors={this.state.errors?.occupation} onChanged={(e) => this.changeResource(e, { type: 'number' })}/>

          </Form.Group>

          <Row>
            <Col xs={4} md={4}></Col>
            <Col xs={4} md={4}>
              <center>
                <Button style={{ whiteSpace: "nowrap" }} onClick={(e: any) => this.send(e)}>送信</Button>
              </center>
            </Col>
            <Col xs={4} md={4}></Col>
          </Row>

        </Form>
      </ScrollView>
      </ReactModal>
    </div>;
  }

  private changeCondition(e: any) {
    const condition = this.state.condition;
    if (condition) {
      const result = this._reflector.execute(condition!, e);
      if (result) {
        this.setState({ condition: result || condition });
      }
    }
    //console.log("this.state.condition");
    //console.log(this.state.condition);
  }

  private send = (e: any) => {
    this.props.closeDialogWithSearch(this.state.condition);
  }

  private reset = (e: any) => {
    Utils.setItem('memberSearchCondition', {});
    this.setState({ condition: {} });
    //document.forms[0].reset();
    this.props.dialogReset();
  }

  private subumit = (event: any) => {
  }

  // フォームのサブミット時にダイアログを閉じる
  /*private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.handleClose();
  }*/

  // ダイアログが開いたときに呼び出される
  private handleOpen = () => {
    // ここで設定情報などを読み込む
  }

  // ダイアログ領域外のクリックや、ESCキーを押したときに呼び出される
  private handleClose = () => {
    // 親コンポーネントにダイアログを閉じてもらうためのコールバック通知
    this.props.onClose?.();
    //this.onSearch()
  }

  private onSearch () {
    this.props.onSearch();
  }

}



export default withRouter(AppDataStore.withStores(SearchConditionDialog));
