import React from "react";
import { Modal, Form, Button } from 'react-bootstrap';

// https://react-bootstrap.github.io/components/modal/

import { Subscription } from 'rxjs';
import AppDataStore from '../../stores/AppDataStore';
import { IAppUiLocker } from '../../stores/IAppUiLocker';
import * as common from '../common/BaseComponent';
//import * as common from '../common/BaseForm';
import { IMember } from '../../resources/IResource';
import ValidationErrorFeedback from '../common/ValidationErrorFeedback';

interface IProps extends common.IProps {
};

interface IState extends common.IState {
}

class WatchThisPopUp extends common.BaseComponent<IProps, IState> {
  private subscription: Subscription | null = null;

  constructor(props: IProps) {
    super(props);
    this.state = {
      show: false
    };
  }

  private handleShow() {
    this.setState({ show: true });
  }

  private handleClose() {
    this.setState({ show: false });
  }

  render() {
    return (
      <>
        <Button variant="primary" onClick={() => this.handleShow()} >
          みてね！
        </Button>

        <Modal show={this.state.show} >
          <Modal.Header closeButton onClick={() => this.handleClose()}>
            <Modal.Title>みてね！を送る</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ testAlign: "center"}}>
            <center>
              <strong>みてね！を送ると上位に表示され、<br />異性によりアピールできます。</strong>
              <br />無料会員は10ポイント消費します
              <br />＊有料会員は送り放題です
            </center>
            <span style={{ fontSize: "12px", display: "flex", justifyContent: "right", alignItems: "flex-end"　}}>
              200文字以内
            </span>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control as="textarea" rows={3} name="additional_message" onChange={(e) => this.changeResource(e)}
                              value={this.state.additional_message}
                               placeholder="あなたの思いや希望を伝えましょう！
                                          ＊電話番号やメールアドレス、LineやカカオのID、その他SNSのIDなどあなたを特定できる個人情報の送信はできません"
                               />
                <ValidationErrorFeedback errors={this.state.errors?.additional_message}/>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleClose()}>
              キャンセル
            </Button>
            <Button variant="primary"
              disabled={!this.state.additional_message || this.state.additional_message.length > 200 }
              onClick={() => this.onSubmit()}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  protected changeResource(e: any, options?: InputEventReflectorOptions) {
    // 変数名なので[]をつける
    this.setState({ [e.target.name] : e.target.value });
  }

  private onSubmit() {
    this.props.onWatchThis?.(this.state.additional_message);
    this.handleClose();
  }
}

export default AppDataStore.withStores(WatchThisPopUp);
