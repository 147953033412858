import React from 'react';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import FavoriteListPageHeader from './FavoriteListPageHeader';
import FavoriteList from './FavoriteList';
import { IMember } from '../../resources/IMember';
import { IRoom } from '../../resources/IRoom';

interface IState extends common.IState {
}

class FavoriteListPage extends common.BasePage<common.IProps<common.IParams>, IState> {

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {;

  }

  render() {
    return (
      <React.Fragment>
        <FavoriteListPageHeader />

        <div style={{ height: 0 }}></div>

        <FavoriteList page={this.state.page} onResourceSelected={(member) => this.onMemberSelected(member)}  />

      </React.Fragment>
    );
  }

  public onMemberSelected(member?: IMember) {
    //this.setState({ member: member });
    //console.log(member.member_id);

    this.props.history.push({
      pathname: `/members/${member?.member_id}`,
      state: { member: member }
    });
  }

  private onSelectRoom(room: IRoom) {
    this.props.history.push({
      pathname: `/rooms/${room.id}`,
      state: { room: room }
    });
  }

}

export default withRouter(AppDataStore.withStores(FavoriteListPage));
