import React from 'react';
import moment from "moment";
import { Button, Col, Form, Jumbotron, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
//import * as common from '../common/BasePage';
import * as common from '../common/BaseForm';
import SystemNotification from '../common/SystemNotification';
import SexSelector from '../common/form/SexSelector';
import MasterDataSelectorDefault from '../common/form/MasterDataSelectorDefault';
import ValidationErrorFeedback from '../common/ValidationErrorFeedback';
import { OptionApiClient } from '../../actions/clients/OptionApiClient';
import { MemberRegistApiClient } from '../../actions/clients/regist/MemberRegistApiClient'
import { UserSession } from '../../actions/session/UserSession';
import Swal from 'sweetalert2'
import FireBaseLogEventManager from '../../utils/FireBaseLogEventManager';
import FireBaseLogEvent from '../../utils/enums/FireBaseLogEvent';

interface IParams extends common.IParams {
}

interface IProps extends common.IProps<IParams> {
}

interface IState extends common.IState {
}

class RegisterInfoPage extends common.BaseForm<IProps, IState> {

  private _session: UserSession;

  constructor(props: IProps) {
    super(props);

    this._session = new UserSession(this.props.globalStore);

    this.state = {
      phone: this.props.location.state.phone,
      sms: this.props.location.state.sms,
      resource: {
        birth_day: "2000-01-01",
        name: "",
        password: "",
        password_confirm: "",
        prefecture: "",
        sex: "female",
      }
    };
  }

  async componentDidMount() {
    //console.log(this.props.globalStore.get('masterData'));
    if (!this.props.globalStore.get('masterData')?.option) {
      const option = await new OptionApiClient(this.props.globalStore).get();
      if (option) {
        ////console.log(option);
        this.props.globalStore.set('masterData')({
          isLoaded: true,
          option: option
        });
        //console.log("this.props.globalStore");
        //console.log(this.props.globalStore);
      }
    }
  }

  render() {
    return (
      <Row>
        <Col sxs={12} md={12}>
          <Jumbotron>
            <SystemNotification/>
            <Form className="mt-5">
              <Form.Group>
                <Form.Label>ニックネーム</Form.Label>
                <Form.Control type="text" name="name" onChange={(e) => this.changeResource(e)} value={this.state.resource?.name} isInvalid={!!this.state.errors?.name}/>
                <ValidationErrorFeedback errors={this.state.errors?.name}/>
              </Form.Group>

              <Form.Group>
                <Form.Label>性別</Form.Label>
                <SexSelector value={this.state.resource?.sex} errors={this.state.errors?.sex} onChanged={(e) => this.changeSex(e)} readonly={this.isEditMode}/>
              </Form.Group>

              <Form.Group>
                <Form.Label>誕生日</Form.Label>
                <Form.Control type="date" name="birth_day" onChange={(e) => this.changeBirthDay(e)} value={this.state.resource?.birth_day} isInvalid={!!this.state.errors?.birth_day}/>
                <ValidationErrorFeedback errors={this.state.errors?.birth_day}/>
              </Form.Group>
              {this.state.age && (
                <Form.Group>
                  <Form.Label>年齢</Form.Label>
                  <Form.Control plaintext readOnly value={this.state.age} />
                </Form.Group>
              )}

              <Form.Group>
                <Form.Label>住んでいる地域</Form.Label>
                <MasterDataSelectorDefault property="prefecture" masterDataKey="prefectures" value={this.state.resource?.prefecture} errors={this.state.errors?.prefecture} onChanged={(e) => this.changeResource(e, { type: 'number' })}/>
              </Form.Group>

              <Form.Group>
                <Form.Label>パスワード</Form.Label>
                <Form.Control type="password" name="password" onChange={(e) => this.changeResource(e)} value={this.state.resource?.password} isInvalid={!!this.state.errors?.password}/>
                <ValidationErrorFeedback errors={this.state.errors?.password}/>
              </Form.Group>

              <Form.Group>
                <Form.Label>パスワード</Form.Label>
                <Form.Control type="password" name="password_confirm" onChange={(e) => this.changeResource(e)} value={this.state.resource?.password_confirm} isInvalid={!!this.state.errors?.password_confirm}/>
                <ValidationErrorFeedback errors={this.state.errors?.password_confirm}/>
              </Form.Group>

              <Form.Group>
                <Form.Label><span style={{ fontSize: "12px" }}></span></Form.Label>
                <Button type="submit" variant="primary" size="lg" block
                  disabled={!this.state.resource?.name || !this.state.resource?.sex || !this.state.resource?.birth_day || !this.state.resource?.prefecture || !this.state.resource?.password || !this.state.resource?.password_confirm}
                   onClick={(e) => this.registInfo(e)}>登録</Button>
              </Form.Group>
            </Form>
          </Jumbotron>
        </Col>
      </Row>
    );
  }

  private changeSms(e: any) {
    this.setState({ sms: e.target.value });
  }

  private async registInfo(e: any) {
    e.preventDefault();
    //console.log(this.state);

    // log event
    FireBaseLogEventManager.sendLogEvent(FireBaseLogEvent.signUpCompleteButtonClick);

    var params = {
      "member": {
        "name": this.state.resource.name,
        "phone_number": this.state.phone,
        "sex": this.state.resource.sex,
        "birth_day": this.state.resource.birth_day.replaceAll('-','/'),
        "prefecture_id": this.state.resource.prefecture,
        "password": this.state.resource.password,
        "password_confirmation": this.state.resource.password_confirm
      }
    };
    //console.log(params);

    const data = await new MemberRegistApiClient(this.props.globalStore).post(params);//.updateWithoutID(params);
    console.log(data);
    //console.log(data);
    if (data?.result === true) {

      Swal.fire({
        title: 'プロフィールを登録しました',
        text: `${data.data.point_bonus}ポイント付与されました `,
        type: 'success',
      }).then((result) => {
        this.goMyPage();
      });

    }

  }

  private async goMyPage() {
    if (await this._session.signIn(
                                    {
                                      "auth": {
                                        "phone_number": this.state.phone,
                                        "password": this.state.resource.password
                                      }
                                    }
    )) {
      this.notifier.info('プロフィール詳細を登録してください。');
      this.props.history.push('/mypage');
    }
  }

  private changeSex(e: any) {
    const resource = this.state.resource;
    if (resource) {
      delete resource.occupation;
      this.setState({ resource: resource });
    }
    this.changeResource(e);
  }

  private changeBirthDay(e: any) {
    this.changeResource(e);
    this.cauculateAge();
  }

  private cauculateAge() {
    if (!this.state.resource?.birth_day) {
      this.setState({ age: undefined });
      return;
    }

    const birthDay = this.state.resource?.birth_day.replaceAll('-', '');
    if (!birthDay.match(/^\d{8}$/)) {
      this.setState({ age: undefined });
      return;
    }

    const current = moment().format('YYYYMMDD');
    const age = Math.floor((Number(current) - Number(birthDay)) / 10000.0);
    this.setState({ age: age });
  }
}

export default withRouter(AppDataStore.withStores(RegisterInfoPage));
