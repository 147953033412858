import React from 'react';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import RoomMessageList from './RoomMessageList'
import { MessageApiClient } from '../../actions/clients/MessageApiClient';
import { IRoom } from '../../resources/IRoom';
import RoomDetailPageHeader from './RoomDetailPageHeader';

interface IState extends common.IState<IRoom> {
}

class RoomDetailPage extends common.BasePage<common.IProps<common.IParams>, IState> {
  private _client: JobApiClient;

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = {
      roomId: this.props.match.params.id,
      room: this.props.location.state.room
    };
  }

  /*async componentDidMount() {
    const myProfile = await new MyProfileApiClient(this.props.globalStore).get();
    this.setState({ myProfileData: myProfile });
  }*/

  render() {
    return (
      <React.Fragment>
        <RoomDetailPageHeader onReload={(e) => this.onReload(e)} room={this.state.room}
                              openMemberPage={(e: any) => this.openMemberPage(e)} />

        <RoomMessageList room={this.state.room} page={this.state.page} onPageSelected={(page) => this.selectPage(page)}
        onResourceSelected={(message) => this.onMessageSelected(message)} ongoToPremium={() => this.goToPremium()}
        />
        <div style={{ height: 60 }}></div>
      </React.Fragment>
    );
  }

  protected createClient(): BaseApiClient<IMessage> {
    return new MessageApiClient(this.props.globalStore, this.state.room.id);
  }

  // メンバー詳細
  private openMemberPage = () => {
    this.props.history.push({
      pathname: `/members/${this.props.location.state.room?.member?.id}`,
      state: { member: this.props.location.state.room?.member }
    });
  }

  // ページ更新
  private onReload = () => {
    window.location.reload();
  }

  // プレミアム会員
  private goToPremium() {
    this.props.history.push('/premiumpurchase');
  }

}

export default withRouter(AppDataStore.withStores(RoomDetailPage));
