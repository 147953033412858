import React from 'react';
import * as common from '../common/BaseComponent';
import { Button, Modal } from 'react-bootstrap';
import { JobApiClient } from '../../actions/clients/JobApiClient';

export interface IProps<TValue> extends common.IProps {
  onClosed: () => void;
  onCommitted?: (value: TValue) => void;
  value: TValue;
}

export interface IState extends common.IState {
  currentStep: number;
  showModal: boolean;
  totalStep: number;
}

export abstract class BaseJobWizardModal<TProps extends IProps<TValue>, TState extends IState, TValue> extends common.BaseComponent<TProps, TState> {
  private _client: JobApiClient;

  constructor(props: TProps) {
    super(props);
    this._client = new JobApiClient(props.globalStore);
  }

  protected abstract get commitValue(): TValue;

  protected abstract get jobType(): string;

  protected abstract get pages(): JSX.Element[];

  protected abstract get title(): string;

  render() {
    return (
      <>
        <Modal show={this.state.showModal} size="xl" centered onHide={() => this.close()} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{this.title} (ステップ {this.state.currentStep} / {this.state.totalStep})</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.pages[this.state.currentStep - 1]}
          </Modal.Body>
          <Modal.Footer>
            {this.state.currentStep > 1 && (
              <Button variant="secondary" onClick={() => this.previousStep()}>前へ</Button>
            )}
            {this.state.currentStep < this.state.totalStep && (
              <Button variant="secondary" onClick={() => this.nextStep()}>次へ</Button>
            )}
            {this.state.currentStep === this.state.totalStep && (
              <Button variant="primary" onClick={() => this.commit()}>実行</Button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  protected close() {
    this.setState({ showModal: false }, () => {
      setTimeout(() => {
        this.props.onClosed();
      }, 1000);
    });
  }

  private async commit() {
    //console.log('Sending job', JSON.stringify(this.commitValue, null, 2));

    await this._client.enqueueJob(this.jobType, this.commitValue);
    this.notifier.info(`${this.title} を開始しました`);

    if (this.props.onCommitted) {
      this.props.onCommitted(this.commitValue);
    }

    this.close();
  }

  private nextStep() {
    this.setState({ currentStep: this.state.currentStep + 1 });
  }

  private previousStep() {
    this.setState({ currentStep: this.state.currentStep - 1 });
  }
}
