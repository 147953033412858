import { IResource } from "../../resources/IResource";
import { BaseApiClient } from "./BaseApiClient"

export class AgeVerificationApiClient extends BaseApiClient<IResource> {

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    return 'api/v1/age_verification';
  }
}
