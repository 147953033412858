import React from 'react';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BaseForm';
import MyProfileSubImagePageHeader from './MyProfileSubImagePageHeader';
import Swal from 'sweetalert2'
// https://sweetalert2.github.io/
//https://codesandbox.io/s/vmvjl2q023?file=/src/index.js:59-165
import ImageCropper from '../imagecropper/ImageCropper';
import { ProfileSubImageApiClient } from '../../actions/clients/profile/ProfileSubImageApiClient';

interface IState extends common.IState {
  isDialogOpen: boolean;
}

class MyProfileSubImagePage extends common.BaseForm<IMember, IProps, IState> {

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = { isDialogOpen: false, isMemberDialogOpen: false };
  }

  async componentDidMount() {
  }

  render() {
    return (
      <React.Fragment>
        <MyProfileSubImagePageHeader />

        <div style={{ height: 10 }}></div>

          <ImageCropper  imageToCrop={this.state.src}
            onPostImageUrl={(imageUrl: any) => this.postSubImage(imageUrl)}
          />

      </React.Fragment>
    );
  }

  // サブ画像更新
  async postSubImage(imageUrl: any) {
    const globalStore = this.props.globalStore
    const props = this.props

    var xhr = new XMLHttpRequest;
    xhr.responseType = 'blob';

    xhr.onload = function() {
       var recoveredBlob = xhr.response;

       var reader = new FileReader;

       reader.onload = function() {
         var blobAsData = reader.result;
         var params = {
           "member": {
             "image": blobAsData.replace("data:image/jpeg;base64,", "")
           }
         };
         const data = new ProfileSubImageApiClient(globalStore).post(params);
         //console.log(data);

         //if (data.result == true) {
             Swal.fire({
               title: '',
               text: "サブ画像をアップロードしました",
               type: 'success',
             }).then((result) => {
               props.history.push('/myprofile');
             })
         /*} else {
           Swal.fire({
             title: '',
             text: "サブ画像をアップロードできませんでした",
             type: 'error',
           })
         }*/

       };
       reader.readAsDataURL(recoveredBlob);
    };

    xhr.open('GET', imageUrl);
    xhr.send();
  }
}

export default withRouter(AppDataStore.withStores(MyProfileSubImagePage));
