import { IResource } from "../../resources/IResource";
import { BaseApiClient } from "./BaseApiClient"

export class BlockDeleteApiClient extends BaseApiClient<IResource> {
  private _blockId: number;

  constructor(store: Store<IAppGlobalStore>, blockId: number) {
    super(store);
    this._blockId = blockId;
  }

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    console.log(`api/v1/blocks/${this._blockId}`);
    return `api/v1/blocks/${this._blockId}`;
  }
}
