import React from 'react';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import FootpintListPageHeader from './FootprintListPageHeader';
import FootprintList from './FootprintList';
import { IMember } from '../../resources/IMember';
import { IRoom } from '../../resources/IRoom';
import FireBaseLogEventManager from '../../utils/FireBaseLogEventManager';
import FireBaseLogEvent from '../../utils/enums/FireBaseLogEvent';
import FireBaseLogEventProfileScreenType from '../../utils/enums/FireBaseLogEventProfileScreenType';

interface IState extends common.IState {
}

class FootpintListPage extends common.BasePage<common.IProps<common.IParams>, IState> {


  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = { member: {} };
  }

  async componentDidMount() {;

  }

  render() {
    return (
      <React.Fragment>
        <FootpintListPageHeader onReload={(e) => this.onReload(e)} />

        <div style={{ height: 0 }}></div>

        <FootprintList page={this.state.page}
        onResourceSelected={(member) => this.onMemberSelected(member)} />

      </React.Fragment>
    );
  }

  public onMemberSelected(member?: IMember) {
    // log event
    FireBaseLogEventManager.sendLogEvent(FireBaseLogEvent.profileDetailClick, { "profile_screen_type" : FireBaseLogEventProfileScreenType.footprints });

    this.props.history.push({
      pathname: `/members/${member?.id}`,
      state: { member: member }
    });
  }

  private onSelectRoom(room: IRoom) {
    this.props.history.push({
      pathname: `/rooms/${room.id}`,
      state: { room: room }
    });
  }

  // ページ更新
  private onReload = () => {
    window.location.reload();
  }

}

export default withRouter(AppDataStore.withStores(FootpintListPage));
