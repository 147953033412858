import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import OffersList from './OffersList';
import OfferedsList from './OfferedsList';
import OfferHeader from './OfferHeader';
import Footer from '../nav/Footer';
import { IMember } from '../../resources/IMember';
import { IRoom } from '../../resources/IRoom';
import Utils from '../../utils/Utils';

interface IState extends common.IState {
}

class OfferListPage extends common.BasePage<common.IProps<common.IParams>, IState> {
  private hash = "";

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = {
                    member: {},
                    isMemberDialogOpen: false,
                    eventKey: Utils.getItem("eventKeyOffer") ? Utils.getItem("eventKeyOffer") : "first"
                  };
  }

  render() {
    return (
      <React.Fragment>
        <OfferHeader onReload={(e) => this.onReload(e)} />

        <Tabs defaultActiveKey={this.state.eventKey}  style={{ display: "flex", flexWrap: "nowrap", alignItems: "stretch" }}>
          <Tab eventKey="first" title="お相手から" tabClassName="nav-item-offer">
            <OfferedsList  eventKey="first" page={this.state.page} onPageSelected={(page) => this.selectPage(page)}
              onResourceSelected={(member) => this.onMemberSelected(member)} />
          </Tab>
          <Tab eventKey="second" title="あなたから" tabClassName="nav-item-offer">
            <OffersList eventKey="second" page={this.state.page} onPageSelected={(page) => this.selectPage(page)}
              onResourceSelected={(member) => this.onMemberSelected(member)} />
          </Tab>
        </Tabs>
        <div style={{ height: 60 }}></div>
        <Footer selectIndex={1} />
      </React.Fragment>
    );
  }

  private async selectTab(e: any) {
    //e.preventDefault();
    this.hash = e;
  }

  // ページ更新
  private onReload(e: any) {
    window.location.reload();
  }

  public onMemberSelected(member?: IMember) {
    //this.props.history.push(`/members/${member?.id}`);

    this.props.history.push({
      pathname: `/members/${member?.id}`,
      state: { member: member }
    });
  }

  private onSelectRoom(room: IRoom) {
    this.props.history.push({
      pathname: `/rooms/${room.id}`,
      state: { room: room }
    });
  }

  // ダイアログを開く
  private openMemberDialog = () => {
    this.setState({isMemberDialogOpen: true});
  }

  // ダイアログからのコールバックでダイアログを閉じてあげる
  private closeMemberDialog = () => {
    this.setState({isMemberDialogOpen: false});
  }

}

export default withRouter(AppDataStore.withStores(OfferListPage));
