import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { IMember } from '../../resources/IMember';
import { IMemberSearchCondition } from '../../resources/IMemberSearchCondition';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import SearchHeader from './SearchHeader';
import SearchList from './SearchList';
import Footer from '../nav/Footer';
import SearchConditionDialog from './SearchConditionDialog';
import Utils from '../../utils/Utils';
import FireBaseLogEventManager from '../../utils/FireBaseLogEventManager';
import FireBaseLogEvent from '../../utils/enums/FireBaseLogEvent';
import FireBaseLogEventProfileScreenType from '../../utils/enums/FireBaseLogEventProfileScreenType';
import Popup from '../popup';

interface IState extends common.IState {
  condition: IMemberSearchCondition;
  isDialogOpen: boolean;
}

class SearchListPage extends common.BasePage<common.IProps<common.IParams>, IState> {
  private hash = "";

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = {
      condition: Utils.getItem('memberSearchCondition') || {} ,
      isDialogOpen: false,
      isMemberDialogOpen: false,
      member: {},
      eventKey: Utils.getItem("eventKeySearch") ? Utils.getItem("eventKeySearch") : "first",
    };
  }

  componentDidMount() {
  }

  render() {
    return (
      <React.Fragment>
        <SearchHeader onOpen={this.openDialog} />

        <SearchConditionDialog condition={this.state.condition} closeDialogWithSearch={this.closeDialogWithSearch} dialogReset={this.dialogReset}
          isOpen={this.state.isDialogOpen} onClose={this.closeDialog} />

        <Popup />

        <Tabs defaultActiveKey={this.state.eventKey}
          style={{
                  display: "flex", flexWrap: "nowrap", alignItems: "stretch",
                }} onClick={(e:any) => this.selectTab(e)}>
          <Tab eventKey="first" title="おすすめ" tabClassName="nav-item-search">
            <SearchList eventKey="first" page={this.state.page} onPageSelected={(page) => this.selectPage(page)} condition={this.state.condition}
              onResourceSelected={(member) => this.onMemberSelected(member)} type="0" />
          </Tab>
          <Tab eventKey="second" title="新着" tabClassName="nav-item-search">
            <SearchList eventKey="second" page={this.state.page} onPageSelected={(page) => this.selectPage(page)} condition={this.state.condition}
              onResourceSelected={(member) => this.onMemberSelected(member)} type="1" />
          </Tab>
          <Tab eventKey="third" title="ログイン" tabClassName="nav-item-search">
            <SearchList eventKey="third" page={this.state.page} onPageSelected={(page) => this.selectPage(page)} condition={this.state.condition}
              onResourceSelected={(member) => this.onMemberSelected(member)}  type="2" />
          </Tab>
          <Tab eventKey="forth" title="今日" tabClassName="nav-item-search">
            <SearchList eventKey="forth" page={this.state.page} onPageSelected={(page) => this.selectPage(page)} condition={this.state.condition}
              onResourceSelected={(member) => this.onMemberSelected(member)}  type="3" />
          </Tab>
        </Tabs>

        <div style={{ height: 60 }}></div>

        <Footer selectIndex={0} />
      </React.Fragment>
    );
  }

  private async selectTab(e: any) {
    //e.preventDefault();
    //console.log(e);
    //console.log(e.target);
    //console.log(e.target?.getAttribute('data-rb-event-key'));
  }

  public onMemberSelected(member?: IMember) {
    
    // log event
    FireBaseLogEventManager.sendLogEvent(FireBaseLogEvent.profileDetailClick, { "profile_screen_type" : FireBaseLogEventProfileScreenType.search });

    this.props.history.push({
      pathname: `/members/${member?.id}`,
      state: { member: member }
    });
  }

  private onSearchConditionChanged(condition: IMemberSearchCondition) {
    this.setState({ condition });
    //console.log('onSearchConditionChanged', { condition });
  }

  // ダイアログを開く
  private openDialog = () => {
    this.setState({isDialogOpen: true});
  }

  // ダイアログからのコールバックでダイアログを閉じてあげる
  private closeDialog = () => {
    this.setState({isDialogOpen: false});
  }

  // ダイアログからのコールバックでダイアログを閉じてあげる
  private closeDialogWithSearch = (c: IMemberSearchCondition) => {
    this.setState({isDialogOpen: false});
    this.setState({ condition: c });
    Utils.setItem('memberSearchCondition', c);
    window.location.reload();
  }
  private dialogReset = () => {
    this.setState({ condition: {} });
  }

  // ダイアログを開く
  private openMemberDialog = () => {
    this.setState({isMemberDialogOpen: true});
  }

  // ダイアログからのコールバックでダイアログを閉じてあげる
  private closeMemberDialog = () => {
    this.setState({isMemberDialogOpen: false});
  }

  // ページ更新
  private onReload = () => {
    window.location.reload();
  }

}

export default withRouter(AppDataStore.withStores(SearchListPage));
