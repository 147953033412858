import React from 'react';
import { Button, Col, Form, Jumbotron, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import SystemNotification from '../common/SystemNotification';
import { PhoneApiClient } from '../../actions/clients/regist/PhoneApiClient';
import FireBaseLogEventManager from '../../utils/FireBaseLogEventManager';
import FireBaseLogEvent from '../../utils/enums/FireBaseLogEvent';

interface IParams extends common.IParams {
}

interface IProps extends common.IProps<IParams> {
}

interface IState extends common.IState {
}

class RegisterSmsPage extends common.BasePage<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      phone: this.props.location.state.phone,
      sms: '',
    };
  }

  componentDidMount() {
  }

  render() {
    return (
      <Row>
        <Col xs={12} md={12}>
          <Jumbotron>
            <SystemNotification/>
            <Form className="mt-5">
              <Form.Group>
                <Form.Label>SMSを入力してください<span style={{ fontSize: "12px" }}></span></Form.Label>
                <Form.Control type="phone" name="sms" onChange={(e) => this.changeSms(e)} value={this.state.sms}/>
              </Form.Group>
              <Form.Group>
                <Form.Label><span style={{ fontSize: "12px" }}></span></Form.Label>
                <Button type="submit" variant="primary" size="lg" block disabled={!this.state.sms} onClick={(e) => this.sendSms(e)}>次へ</Button>
              </Form.Group>
            </Form>
          </Jumbotron>
        </Col>
      </Row>
    );
  }

  private changeSms(e: any) {
    this.setState({ sms: e.target.value });
  }

  private async sendSms(e: any) {
    e.preventDefault();
    //console.log(this.state);

    // log event
    FireBaseLogEventManager.sendLogEvent(FireBaseLogEvent.telAuthButtonClick);

    var params = {
      "member": {
        "phone_number": this.state.phone,
        "sms": this.state.sms
      }
    };
    const data = await new PhoneApiClient(this.props.globalStore).updateWithoutID(params);
    //console.log(data);
    if (data.result === true) {
      this.props.history.push({
        pathname: `/registerinfo`,
        state: {
                phone: this.state.phone,
                sms: this.state.sms,
              }
      });
    }
  }
}

export default withRouter(AppDataStore.withStores(RegisterSmsPage));
