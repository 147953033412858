// イベント
enum FireBaseLogEvent {
  signUpButtonClick      = "sign_up_click",
  inputTelButtonClick    = "input_tel_click",
  telAuthButtonClick     = "tel_auth_click",
  baseProfileConfirmButtonClick = "base_profile_confirm_click",
  signUpCompleteButtonClick     = "sign_up_complete_click",
  walkthrough1Show              = "walkthrough1_show",
  walkthrough2Show              = "walkthrough2_show",
  walkthrough3Show              = "walkthrough3_show",
  walkthrough4Show              = "walkthrough4_show",
  profileDetailClick            = "profile_detail_click",
  offerButtonClick              = "offer_click",
  reOfferButtonClick            = "reoffer_click",
  //priceTypeClick(_:)            = "price_type_click",
  pricePointClick               = "price_point_click",
  cancelOffer1Click             = "cancel_offer1_click",
  okOffer1Click                 = "ok_offer1_click",
  //sendOffer2Click(_:)           = "send_offer2_click",
  cancelOffer2Click             = "cancel_offer2_click",
  inAppMessageIsReady           = "in_app_message_is_ready",
  initProfileUploadImageDone    = "init_profile_upload_image_done",
  joiningReleaseSecondCampaignClick = "joining_release_second_campaign_click",
  pointPurchaseDone             = "point_purchase_done",
  subscriptionDone              = "subscription_done",
  myPageNormalMemberClick   = "my_page_normal_member_click",
  myPagePointPurchaseClick  = "my_page_point_purchase_click",
  messageSendSubscriptionPurchaseClick = "message_send_subscription_purchase_click",
  dialogAppealingForMatchedUsersShow   = "dialog_appealing_for_matched_users_show",
  dialogAppealingForMatchedUsersClick  = "dialog_appealing_for_matched_users_click",
  purchaseSubscriptionShow                = "purchase_subscription_show",
  //purchaseSubscriptionClick(_:)           = "purchase_subscription_click",
  //mitsumitsLog(_:)  = "mitsumitsu_log",
  free_offer_send   = "free_offer_send",
  free_offer_skip   = "free_offer_skip",
}

export default FireBaseLogEvent;
