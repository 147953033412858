import AppDataStore from '../../stores/AppDataStore';
import * as common from './BaseComponent';

interface IProps extends common.IProps {
};

class ScrollToTop extends common.BaseComponent<IProps, common.IState> {
  componentDidUpdate(prevProps: IProps) {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

export default AppDataStore.withStores(ScrollToTop);
