import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './css/search.css';
import './css/offer.css';
import './css/room.css';
import './css/mypage.css';
import './css/setting.css';
import './css/error.css';
import './css/memberdetail.css';
import './css/bbs.css';
import './css/withdrawal.css';
import './css/privatemode.css';
import './css/notification.css';
import './css/alert.css';
import './css/nav.css';
import './css/pagination.css';
import './css/form.css';
import './css/color.css';
import './css/purchase.css';
import './css/maintenance.css';
import './css/popup.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
