import { IResource } from "../../resources/IResource";
import { BaseApiClient } from "./BaseApiClient"

export class ConfigApiClient extends BaseApiClient<IResource> {

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }
/*
  constructor(store: Store<IAppGlobalStore>) {
    super(store);
  }
*/
  protected basePath(): string {
    return `api/v1/config`;
  }
}
