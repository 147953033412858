import * as common from '../common/BaseListComponent';
import AppDataStore from '../../stores/AppDataStore';
import { BaseApiClient } from '../../actions/clients/BaseApiClient';
import { Image, Row, Col, Card, Button } from 'react-bootstrap';
import LoadingIndicator from '../common/LoadingIndicator';
import Paginator from '../common/Paginator';
import { IFootprint } from '../../resources/IFootprint';
import { BlocksApiClient } from '../../actions/clients/BlocksApiClient';
//import { DateTimeConverter } from '../../converters/DateTimeConverter';
import Utils from '../../utils/Utils';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

interface IProps extends common.IProps<IFootprint> {
}

interface IState extends common.IState<IFootprint> {
}

class BlockList extends common.BaseListComponent<IOffer, IProps, IState> {

  private storageKey: String = "BlocksList";  // JSON保存のキー

  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    // 戻ってきた場合は保存されたJSONで一覧を表示
    const json = Utils.getJson(this.storageKey);
    if ( Object.keys(json).length > 0 ) {
      this.setResponse(json);
    } else {
      await super.componentDidMount();
    }
  }

  setResponse(json: any) {
    if (json === null) return;
    this.setState({ "response": json.response });
    Utils.removeJson(this.storageKey)
  }

  render() {
    //console.log(this.state.response);
    return (
      <>
        <LoadingIndicator watch={this.state.response}>

          {this.state.response?.data.map((f, index) => (
            <Card className="list_card" key={index}>
              <Row>
                <Col>
                  <Image width={60} height={60} style={{ height: "auto" }}
                       src={Utils.toImageUrl(f.image)} roundedCircle id={f.id} />
                    <span style={{ fontSize: "14px" }}  >
                      &nbsp;&nbsp;{f.name}&nbsp;&nbsp;{f.age}歳&nbsp;&nbsp;{f.prefecture}
                    </span>
                </Col>
                <Col>
                  <span style={{ width: "100%", verticalAlign: "middle",  paddingTop: "5px", fontSize: "14px", display: "flex", justifyContent: "right", alignItems: "flex-end"　}}>
                    <Button className="profile_button button-dark" style={{width: "110px"}} bf="dark"
                      onClick={(e: any) => this.selectMember(f)}>ブロック解除</Button>
                  </span>
                </Col>
              </Row>
              <hr />
            </Card>
          ))}

          {this.state.response?.data.length === 0 && (
            <Row>
              <Col xs={2} md={2}></Col>
              <Col className="nodata" xs={8} md={8}>データが見つかりません。</Col>
              <Col xs={2} md={2}></Col>
            </Row>
          )}

          <Paginator currentPage={this.state.response?.meta.current_page} totalPage={this.state.response?.meta.total_pages} onPageSelected={(page) => this.selectPage(page)}/>
          <div style={{ height: 40 }}></div>
        </LoadingIndicator>
      </>
    );
  }

  private selectMember(f: any) {
    confirmAlert({
      title: '',
      message: 'ブロックを解除しますか？',
      buttons: [
        {
          label: 'キャンセル',
          //onClick: () => alert('Click Yes')
        },
        {
          label: 'OK',
          onClick: () => this.unBlock(f)
        }
      ]
    });
  }

  private unBlock(f: any) {
    this.props.onResourceSelected?.(f)
  }

  protected createClient(): BaseApiClient<IOffer> {
    return new BlocksApiClient(this.props.globalStore);
  }

  protected buildSearchCondition(page?: number) {
    return Object.assign({}, {}, { page: page });
  }

  private onMemberSelected(member?: IMember) {
    // 戻った場合に再建策しないように
    Utils.setJson(this.storageKey, this.state);

    //this.props.onResourceSelected(member);
  }
}

export default AppDataStore.withStores(BlockList);
