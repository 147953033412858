import React from 'react';
import * as common from '../common/BasePage';
import { Nav, Navbar, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

interface IProps extends common.IProps<common.IParams> {
}

interface IState extends common.IState {
}

class SettingPageHeader extends common.BasePage<IProps, IState> {
  private title = "設定";

  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
      //document.title = this.title;;
  }

  render() {
    return (
      <React.Fragment>
        <Navbar className="justify-content-center" fixed="top" bg="dark" variant="dark">
          <Navbar.Collapse className="container-fluid">
            <Nav>
            <Button style={{marginLeft: '-1.0em', marginTop: '0.2em'}} className="bg-dark" onClick={(e: any) => this.toMyPage(e)}>
                <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#A1A1A1" }} size="sm" />
            </Button>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Brand>{this.title}</Navbar.Brand>
          <Navbar.Collapse className="container-fluid">
            <Nav className="ml-auto">
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </React.Fragment>
    );
  }

  private async toMyPage(e: any) {
    e.preventDefault();
    this.props.history.push('/mypage');
  }

  private async back(e: any) {
    e.preventDefault();
    const session = new UserSession(this.props.globalStore);
    await session.signOut();
    this.notifier.info('ログアウトしました。');
    this.props.history.push('/signin');
  }
}

export default withRouter(AppDataStore.withStores(SettingPageHeader));
