import React from 'react';
import { ILastViewPopup, IPopup } from '../../resources/IPopup';
import ReactModal from 'react-modal';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ScrollView } from "@cantonjs/react-scroll-view";
import Utils from '../../utils/Utils';
import AppDataStore from '../../stores/AppDataStore';
import { withRouter } from 'react-router-dom';

interface Props {
  /** このダイアログを表示するなら true */
  isOpen: boolean;
  /** このダイアログを閉じるときのコールバック */
  onClose?: () => void;
  popup: IPopup;
}

interface State {
}

class PopupDialog extends React.Component<Props, State> {
  private lastViewPopup: string = 'LastViewPopup';

  constructor(props: Props) {
    super(props);
    ReactModal.setAppElement('body');
    this.state = {};
  }

  componentDidMount() {
    this.setState({ scrollHeight: window.innerHeight * 0.7 });
  }

  private customStyles: ReactModal.Styles = {
    // ダイアログ内のスタイル（中央に表示）
    content: {},
    // 親ウィンドウのスタイル（ちょっと暗くする）
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1031,
    }
  }

  // ダイアログが開いたときに呼び出される
  private handleOpen = () => {
    // ここで設定情報などを読み込む
    if (this.props.popup?.id && this.props.popup?.duration) {
      let lastView: ILastViewPopup = { id: this.props.popup.id, duration: this.props.popup.duration, lastSeen: Date.now() }
      Utils.setJson(this.lastViewPopup, lastView);
    }
  }

  // ダイアログ領域外のクリックや、ESCキーを押したときに呼び出される
  private handleClose = () => {
    // 親コンポーネントにダイアログを閉じてもらうためのコールバック通知
    this.props.onClose?.();
  }

  private displayPopupImage() {
    let url = this.props.popup?.url ?? 'javascript:void(0);';
    let image = this.props.popup?.image ? Utils.toImageUrl(this.props.popup.image) : '/images/profile/avatar-default.png';
  
    return this.props.popup.active ? (
      <a href={url} target="_blank" style={{ cursor: "inherit" }} rel="noreferrer">
        <Image
          className="member_img"
          style={{ width: "100%", aspectRatio: "1/1", objectFit: "cover" }}
          src={image}
        />
      </a>
    ) : (
      <Image
          className="member_img"
          style={{ width: "100%", aspectRatio: "1/1", objectFit: "cover" }}
          src={image}
        />
    );
  }

  render() {
    return (
      <div>
        <ReactModal
          isOpen={this.props.isOpen}
          onAfterOpen={this.handleOpen}
          onRequestClose={this.handleClose}
          style={this.customStyles}
          contentLabel="Settings"
          className="popup-content"
        >
          <Row>
            <Col xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={(e: any) => this.handleClose(e)} className="popup-icon-close">
                <FontAwesomeIcon icon={faTimes}  size="sm" style={{ color: "#000" }} />
              </Button>
            </Col>
          </Row>
          <ScrollView style={{ height: '-webkit-fill-available', width: '-webkit-fill-available', paddingLeft: "1px" }}>
            {this.displayPopupImage()}
          </ScrollView>
        </ReactModal>
      </div>
    )
  }
}

export default withRouter(AppDataStore.withStores(PopupDialog));
