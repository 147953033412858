import React from "react";
import { useState } from "react";

// Create a custom React alert message
// https://blog.logrocket.com/create-custom-react-alert-message/

export default function Alert({ children, type, message }) {

  const [isShow, setIsShow] = useState(true);

  const renderElAlert = function () {
    return React.cloneElement(children);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setIsShow(false);
  };

return(
    <div className={isShow ? `alert ${type} ` : `alert ${type} hide`}>
      <span className="closebtn" onClick={handleClose}>
        &times;
      </span>
      {children ? renderElAlert() : message}
    </div>
  );
}
