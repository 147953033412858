import React from 'react';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import BbsListPageHeader from './BbsListPageHeader';
import Footer from '../nav/Footer';
import BbsList from './BbsList';

interface IState extends common.IState {
}

class BbsListPage extends common.BasePage<common.IProps<common.IParams>, IState> {
  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <BbsListPageHeader />
        <div style={{ height: 10 }}></div>

        <BbsList page={this.state.page} onPageSelected={(page) => this.selectPage(page)} onResourceSelected={(member) => this.onMemberSelected(member)} />

        <div style={{ height: 60 }}></div>

        <Footer selectIndex={3} />
      </React.Fragment>
    );
  }

  public onMemberSelected(member?: any) {
    //console.log(member);
    this.props.history.push({
      pathname: `/members/${member?.id}`,
      state: { member: member }
    });
  }

}

export default withRouter(AppDataStore.withStores(BbsListPage));
