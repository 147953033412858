import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import PointPageHeader from './PointPageHeader';
import { ConfigApiClient } from '../../actions/clients/ConfigApiClient';
import { TcApiClient } from '../../actions/clients/tc/TcApiClient';
import Alert from "../common/Alert";
//import Utils from '../../utils/Utils';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import premium_btn_plan_background from '../../materials/images/premium/premium_btn_plan_background.png'
import FireBaseLogEventManager from '../../utils/FireBaseLogEventManager';
import FireBaseLogEvent from '../../utils/enums/FireBaseLogEvent';
import Utils from '../../utils/Utils';
import { IMyProfile } from '../../resources/IMyProfile';
import { IResource } from '../../resources/IResource';

interface IState extends common.IState {
  configData: IResource | null;
  myProfileData: IMyProfile | null;
  pointData: Array<object>;
}

class PointPage extends common.BasePage<common.IProps<common.IParams>, IState> {

  containerStyle = {
        backgroundImage:
          'url(' +  premium_btn_plan_background + ')' ,
        width: "300px",
        height: "72px",
        position: "relative",
      };

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = { configData: {}, pointData: [{}], myProfileData: Utils.loadMyProfile() };
  }

  async componentDidMount() {;
    const myProfile = await Utils.getMyProfile(this.props.globalStore);
    this.setState({ myProfileData: myProfile });

    if (myProfile?.sex !== '男性') {
      return this.props.history.push('/');
    }

    const configData = await new ConfigApiClient(this.props.globalStore).get();
    this.setState({ configData: configData });
    //console.log(configData);
    const key = this.getPointPriceKey();
    if (configData && configData[key]) {
      this.setState({ pointData: JSON.parse(configData[key]) });
    }

    // log event
    FireBaseLogEventManager.sendLogEvent(FireBaseLogEvent.myPagePointPurchaseClick);

  }

  render() {
    return (
      <React.Fragment>
        <PointPageHeader />
        <div style={{ height: 0 }}></div>

        <Alert type="error" message="iOSの場合は、［設定アプリ］-［Safari］-［ポップアップロック］をOFFにして購入してください。" />

        <div style={{ height: 20 }}></div>

        {this.state.pointData?.map((s, index) => (
          <Row key={index}>
            <Col xs={12} md={12}>
              <center>
              <div style={this.containerStyle} onClick={(e: any) => this.selectItem(s)}>
                <div style={{ position: "absolute", height: "20px", fontSize: "18px", width: "100%", textAlign: "center", padding: "6px",  }}><strong>{s.description}</strong></div>
                <div style={{ position: "absolute", width: "100%",  fontSize: "14px", bottom: "0", textAlign: "center", padding: "10px" }}><strong>&nbsp;&nbsp;<strike>¥{s.price_before_discount?.toLocaleString()}</strike>&nbsp;&nbsp;&nbsp;→&nbsp;&nbsp;&nbsp;¥{s.price?.toLocaleString()}（{s.discount_percent}%off）</strong></div>
              </div>;
              </center>
            </Col>
          </Row>
        ))}

        <div style={{ height: 60 }}></div>

      </React.Fragment>
    );
  }

  private selectItem(s: any) {
    //console.log( Utils.loadMyProfile() );
    if (!this.state.myProfileData || this.state.myProfileData['age_verification'] !== Utils.ageVerificationStatus.verified) {
      alert('マイページから年齢確認を行ってください。');
      return;
    }

    confirmAlert({
      //title: s.description,
      //label: s.description,
      childrenElement: () => <div><strong><br />クレジット決済<br />{s.description}　{s.price?.toLocaleString()}円</strong></div>,
      message: 'ポイントを購入します。よろしいですか？',
      buttons: [
        {
          label: 'キャンセル',
          //onClick: () => alert('Click Yes')
        },
        {
          label: '購入する',
          onClick: () => this.buyPoint(s)
        }
      ]
    });
  }

  private async buyPoint(s: any) {
    //console.log(s);
    var params = {
      "transaction": {
        "point_plan": s.code_ios
      }
    };
    const api = new TcApiClient(this.props.globalStore)
    const data = await api.post(params);
    if (data.result === true) {
      window.open(api.getWebURL(data.data.transaction_id), '_blank');
    }
  }

  private getPointPriceKey() {
    if( window.location.host.indexOf('staging') !== -1 || window.location.host.indexOf('localhost') !== -1) {
      return 'ios_point_price_json_develop';
    }
    //return 'ios_point_price_json_production';
    return 'ios_point_price_json_prodution';
  }
}

export default withRouter(AppDataStore.withStores(PointPage));
