import React from 'react';
import * as common from '../common/BasePage';
import { Nav, Navbar, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCrown } from "@fortawesome/free-solid-svg-icons";

interface IProps extends common.IProps<common.IParams> {
  onOpen?: () => void;
}

interface IState extends common.IState {
}

class SearchHeader extends common.BasePage<IProps, IState> {
  private title = "MITSUMITSU";

  constructor(props: IProps) {
    super(props);
    //console.log(props);
    this.state = {};
  }

  componentDidMount() {
      //document.title = this.title;;
  }

  render() {
    return (
      <React.Fragment>
        <Navbar className="justify-content-center bg-dark theme" fixed="top" bg="dark" variant="dark">
          <Navbar.Collapse className="container-fluid">
            <Nav>
            <Button onClick={(e: any) => this.handleOpen(e)} className="bg-dark" style={{marginLeft: '-1.0em', marginTop: '0.2em'}}>
              <FontAwesomeIcon icon={faSearch}  size="sm" style={{ color: "#A1A1A1" }} />
            </Button>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Brand style={{ color: "#ffc107" }}>{this.title}</Navbar.Brand>
          <Navbar.Collapse className="container-fluid">
            <Nav className="ml-auto">
            <Button onClick={(e: any) => this.moveRank(e)} className="bg-dark" style={{marginRight: '-1.0em', marginTop: '0.2em'}}>
              <FontAwesomeIcon icon={faCrown}  size="sm" style={{ color: "#A1A1A1" }} />
            </Button>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </React.Fragment>
    );
  }

  private handleOpen = (e: any) => {
    //console.log('open');
    // 親コンポーネントにダイアログを閉じてもらうためのコールバック通知
    this.props.onOpen?.();
  }

  private moveRank= (e: any) => {
    this.props.history.push('/ranking');
  }

}

export default withRouter(AppDataStore.withStores(SearchHeader));
