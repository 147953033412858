class Constraints {

  static encKeys() {
    return {
        key : 'e61454eb27a145019a5f9696c912ad62',
        algorithm : 'aes-256-cbc',
        delimiter : '$'
    };
  }

}

export default Constraints;
