import { IResource } from "../../../resources/IResource";
import { BaseApiClient } from "../BaseApiClient"

export class WithdrawalApiClient extends BaseApiClient<IResource> {

  constructor(store: Store<IAppGlobalStore>) {
    super(store);
  }

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    return `api/v1/members/profile/withdrawal`;
  }
}
