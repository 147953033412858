import { IFootprint } from "../../resources/IFootprint";
import { BaseApiClient } from "./BaseApiClient"

export class FootprintApiClient extends BaseApiClient<IFootprint> {
  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    return 'api/v1/footprints';
  }
}

export class RankingApiClient extends BaseApiClient<IMember> {
  private _ranking_type: string;

  constructor(store: Store<IAppGlobalStore>, rankingType: string) {
    super(store);
    this._ranking_type = rankingType;
  }

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    //console.log(`api/v1/rankings/${this._ranking_type}`);
    return `api/v1/rankings/${this._ranking_type}`;
  }
}
