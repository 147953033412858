import React from 'react';
import * as common from '../common/BasePage';
import { Nav, Navbar, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';

interface IProps extends common.IProps<common.IParams> {
    onPreview?: () => void;
}

interface IState extends common.IState {
}

class MyPageHeader extends common.BasePage<IProps, IState> {
  private title = "マイページ";

  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
      //document.title = this.title;;
  }

  render() {
    return (
      <React.Fragment>
        <Navbar className="justify-content-center bg-dark" fixed="top" bg="dark" variant="dark">
          <Navbar.Collapse className="container-fluid">
            <Nav>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Brand>{this.title}</Navbar.Brand>
          <Navbar.Collapse className="container-fluid">
            <Nav className="ml-auto">
              <Button onClick={(e: any) => this.preview(e)} className="bg-dark" style={{ color: "#A1A1A1", fontSize: "12px" }}>
                プレビュー
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </React.Fragment>
    );
  }

  // プレビュー
  preview(e) {
    this.props.onPreview?.();
  }
}

export default withRouter(AppDataStore.withStores(MyPageHeader));
