import { IFootprint } from "../../resources/IFavorite";
import { BaseApiClient } from "./BaseApiClient"

export class FavoriteApiClient extends BaseApiClient<IFootprint> {
  private _memberId: number;

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  constructor(store: Store<IAppGlobalStore>, memberId: member) {
    super(store);
    this._memberId = memberId;
  }

  protected basePath(): string {
    //console.log(`api/v1/members/${this._memberId}/favorite`);
    return `api/v1/members/${this._memberId}/favorite`;
  }

}
