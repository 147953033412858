import React from 'react';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import BlockListPageHeader from './BlockListPageHeader';
import BlockList from './BlockList';
import { BlockDeleteApiClient } from '../../actions/clients/BlockDeleteApiClient';
import Swal from 'sweetalert2'

interface IState extends common.IState {
}

class BlockListPage extends common.BasePage<common.IProps<common.IParams>, IState> {


  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = { member: {} };
  }

  async componentDidMount() {;

  }

  render() {
    return (
      <React.Fragment>
        <BlockListPageHeader onReload={(e) => this.onReload(e)} />

        <div style={{ height: 0 }}></div>

        <BlockList page={this.state.page}
        onResourceSelected={(member) => this.onMemberSelected(member)} />

      </React.Fragment>
    );
  }

  private async onMemberSelected(member?: IMember) {
    // block の id
    await new BlockDeleteApiClient(this.props.globalStore, member.id).delete();

    Swal.fire({
      title: '',
      text: "ブロックを解除しました",
      type: 'success',
    }).then((result) => {
      this.onReload();
    })
  }


  // ページ更新
  private onReload = () => {
    window.location.reload();
  }

}

export default withRouter(AppDataStore.withStores(BlockListPage));
