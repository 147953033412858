import { IMyProfile } from "../../resources/IMyProfile";
import { BaseApiClient } from "./BaseApiClient"

export class MyProfileApiClient extends BaseApiClient<IMyProfile> {
  private _cache: IMyProfile | null = null;

  async get(): Promise<IMyProfile | null> {
    //console.log(`Retrieving a resource.`);
    if (!this._cache) {
      this._cache = await super.get();
    }
    return this._cache;
  }

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    return 'api/v1/members/profile';
  }
}
