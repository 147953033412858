import React from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Button, Row, Col } from 'react-bootstrap';
//import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';

interface IProps extends common.IProps<common.IParams> {
  onOpen?: () => void;
}

interface IState extends common.IState {
}

class ImageCropper extends common.BasePage<IProps, IState> {

    private buttonName: String = "送信";
    constructor(props: common.IProps<common.IParams>) {
      super(props);
      this.state = { crop: { unit: "%", width: 30, aspect: 1 / 1 } };
      this.buttonName = props.buttonName ? props.buttonName : "送信"
    }

    componentDidMount() {
    }

    onSelectFile = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          this.setState({ src: reader.result })
        );
        reader.readAsDataURL(e.target.files[0]);
      }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = (image) => {
      this.imageRef = image;
    };

    onCropComplete = (crop) => {
      this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
      // You could also use percentCrop:
      // this.setState({ crop: percentCrop });
      this.setState({ crop });
    };

    async makeClientCrop(crop) {
      if (this.imageRef && crop.width && crop.height) {
        const croppedImageUrl = await this.getCroppedImg(
          this.imageRef,
          crop,
          'newFile.jpeg'
        );
        this.setState({ croppedImageUrl });
      }
    }

    getCroppedImg(image, crop, fileName) {
      const canvas = document.createElement('canvas');
      //const pixelRatio = window.devicePixelRatio;
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = canvas.getContext('2d');

      // https://qiita.com/ksrnnb/items/81d34faf4abc47ea4182
      canvas.width = crop.width;
      canvas.height = crop.height;

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      /*
      // スマホだとサイズが狂う
      canvas.width = crop.width * pixelRatio * scaleX;
      canvas.height = crop.height * pixelRatio * scaleY;

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );
      */

      return new Promise((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              //reject(new Error('Canvas is empty'));
              //console.log('Canvas is empty');
              return;
            }
            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            resolve(this.fileUrl);
          },
          'image/jpeg',
          1
        );
      });
    }

    async post(e: any) {
      //console.log(e);
      //console.log(this.state.croppedImageUrl);
      this.props.onPostImageUrl(this.state.croppedImageUrl);
    }

    render() {
      const { crop, croppedImageUrl, src } = this.state;

      return (
        <div className="App">
          <div>
            <label className="image_post_label">
              <input type="file" accept="image/*"  onChange={this.onSelectFile} />ファイルを選択
            </label>
          </div>
          {src && (
            <ReactCrop
              src={src}
              crop={crop}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          )}

          {croppedImageUrl && (
          <Row className="member-profile-row">
             <Col xs={12} md={12} style={{ textAlign: "center" }}>
                <Button style={{ width: "1000" }} onClick={(e: any) => this.post(e)}>{this.buttonName}</Button>
             </Col>
          </Row>
          )}
        </div>
      );
    }

}

export default ImageCropper;

/*
{croppedImageUrl && (
  <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
)}
*/
