import { Store } from "undux";
import { IMessage } from "../../resources/IMessage";
import { IAppGlobalStore } from "../../stores/IAppGlobalStore";
import { BaseApiClient } from "./BaseApiClient"

export class MessageRoomsApiClient extends BaseApiClient<IMessage> {
  private _roomId: String;

  constructor(store: Store<IAppGlobalStore>, status: String) {
    super(store);
    this._status = status;
  }

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    //console.log(`/api/v1/message_rooms?status=${this._status}&`)
    return `/api/v1/message_rooms?status=${this._status}&`;
  }
}
