import React from 'react';
import { Breadcrumb, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router-dom';
import { JobApiClient } from '../../actions/clients/JobApiClient';
import { IFootprintJobParams } from '../../resources/IFootprintJobParams';
import { ILoginJobParams } from '../../resources/ILoginJobParams';
import { IMember } from '../../resources/IMember';
import { IMemberCondition } from '../../resources/IMemberCondition';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import FootprintJobWizardModal from '../job/FootprintJobWizardModal';
import LoginJobWizardModal from '../job/LoginJobWizardModal';
import MemberList from './MemberList';

interface IState extends common.IState {
  condition: IMemberCondition;
  footprintJobParams: IFootprintJobParams;
  loginJobParams: ILoginJobParams;
  showFootprintJobWizard: boolean;
  showLoginJobWizard: boolean;
}

class MemberListPage extends common.BasePage<common.IProps<common.IParams>, IState> {
  private _client: JobApiClient;

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this._client = new JobApiClient(props.globalStore);
    this.state = {
      condition: {},
      footprintJobParams: {},
      loginJobParams: {},
      showFootprintJobWizard: false,
      showLoginJobWizard: false
    };
  }

  render() {
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.Item active>会員一覧</Breadcrumb.Item>
        </Breadcrumb>
        <div className="d-flex pb-3">
          <LinkContainer to="/members/new">
            <Button >新規作成</Button>
          </LinkContainer>
          &nbsp;
          <Button  onClick={() => this.launchLoginJobWizard()}>ログイン日時を更新</Button>
        </div>
        <MemberList page={this.state.page} onPageSelected={(page) => this.selectPage(page)} onResourceSelected={(member) => this.onMemberSelected(member)} onSearchConditionChanged={(condition) => this.onSearchConditionChanged(condition)}/>
        {this.state.showFootprintJobWizard && (
          <FootprintJobWizardModal value={this.state.footprintJobParams} onClosed={() => this.onFootprintJobWizardClosed()}/>
        )}
        {this.state.showLoginJobWizard && (
          <LoginJobWizardModal value={this.state.loginJobParams} onClosed={() => this.onLoginJobWizardClosed()}/>
        )}
      </React.Fragment>
    );
  }

  private onMemberSelected(member?: IMember) {
    this.props.history.push(`/members/${member?.id}`);
  }

  private async launchLoginJobWizard() {
    this.setState({
      loginJobParams: {
        member: this.state.condition,
        member_interval: {
          min: 60,
          max: 180
        }
      }
    });
    this.setState({ showLoginJobWizard: true });
  }

  private launchFootprintJobWizard() {
    this.setState({
      footprintJobParams: {
        member: this.state.condition,
        member_interval: {
          min: 60,
          max: 180
        },
        target: {
          sex: this.state.condition.sex === 'male' ? 'female' : 'male',
          type: 'actual'
        },
        target_interval: {
          min: 60,
          max: 180
        }
      }
    });
    this.setState({ showFootprintJobWizard: true });
  }

  private onFootprintJobWizardClosed() {
    this.setState({ showFootprintJobWizard: false });
  }

  private onLoginJobWizardClosed() {
    this.setState({ showLoginJobWizard: false });
  }

  private onSearchConditionChanged(condition: IMemberCondition) {
    this.setState({ condition });
    //console.log('onSearchConditionChanged', { condition });
  }
}

export default withRouter(AppDataStore.withStores(MemberListPage));
