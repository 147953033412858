import React from "react";
import { Modal, Button, Image } from 'react-bootstrap';
import { Subscription } from 'rxjs';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BaseComponent';
import Utils from '../../utils/Utils';

interface IProps extends common.IProps {
};

interface IState extends common.IState {
}

class RankingMemberPopUp extends common.BaseComponent<IProps, IState> {
  private subscription: Subscription | null = null;

  constructor(props: IProps) {
    super(props);
    const myProfile = Utils.loadMyProfile()

    this.state = {
      show: myProfile.sex === '男性' && myProfile.paid === false
    };
  }

  private handleShow() {
    this.setState({ show: true });
  }

  private handleClose() {
    //this.setState({ show: false });
    this.props.onGoToSearch?.()
  }

  render() {
    return (
      <>
        <Modal show={this.state.show} >
          <Modal.Header closeButton onClick={() => this.handleClose()}>
            <Modal.Title>ランキング</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ testAlign: "center"}}>
            <center>
            <Image width="100%" src="/images/member/paid_02@2x.png" />
            </center>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleClose()}>
              キャンセル
            </Button>
            <Button variant="primary"
              onClick={() => this.onSubmit()}>
              有料会員ページへ
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  private onSubmit() {
    this.props.onGoToMemberPurchase?.();
    //this.handleClose();
  }
}

export default AppDataStore.withStores(RankingMemberPopUp);
