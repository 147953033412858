import * as common from '../common/BaseListComponent';
import AppDataStore from '../../stores/AppDataStore';
import { BaseApiClient } from '../../actions/clients/BaseApiClient';
import { Row, Col, Card } from 'react-bootstrap';
import LoadingIndicator from '../common/LoadingIndicator';
import Paginator from '../common/Paginator';
import { IResource } from '../../resources/IResource';
import { PointHistoriesApiClient } from '../../actions/clients/PointHistoriesApiClient';
import { DateTimeConverter } from '../../converters/DateTimeConverter';
import { PointHistorySatusConverter } from '../../converters/PointHistorySatusConverter';

interface IProps extends common.IProps<IResource> {
}

interface IState extends common.IState<IResource> {
}

class PointHistoryList extends common.BaseListComponent<IResource, IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    await super.componentDidMount();
  }

  render() {
    //console.log(this.state.response);
    return (
      <>
        <LoadingIndicator watch={this.state.response}>

          {this.state.response?.data.map((f, index) => (
            <Card className="list_card" key={index}>
              <div>
                <span style={{ fontSize: "14px"　}}>
                {PointHistorySatusConverter.convert(f.type)}<br />
                 &nbsp;&nbsp;{f.point}ポイント
                </span>
                <span style={{ fontSize: "14px", display: "flex", justifyContent: "right", alignItems: "flex-end"　}}>
                   {DateTimeConverter.convert(f.date)}
                </span>
              </div>
              <hr />
            </Card>
          ))}

          {this.state.response?.data.length === 0 && (
            <Row>
              <Col xs={2} md={2}></Col>
              <Col className="nodata" xs={8} md={8}>データが見つかりません。</Col>
              <Col xs={2} md={2}></Col>
            </Row>
          )}

          <Paginator currentPage={this.state.response?.meta.current_page} totalPage={this.state.response?.meta.total_pages} onPageSelected={(page) => this.selectPage(page)}/>
        </LoadingIndicator>
      </>
    );
  }

  protected createClient(): BaseApiClient<IOffer> {
    return new PointHistoriesApiClient(this.props.globalStore);
  }

  protected buildSearchCondition(page?: number) {
    return Object.assign({}, {}, { page: page });
  }

}

export default AppDataStore.withStores(PointHistoryList);
