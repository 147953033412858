import { IResource } from "../../resources/IResource";
import { BaseApiClient } from "./BaseApiClient"

export class WatchThisApiClient extends BaseApiClient<IResource> {
  private _offerId: number;

  constructor(store: Store<IAppGlobalStore>, offerId: number) {
    super(store);
    this._offerId = offerId;
  }

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    console.log(`api/v1/offers/${this._offerId}`);
    return `api/v1/offers/${this._offerId}`;
  }
}
