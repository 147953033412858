import { BaseApiClient } from "../BaseApiClient"

export class MemberRegistApiClient extends BaseApiClient<IOption> {

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    return 'api/v1/members/profile';
  }
}

/*
POST
{
  "member": {
    "name": "はな",
    "phone_number": "09099999999",
    "sex": "female",
    "birth_day": "1980/1/1",
    "prefecture_id": 1,
    "password": "abcd1234",
    "password_confirmation": "abcd1234"
  }
}

{
  "result": true,
  "data": {
    "jwt": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE1NTI3MzU0MDQsInN1YiI6MTh9.o0vzGSK8iyKiR8prEQ0i22emsskpkn4zXYwj-Naip6k",
    "point_bonus": 30
  }
}
*/
