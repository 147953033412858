
export class OfferRelationConverter {
  static convert(value?: number): string {
    switch(value) {
      case 0:
        return 'いいね';
      case 1:
        return 'みてね';
      case 2:
        return 'OKする';
      case 3:
        return 'メッセージを送る';
      default:
        return '';
    }
  }

}

/*
case none = 0
case from_offer
case offerd
case matching
case from_reoffer   // 自分から逆オファー済み
case roffered       // 被逆オファー
*/
