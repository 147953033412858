import * as common from '../common/BaseListComponent';
import AppDataStore from '../../stores/AppDataStore';
import { BaseApiClient } from '../../actions/clients/BaseApiClient';
import { Image, Col, Row, Button } from 'react-bootstrap';
import LoadingIndicator from '../common/LoadingIndicator';
import Paginator from '../common/Paginator';
import { IOffer } from '../../resources/IOffer';
import { OfferedsApiClient } from '../../actions/clients/OfferedsApiClient';
import Utils from '../../utils/Utils';

interface IProps extends common.IProps<IOffer> {
}

interface IState extends common.IState<IOffer> {
  condition: IOfferCondition;
}

class OfferedsList extends common.BaseListComponent<IOffer, IProps, IState> {

  private storageKey: String = "OfferedsList";  // JSON保存のキー

  constructor(props: IProps) {
    super(props);
    this.state = {
      condition: {}
    };
  }

  async componentDidMount() {
    // 戻ってきた場合は保存されたJSONで一覧を表示
    const json = Utils.getJson(this.storageKey);
    if ( Object.keys(json).length > 0 ) {
      this.setResponse(json);
    } else {
      await super.componentDidMount();
    }
  }

  setResponse(json: any) {
    if (json === null) return;
    this.setState({ "response": json.response });
    Utils.removeJson(this.storageKey)
  }

  render() {
    ////console.log(this.state.response);
    return (
      <>
        <LoadingIndicator watch={this.state.response}>

          <div style={{ height: 10 }}></div>

          {this.state.response?.data.map((offer) => (
            <section style={{ display: "block"}}>
              <article class="article_user-like">
                <div class="wrapper">
                  <ul class="article_lists">
                    <li class="article_list">
                      <div class="article_list_thumb article_list_thumb">
                        <div class=" middle">
                          <a onClick={(e:any) => this.onMemberSelected(offer.member)}>
                          <Image style={{ height: "auto" }}
                               src={Utils.toImageUrl(offer.image)} id={offer.member.id}
                               onClick={(e:any) => this.onMemberSelected(offer.member)} />
                          </a>
                      </div>
                      </div>
                      <div class="article_list_content article_list_content">
                        <i class="content_time ">{offer.created_at}</i>
                        <div class="article_list_content_info">
                          <p class="content_name">
                            &nbsp;&nbsp;{offer.member.name}
                            <br />&nbsp;&nbsp;
                            {offer.member.age}歳&nbsp;&nbsp;{offer.member.height}cm
                          </p>
                          <p class="message">
                            &nbsp;&nbsp;{offer.message}&nbsp;&nbsp;
                          </p>
                        </div>
                        <div class="article_list_content_btns">
                          <div class="flex" style={{ justifyContent: "flex-end" }}>
                              <p class="like_thanks">
                                <Button style={{ width: "100%", fontSize: "10px" }}
                                 onClick={(e:any) => this.onMemberSelected(offer.member)}>
                                  プロフィール確認
                                </Button>
                              </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </article>
            </section>

          ))}

          {this.state.response?.data.length === 0 && (
            <Row>
              <Col xs={2} md={2}></Col>
              <Col className="nodata" xs={8} md={8}>データが見つかりません。</Col>
              <Col xs={2} md={2}></Col>
            </Row>
          )}

          <Paginator currentPage={this.state.response?.meta.current_page} totalPage={this.state.response?.meta.total_pages} onPageSelected={(page) => this.selectPage(page)}/>
          <div style={{ height: 40 }}></div>
        </LoadingIndicator>
      </>
    );
  }

  private onMemberSelected(member?: IMember) {
    Utils.setItem("eventKeyOffer", this.props.eventKey);

    // 戻った場合に再建策しないように
    Utils.setJson(this.storageKey, this.state);

    this.props.onResourceSelected(member);
  }

  protected createClient(): BaseApiClient<IOffer> {
    return new OfferedsApiClient(this.props.globalStore);
  }

  protected buildSearchCondition(page?: number) {
    return Object.assign({}, this.state.condition, { page: page });
  }

  private async accept(offer: IOffer) {
    await this.client.update(offer);
    await this.loadResources(this.props.page);
  }

  private async search(condition: IOfferCondition) {
    this.setState({ condition });
    await this.loadResources(1);
  }
}

export default AppDataStore.withStores(OfferedsList);
