import * as common from '../common/BaseListComponent';
import AppDataStore from '../../stores/AppDataStore';
import { IMember } from '../../resources/IMember';
import { BaseApiClient } from '../../actions/clients/BaseApiClient';
import { SearchMembersApiClient } from '../../actions/clients/SearchMembersApiClient';
import React from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import LoadingIndicator from '../common/LoadingIndicator';
import Paginator from '../common/Paginator';
import Utils from '../../utils/Utils';
import { IResource } from '../../resources/IResource';

interface IProps extends common.IProps<common.IParams> {
  //  onReload?: () => void;
}

interface IState extends common.IState<IResource> {
}

class SearchList extends common.BaseListComponent<IMember, IProps, IState> {
  private type: Int = 0;
  private storageKey: String = "";  // JSON保存のキー

  constructor(props: IProps) {
    super(props);
    this.type = +props["type"];
    this.state = { page: 1 };
    this.storageKey = `search_datalist_${this.type}`
  }

  async componentDidMount() {
    // 戻ってきた場合は保存されたJSONで一覧を表示
    const json = Utils.getJson(this.storageKey);
    if ( Object.keys(json).length > 0 ) {
      this.setResponse(json);
    } else {
      await super.componentDidMount();
    }
  }

  setResponse(json: any) {
    if (json === null) return;
    this.setState({ "response": json.response });
    Utils.removeJson(this.storageKey)
  }

  async componentDidUpdate(prevProps) {
    // 典型的な使い方(props を比較することを忘れないでください)
    if (this.props.page !== prevProps.page) {
      //super.load();
      //this.loadResources(1);
    }
  }

  private isNew(today, created_at) {
    var diff = Date.parse(today) - Date.parse(created_at);
    if (Math.ceil(diff/((60*60*24)*1000)) <= 7) {
        return true;
    }
    return false;
  }

  private displayProfileImage(member?: IResource) {
    if (member?.type === "member" && member?.profile_image) {
      return (
        <Image
          className="member_img"
          style={{ width: "100%", aspectRatio: "1/1", objectFit: "cover" }}
          src={Utils.toImageUrl(member.profile_image)}
          onClick={(e:any) => this.onMemberSelected(member)}></Image>
      );
    } else if (member?.type === "event" && member?.image && member?.url) {
      return member?.active ? (
        <a href={member.url} target="_blank" style={{ cursor: "inherit" }} rel="noreferrer">
          <Image
            className="member_img"
            style={{ width: "100%", aspectRatio: "1/1", objectFit: "cover" }}
            src={Utils.toImageUrl(member.image)}
          />
        </a>
      ) : (
        <Image
          className="member_img"
          style={{ width: "100%", aspectRatio: "1/1", objectFit: "cover" }}
          src={Utils.toImageUrl(member.image)}
        />
      );
    } else {
      return (
        <Image
          className="member_img"
          style={{ width: "100%", aspectRatio: "1/1", objectFit: "cover" }}
          src="/images/profile/avatar-default.png" />
      );
    }
  }

  render() {
    // console.log(this.state.response?.data);
    var dt = new Date();
    const today = dt.toISOString();

    return (
      <React.Fragment>
        <LoadingIndicator watch={this.state.response}>
          <Container style={{ paddingBottom: '100px' }}>
            <Row>
              {this.state.response?.data.map((member, index) => (
              <Col xs={6} md={6} key={index}>
                <div className="item">
                  <div className="title-header">
                      {this.displayProfileImage(member)}

                      {this.isNew(today, member.created_at) ? <span class="new"> NEW</span> : ''}

                      {member?.type === "member" &&
                        (member.tweet?.length > 12 ? <p>{member.tweet?.substr( 0, 12)}...</p>: <p>{member.tweet}</p>)}

                  </div>

                  <h3 style={{ textAlign: "center" }} onClick={(e:any) => this.onMemberSelected(member)} >
                    {member?.type === "member" && (
                      <span
                        className="badge member_badge"
                        style={{ backgroundColor: Utils.getLoginStatusColor(member) }}> </span>
                    )}
                    {member.age && `${member.age}歳`} {member.prefecture}
                  </h3>

                </div>
                <div style={{ height: 10 }}></div>
              </Col>
              ))}
              {this.state.response?.data.length === 0 && (
                <Col className="nodata" colSpan={12}>
                  <span >データが見つかりません。</span>
                </Col>
              )}
            </Row>
            <Row>
               <Col xs={12} md={12}>
               <Paginator currentPage={this.state.response?.meta.current_page}
                          totalPage={this.state.response?.meta.total_pages}
                          onPageSelected={(page) => this.selectPage(page)}  />
               </Col>
             </Row>
          </Container>
        </LoadingIndicator>
      </React.Fragment>
    );
  }

  private onMemberSelected(member?: IMember) {
    // タブキーの保存
    Utils.setItem("eventKeySearch", this.props.eventKey);

    // 戻った場合に再建策しないように
    Utils.setJson(this.storageKey, this.state);

    this.props.onResourceSelected(member);
  }

  protected createClient(): BaseApiClient<IRoom> {
    return new SearchMembersApiClient(this.props.globalStore, this.type );
  }

  protected buildSearchCondition(page?: number) {
    this.setState({ page: page})
    //return Object.assign({}, {'q[prefecture_id_in][]': 13}, { page: page });
    //return Object.assign({}, this.state.condition, { page: page });
    // 検索条件をAPIに合うように加工
    try {
      const obj = Utils.getItem('memberSearchCondition');
      var params = {};
      for (const [key, value] of Object.entries(obj)) {
        ////console.log(`KEY is ${key}, VALUE is ${value}`);
        //params[`q[${key}]`] = value;
        switch (key) {
          case 'prefecture_id_in':
            params[`q[${key}]`] = [value];
            break;
          default:
            params[`q[${key}]`] = value;
        }
      }
      //console.log(params);
      return Object.assign({}, params, { page: page });
    } catch (error) {
      //console.log(error);
    }
    return Object.assign({}, {}, { page: page });
  }

  /*private async search() {
    this.setState({});
    if (this.props.onSearchConditionChanged) {
      this.props.onSearchConditionChanged();
    }
    //await this.loadResources(1);
  }*/

  private onReload () {
    (async () => this.loadResources(1) )();
  }
}

export default AppDataStore.withStores(SearchList);
