import { BaseApiClient } from "../BaseApiClient"
import { IResource } from '../../../resources/IResource';

export class TcApiClient extends BaseApiClient<IResource> {

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    return 'api/v1/tc/transactions';
  }

  getWebURL(transaction_id) {
    const endpoint = process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
    return endpoint + "/tc/settlement?transaction_id=" + transaction_id;
  }
}
