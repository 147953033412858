import * as common from '../common/BaseListComponent';
import AppDataStore from '../../stores/AppDataStore';
import { IMember } from '../../resources/IMember';
import { BaseApiClient } from '../../actions/clients/BaseApiClient';
import { MemberApiClient } from '../../actions/clients/MemberApiClient';
import React from 'react';
import { Accordion, Card, Table, Image } from 'react-bootstrap';
import LoadingIndicator from '../common/LoadingIndicator';
import Paginator from '../common/Paginator';
import MemberSearchForm from './MemberSearchForm';
import { DateTimeConverter } from '../../converters/DateTimeConverter';
import { IMemberCondition } from '../../resources/IMemberCondition';
import Utils from '../../utils/Utils';

interface IProps extends common.IProps<IMember> {
  condition?: IMemberCondition;
  hideSexCondition?: boolean;
  onSearchConditionChanged?: (condition: IMemberCondition) => void;
}

interface IState extends common.IState<IMember> {
  condition: IMemberCondition;
}

class MemberList extends common.BaseListComponent<IMember, IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      condition: props.condition || { sex: 'female' }
    };
  }

  async componentDidMount() {
    await super.componentDidMount();
    if (this.props.onSearchConditionChanged) {
      this.props.onSearchConditionChanged(this.state.condition);
    }
  }

  render() {
    //console.log("--");
    ////console.log(this.state.response);
    return (
      <React.Fragment>
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              検索条件
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <div className="p-3">
                <MemberSearchForm condition={this.state.condition} hideSexField={this.props.hideSexCondition} onSearchRequested={(condition) => this.search(condition)}/>
              </div>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <LoadingIndicator watch={this.state.response}>
          <Table bordered>
            <thead>
              <tr>
                <th>ID</th>
                <th>電話番号</th>
                <th>ニックネーム</th>
                <th>最終ログイン日時</th>
              </tr>
            </thead>
            <tbody>
              {this.state.response?.data.map((member) => (
                <tr className="clickable" key={member.id} onClick={() => this.selectResource(member)}>
                  <td>{member.id}</td>
                  <td>{member.phone_number?.replace('+81', '0')}</td>
                  <td>
                    <Image width={40} height={40} src={Utils.toImageUrl(member.profile_image?.url)} roundedCircle id={member.id} />
                    &nbsp;&nbsp;{member.name}
                  </td>
                  <td>{DateTimeConverter.convert(member.last_active_at)}</td>
                </tr>
              ))}
              {this.state.response?.data.length === 0 && (
                <tr>
                  <td colSpan={3}>データが見つかりません。</td>
                </tr>
              )}
            </tbody>
          </Table>
          <Paginator currentPage={this.state.response?.meta.current_page} totalPage={this.state.response?.meta.total_pages} onPageSelected={(page) => this.selectPage(page)}/>
        </LoadingIndicator>
      </React.Fragment>
    );
  }

  protected createClient(): BaseApiClient<IMember> {
    return new MemberApiClient(this.props.globalStore);
  }

  protected buildSearchCondition(page?: number) {
    return Object.assign({}, this.state.condition, { page: page });
  }

  private async search(condition: IMemberCondition) {
    this.setState({ condition });
    if (this.props.onSearchConditionChanged) {
      this.props.onSearchConditionChanged(condition);
    }
    await this.loadResources(1);
  }
}

export default AppDataStore.withStores(MemberList);
