import React from 'react';
import { Alert, Form } from 'react-bootstrap';
import { IJobInterval } from '../../resources/IJobInterval';
import * as common from '../common/BaseComponent';
import { InputEventReflector, InputEventReflectorOptions } from '../common/InputEventReflector';

export interface IProps extends common.IProps {
  value: IJobInterval;
  onValueChanged: (value: IJobInterval) => void;
}

export interface IState extends common.IState {
  value: IJobInterval;
}

export abstract class BaseIntervalWizardPage<TProps extends IProps, TState extends IState> extends common.BaseComponent<TProps, TState> {
  private _reflector: InputEventReflector;

  constructor(props: TProps) {
    super(props);
    this._reflector = new InputEventReflector();
  }

  protected abstract get title(): string;

  protected abstract get note(): string;

  render() {
    return (
      <>
        <h6>{this.title}</h6>
        <Alert className="my-3" variant="secondary">
          <small>{this.note}</small>
        </Alert>
        <Form>
          <Form.Group>
            <Form.Label>最小待ち時間 (秒)</Form.Label>
            <Form.Control type="number" name="min" onChange={(e) => this.changeValue(e)} value={this.state.value.min} min={0} max={600}/>
          </Form.Group>
          <Form.Group>
            <Form.Label>最大待ち時間 (秒)</Form.Label>
            <Form.Control type="number" name="max" onChange={(e) => this.changeValue(e)} value={this.state.value.max} min={30} max={6000}/>
          </Form.Group>
        </Form>
      </>
    );
  }

  protected changeValue(e: any, options?: InputEventReflectorOptions) {
    const value = this.state.value;
    const result = this._reflector.execute(value, e, options);
    if (result) {
      this.setState({ value: result || value });
      this.props.onValueChanged(result);
    }
  }
}
