import * as common from '../common/BaseListComponent';
//import { Image } from 'react-bootstrap';
import AppDataStore from '../../stores/AppDataStore';
import { IRoom } from '../../resources/IRoom';
import { IMessage } from '../../resources/IMessage';
import { BaseApiClient } from '../../actions/clients/BaseApiClient';
import { MessageApiClient } from '../../actions/clients/MessageApiClient';
import React from 'react';
import LoadingIndicator from '../common/LoadingIndicator';
import Paginator from '../common/Paginator';
import Utils from '../../utils/Utils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";
//import { MyProfileApiClient } from '../../actions/clients/MyProfileApiClient';

interface IProps extends common.IProps<IRoom> {
    onSearchRequested?: () => void;
    room: IRoom;
}

interface IState extends common.IState<IResource> {

}

class RoomMessageList extends common.BaseListComponent<IRoom, IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      room: this.props.room,
      message_room_id: this.props.room.id,
      body: '',
      image_name: 'Choose file',
      image_src: '',
      image_base64: '',
      posted_message_text: '',
      posted_message_image: '',
      posted_message_date: '',
      posted_message_time: '',
      myProfileData: Utils.loadMyProfile()
    };
  }

  async componentDidMount() {
    await super.componentDidMount();
    if (this.props.onSearchConditionChanged) {
      this.props.onSearchConditionChanged();
    }
    //const myProfile = await new MyProfileApiClient(this.props.globalStore).get();
    //this.setState({ myProfileData: myProfile });
    //window.scrollTo(0, document.body.scrollHeight);

    // メッセージ最後までスクロール
    var element = document.documentElement;
    var bottom = element.scrollHeight - element.clientHeight;
    window.scroll(0, bottom);
  }

  private isRead(message) {
    var read = ""
    switch (this.state.myProfileData?.age_verification) {
      case Utils.ageVerificationStatus.not_verified:
        break;
      case Utils.ageVerificationStatus.rejected:
        break;
      case Utils.ageVerificationStatus.verifying:
        break;
      case Utils.ageVerificationStatus.verified:
        //'年齢確認済';
        if(this.state.myProfileData.sex === '女性') {
          read = message.unread ? '未読' : '既読'
        }
        if(this.state.myProfileData.sex === '男性') {
          if (this.state.myProfileData.paid === false) {
            break;
          } else {
            if (this.state.myProfileData.premium_plan_enabled === false) {
              break;
            } else {
              read = message.unread ? '未読' : '既読'
            }
          }
        }
        break;
      default:
        break;
    }
    return read
  }

  private clickKey() {
    // プレミアム購入
    this.props.ongoToPremium?.();
  }

  render() {
    return (
      <React.Fragment>

        <LoadingIndicator watch={this.state.response}>
        <div className="messaging">
          <div className="mesgs">

            <div className="msg_history">
              {this.state.response?.data.map((message) => (

                <div key={message.id} className={message.member_id === this.state.myProfileData?.id ? 'outgoing_msg' : 'incoming_msg'}>
                  <div className={message.member_id === this.state.myProfileData?.id ? 'sent_msg' : 'received_msg'}>
                    <div className={message.member_id === this.state.myProfileData?.id ? 'sent_with_msg' : 'received_with_msg'}>
                      <p>
                      {message.body && <span dangerouslySetInnerHTML={{__html: message.body?.replace(/\r?\n/g, '<br />') }} ></span>}
                      {message.image && <img src={process.env.REACT_APP_API_ENDPOINT + message.image} alt='icon' />}
                      </p>
                      {message.member_id !== this.state.myProfileData?.id && <span className="time_date">{message.date}        |       {message.time}        </span>}
                      {message.member_id === this.state.myProfileData?.id && <span className="time_date">{message.date}        |       {message.time}   &nbsp;     {this.isRead(message)? this.isRead(message) : <FontAwesomeIcon onClick={(e:any) => this.clickKey()} icon={faKey} style={{ color: "#747474" }} size="sm" />}</span>}
                    </div>
                  </div>
                </div>

              ))}
            </div>

            <Paginator currentPage={this.state.response?.meta.current_page} totalPage={this.state.response?.meta.total_pages} onPageSelected={(page) => this.selectPage(page)}/>

          </div>
        </div>

        </LoadingIndicator>

        <div className="input_msg_write">
          <textarea className="md-textarea form-control" rows="3"
           style={{ backgroundColor: "#090d13", color: "#fff" }}
           width="85%" placeholder="Type a message" value={this.state.body} onChange={(e: any) => this.updateInputValue(e)} />
        </div>

        <div className="row">

          <div className="col-md-12">
            <div className="form-group">

              <div className="input-group">
                <div className="custom-file">
                </div>
                <div className="input-group-append">
                  <label className="image_post_label">
                    <input type="file" name="file"  onChange={(e:any) => this.preview(e) }  />ファイルを選択
                  </label>
                </div>
                <div className="input-group-append">
                  <span className="input-group-text text-light button_label" id="upload_button" onClick={(e: any) => this.submitImage(e)}>画像送信</span>
                </div>
                <div className="input-group-append">
                  <span className="input-group-text text-light button_label" id="upload_button" onClick={(e: any) => this.submit(e)}>メッセージ送信</span>
                </div>
              </div>
              {this.state.image_src && <img src={this.state.image_src} alt='read file' />}
            </div>

          </div>

        </div>

      </React.Fragment>
    );
  }

  private updateInputValue(e) {
    this.setState({
      body: e.target.value
    });
  }

  private preview(e:any) {
    const file = e.target.files[0] as File;
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result === null) {
        return;
      }
      e.target.value = '';
      var tmp = {
        content: reader.result.toString().split(',')[1],
        url: window.URL.createObjectURL(file)
      };
      this.setState({ image_src: tmp.url });
      this.setState({ image_name: file.name });
      this.setState({ image_base64: tmp.content });
    }
    reader.readAsDataURL(file);
  }

  private submit(e: any) {
    e.preventDefault();
    var resource = {
      message_room_id: this.state.room.id,
      message: {
        body: this.state.body
      }
    };
    try {
      let response;
      ( async () => response = await this.createClient().save(resource) )();
      if (response !== null) {
        (async () => this.loadResources(1) )();
        this.clearPostedParams()
        this.setState( {'posted_message_text': this.state.body });
        this.setPotedDate();
        this.clearParams();
        // 初回のみメッセージが取得できないことがあるのでここで呼ぶ
        (async () => await this.loadResources(1) )();
      }
    }
    catch (error) {
      //console.log(error)
    }
  }

  private submitImage(e: any) {
    if (this.state.image_base64 === '') {
      window.alert('画像を選択してください');
      return;
    }
    e.preventDefault();
    var resource = {
      message_room_id: this.state.room.id,
      message: {
        image: this.state.image_base64
      }
    };
    try {
      let response;
      ( async () => response = await this.createClient().save(resource) )();
      if (response !== null) {
        (async () => this.loadResources(1) )();
        this.clearPostedParams();
        this.setState( {'posted_message_image': this.state.image_src });
        this.setPotedDate();
        this.clearParams();

        // 初回のみメッセージが取得できないことがあるのでここで呼ぶ
        (async () => await this.loadResources(1) )();
        //window.scrollTo(0, document.body.scrollHeight);
      }
    }
    catch (error) {
      //console.log(error)
    }
  }

  private setPotedDate() {
    var now = new Date();
    var month = now.getMonth()+1;
    var day = now.getDate();
    var hour = now.getHours();
    var min = now.getMinutes();
    this.setState( {'posted_message_date': month + ':' + day });
    this.setState( {'posted_message_time': hour + ':' + min });
  }

  private clearParams() {
    this.setState({ body: '' });
    this.setState({ image_src: '' });
    this.setState({ image_name: 'Choose file' });
    this.setState({ image_base64: '' });
  }

  private clearPostedParams() {
    this.setState({ posted_message_text: '' });
    this.setState({ posted_message_image: '' });
    this.setState({ posted_message_date: '' });
    this.setState({ posted_message_time: '' });
  }

  private checkPost() {
    try {
      if ( this.state.response ) {
        if (this.state.response?.data[this.state.response?.data.length-1].body === this.state.posted_message_text)  {
          this.setState( { posted_message_text: "" } )
        }
      }
    } catch (error) {
      //console.log(error);
    }
  }

  protected createClient(): BaseApiClient<IMessage> {
    return new MessageApiClient(this.props.globalStore, this.state.room.id);
  }

  protected buildSearchCondition(page?: number) {
    return Object.assign({}, {}, { page: page });
  }

  private onReload () {
    (async () => await this.loadResources(1) )();
    this.clearParams();
    this.clearPostedParams();
  }
}

export default AppDataStore.withStores(RoomMessageList);
