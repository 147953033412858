import React from 'react';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import NotificationListPageHeader from './NotificationListPageHeader';
import NotificationList from './NotificationList';

interface IState extends common.IState {
}

class NotificationListPage extends common.BasePage<common.IProps<common.IParams>, IState> {


  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = { };
  }

  async componentDidMount() {;

  }

  render() {
    return (
      <React.Fragment>
        <NotificationListPageHeader  onReload={(e) => this.onReload(e)} />
        <div style={{ height: 0 }}></div>

        <NotificationList page={this.state.page} />

      </React.Fragment>
    );
  }

  // ページ更新
  private onReload(e: any) {
    window.location.reload();
  }

}

export default withRouter(AppDataStore.withStores(NotificationListPage));
