import React from 'react';
import { Button, Col, Form, Jumbotron, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import SystemNotification from '../common/SystemNotification';
import FireBaseLogEventManager from '../../utils/FireBaseLogEventManager';
import FireBaseLogEvent from '../../utils/enums/FireBaseLogEvent';
import { ResetPasswordSmsApiClient } from '../../actions/clients/resetPassword/ResetPasswordSmsApiClient';

interface IParams extends common.IParams {
}

interface IProps extends common.IProps<IParams> {
}

interface IState extends common.IState {
  phone: string;
}

class ResetPasswordPage extends common.BasePage<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = { phone: '', };
  }

  componentDidMount() {
  }

  render() {
    return (
      <Row>
        <Col xs={12} md={12}>
          <Jumbotron>
            <SystemNotification/>
            <Form className="mt-5">
              <Form.Group>
                <Form.Label>電話番号を入力してください<span style={{ fontSize: "12px" }}></span></Form.Label>
                <Form.Control type="phone" name="phone" onChange={(e) => this.changePhone(e)} value={this.state.phone}/>
              </Form.Group>
              <Form.Group>
                <Form.Label><span style={{ fontSize: "12px" }}>次へをタップするとSMSコードが送信されます</span></Form.Label>
                <Button type="submit" variant="primary" size="lg" block disabled={!this.state.phone} onClick={(e) => this.sendSms(e)}>次へ</Button>
              </Form.Group>
            </Form>
            <Button type="submit" variant="primary" size="lg" block onClick={(e) => this.props.history.push('/signin')}>戻る</Button>
          </Jumbotron>
        </Col>
      </Row>
    );
  }

  private changePhone(e: any) {
    this.setState({ phone: e.target.value.replace('-', '') });
  }

  private async sendSms(e: any) {
    e.preventDefault();

    // log event
    FireBaseLogEventManager.sendLogEvent(FireBaseLogEvent.inputTelButtonClick);

    var params = {
      "member": {
        "phone_number": this.state.phone,
        "os": "web"
      }
    };
    const data = await new ResetPasswordSmsApiClient(this.props.globalStore).post(params);

    if (data?.result === true) {
      this.props.history.push({
        pathname: `/reset_password_sms`,
        state: { phone: this.state.phone }
      });
    }
  }
}

export default withRouter(AppDataStore.withStores(ResetPasswordPage));
