import * as common from '../common/BasePage';
import { Redirect, withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore'

class TopPage extends common.BasePage<common.IProps<common.IParams>, common.IState> {
  render() {
    return <Redirect to={'/search'}/>
  }
}

export default withRouter(AppDataStore.withStores(TopPage));
