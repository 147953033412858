import React from 'react';
import { Button, Image, Row, Col, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BaseForm';
import ActionButton from '../common/ActionButton';
import MyProfilePageHeader from './MyProfilePageHeader';
import IMyProfile from "../resources/IMyProfile"
import { IMemberImage } from '../resources/IMemberImage';
import Utils from '../../utils/Utils';
import MasterDataSelector from '../common/form/MasterDataSelector';
import GeneralDataSelector from '../common/form/GeneralDataSelector';
import { ProfileBasicApiClient } from '../../actions/clients/profile/ProfileBasicApiClient';
import { ProfileSubImageApiClient } from '../../actions/clients/profile/ProfileSubImageApiClient';
import Swal from 'sweetalert2'
// https://sweetalert2.github.io/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import nl2br from 'react-nl2br';
import { confirmAlert } from 'react-confirm-alert';

interface IState extends common.IState {
  isDialogOpen: boolean;
  myProfileData: IMyProfile
}

class MyProfilePage extends common.BaseForm<IMember, IProps, IState> {

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = { isDialogOpen: false, isMemberDialogOpen: false, myProfileData: Utils.loadMyProfile()  };
  }

  // プロフィールデータ取得、マスタデータ処理
  async getMyProfile() {
    //const myProfile = await new MyProfileApiClient(this.props.globalStore).get();
    const myProfile = await Utils.getMyProfile(this.props.globalStore);
    this.setState({ resource: myProfile });

    const option = this.props.globalStore.get('masterData').option;

    // マスタのキー
    const profile_keys = ["body_types", "annual_incomes", "drinking_types", "smoking_types", "heights", "prefectures", "desired_dating_plans", "hometowns", "occupations"]
    // 該当するプロフィールの項目
    const profile_ids = ["body_type", "annual_income", "drinking_type", "smoking_type", "height", "prefecture", "desired_dating_plan", "hometown", "occupation"]

    try {
      // APIからは項目名で来るのでマスタのidに置き換える
      for(const k in profile_keys) {
        const profile_id = profile_ids[k];
        var values = option[profile_keys[k]];
        // 職業は性別で分かれているので選択肢を先に取得しておく
        if ( profile_id === "occupation" ) {
          if (this.resource?.sex === '男性') {
            values = option["occupations"]["male"];
          } else {
            values = option["occupations"]["female"];
          }
          this.setState({ occupations: values});
        }
        try {
          const opt = values?.filter(opt => opt['name'] === this.state.resource[profile_id])[0]
          if (opt) {
            this.state.resource[profile_id] = opt['id'];
          }
        } catch (error) {
          //console.log(error);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  }

  async componentDidMount() {
    this.getMyProfile();
  }

  render() {

    //console.log(this.state.resource);
    return (
      <React.Fragment>
        <MyProfilePageHeader onPreview={this.onPreview} />

        <div style={{ height: 0 }}></div>

        <Form.Group>
          <Form.Label>メイン画像</Form.Label>
          <Row>
              <Col md={2}>
                <div className="d-flex justify-content-center">
                  <Image className="my-2" src={Utils.toImageUrl(this.state.resource?.profile_image?.image)} rounded fluid/>
                </div>
                <div className="d-flex justify-content-center">
                {this.state.resource?.profile_image?.status === undefined && (
                  <Button onClick={(e: any) => this.toMyImagePage(e)}>登録</Button>
                )}
                  {this.state.resource?.profile_image?.status === 1 && (
                    <Button onClick={(e: any) => this.toMyImagePage(e)}>編集</Button>
                  )}
                  {this.state.resource?.profile_image?.status === 0 && (
                    <Button>審査中</Button>
                  )}
                </div>
              </Col>
          </Row>
        </Form.Group>

        <div style={{ height: 5 }}></div>

        <Form.Group>
          <Form.Label>サブ画像</Form.Label>
          <Row>
            {this.visibleSubImages().map((item) => (
              <Col md={2} key={item.index}>
                <div className="d-flex justify-content-center">
                  <Image className="my-2" src={Utils.toImageUrl(item.image.image)} rounded fluid/>
                </div>
                <div className="d-flex justify-content-center">
                {item.image.status === 1 && (
                  <Button onClick={() => this.deleteSubImageAt(item.image.id)}>削除</Button>
                )}
                {item.image.status === 0 && (
                  <Button>審査中</Button>
                )}
                </div>
              </Col>
            ))}
            <Col md={2}>
              <div className="d-flex justify-content-center">
              </div>
              <div className="d-flex justify-content-center">
                <Button onClick={(e: any) => this.toMySubImagePage(e)}>追加</Button>
              </div>
            </Col>
          </Row>
        </Form.Group>

        <div style={{ height: 5 }}></div>

        <Row className="member-profile-row">
           <Col xs={8} md={8}>
            {this.state.resource?.age}歳 {this.state.resource?.prefecture}
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
            {this.state.resource?.login_status}
           </Col>
        </Row>

        <Row className="member-profile-row">
          <Col xs={4} md={4}>
           自己紹介
          </Col>
          <Col xs={8} md={8} className="member-profile-col-r">
          {nl2br(this.state.resource?.introduction?.content)}　&nbsp;
          {this.state.resource?.introduction?.status === undefined && (
            <Button onClick={(e: any) => { e.preventDefault(); this.props.history.push('/myprofileintro'); }}>登録</Button>
          )}
          {this.state.resource?.introduction?.status === 1 && (
            <Button onClick={(e: any) => { e.preventDefault(); this.props.history.push('/myprofileintro'); }}>編集</Button>
          )}
          {this.state.resource?.introduction?.status === 0 && (
            <Button>審査中</Button>
          )}
          </Col>
        </Row>

        <Row className="member-profile-row">
           <Col xs={4} md={4}>
            つぶやき
           </Col>
           <Col xs={8} md={8} className="member-profile-col-r">
           {this.state.resource?.tweet?.content}　&nbsp;
           {this.state.resource?.tweet?.status === undefined && (
             <Button onClick={(e: any) => { e.preventDefault(); this.props.history.push('/myprofiletweet'); }}>登録</Button>
           )}
           {this.state.resource?.tweet?.status === 1 && (
             <Button onClick={(e: any) => { e.preventDefault(); this.props.history.push('/myprofiletweet'); }}>編集</Button>
           )}
           {this.state.resource?.tweet?.status === 0 && (
             <Button>審査中</Button>
           )}
           </Col>
        </Row>

        <Row className="member-profile-row">
           <Col xs={4} md={4}>
            会いたい場所
           </Col>
           <Col xs={8} md={8} className="member-profile-col-r">
              {this.state.resource?.desired_meeting_place?.content}　&nbsp;
              {this.state.resource?.desired_meeting_place?.status === undefined && (
                <Button onClick={(e: any) => { e.preventDefault(); this.props.history.push('/myprofileplace'); }}>登録</Button>
              )}
              {this.state.resource?.desired_meeting_place?.status === 1 && (
                <Button onClick={(e: any) => { e.preventDefault(); this.props.history.push('/myprofileplace'); }}>編集</Button>
              )}
              {this.state.resource?.desired_meeting_place?.status === 0 && (
                <Button>審査中</Button>
              )}
           </Col>
        </Row>
        <Row className="member-profile-row-item">
           <Col xs={8} md={8}>
            希望のデートプラン
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
           <MasterDataSelector property="desired_dating_plan" masterDataKey="desired_dating_plans" value={this.state.resource?.desired_dating_plan}
            errors={this.state.errors?.desired_dating_plan} onChanged={(e) => this.changeResource(e, { type: 'number' })}/>
           </Col>
        </Row>
        <Row className="member-profile-row-item">
           <Col xs={8} md={8}>
            ニックネーム
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
            {this.state.resource?.name}
            <Button className="bg-dark text-light" onClick={(e: any) => { e.preventDefault(); this.props.history.push('/myprofilename'); }} style={{ backgroundColor: "#ffffff" }}>
              <FontAwesomeIcon icon={faChevronRight}  size="sm" style={{ color: "#A1A1A1" }}/>
            </Button>
           </Col>
        </Row>
        <Row className="member-profile-row-item">
           <Col xs={8} md={8}>
            年齢
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
            {this.state.resource?.age}歳&nbsp;&nbsp;
           </Col>
        </Row>
        <Row className="member-profile-row-item">
           <Col xs={8} md={8}>
            身長
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
            <Form.Control className="bg-dark text-light" type="number" name="height" onChange={(e) => this.changeResource(e)}
              value={this.state.resource?.height} isInvalid={!!this.state.errors?.height}/>
           </Col>
        </Row>
        <Row className="member-profile-row-item">
           <Col xs={8} md={8}>
            住んでいる地域
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
            <MasterDataSelector property="prefecture" masterDataKey="prefectures" value={this.state.resource?.prefecture}
              errors={this.state.errors?.prefecture} onChanged={(e) => this.changeResource(e, { type: 'number' })}/>
           </Col>
        </Row>
        <Row className="member-profile-row-item">
           <Col xs={8} md={8}>
            出身地
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
            <MasterDataSelector property="hometown" masterDataKey="prefectures" value={this.state.resource?.hometown}
              errors={this.state.errors?.hometown} onChanged={(e) => this.changeResource(e, { type: 'number' })}/>
           </Col>
        </Row>
        <Row className="member-profile-row-item">
           <Col xs={8} md={8}>
            体型
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
           <MasterDataSelector property="body_type" masterDataKey="body_types" value={this.state.resource?.body_type}
            errors={this.state.errors?.body_type} onChanged={(e) => this.changeResource(e, { type: 'number' })}/>
           </Col>
        </Row>
        { this.annual_income() }
        <Row className="member-profile-row-item">
           <Col xs={8} md={8}>
            お酒
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
            <MasterDataSelector property="drinking_type" masterDataKey="drinking_types" value={this.state.resource?.drinking_type}
             errors={this.state.errors?.drinking_type} onChanged={(e) => this.changeResource(e, { type: 'number' })}/>
           </Col>
        </Row>
        <Row className="member-profile-row-item">
           <Col xs={8} md={8}>
            タバコ
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
            <MasterDataSelector property="smoking_type" masterDataKey="smoking_types" value={this.state.resource?.smoking_type}
             errors={this.state.errors?.smoking_type} onChanged={(e) => this.changeResource(e, { type: 'number' })}/>
           </Col>
        </Row>
        <Row className="member-profile-row-item">
           <Col xs={8} md={8}>
            職業
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
            <GeneralDataSelector property="occupation"
            masterDataKey={`occupations.${this.state.resource?.sex}`}
            data={this.state.occupations}
            value={this.state.resource?.occupation}
            errors={this.state.errors?.occupation} onChanged={(e) => this.changeResource(e, { type: 'number' })}/>
           </Col>
        </Row>
        <Row className="member-profile-row">
           <Col xs={12} md={12} style={{ textAlign: "center" }}>
              <ActionButton style={{ width: "1000" }} onClick={(e: any) => this.update(e)}>プロフィール更新</ActionButton>
           </Col>
        </Row>

        <div style={{ height: 20 }}></div>

        <div style={{ height: 10 }}></div>

      </React.Fragment>
    );
  }

  // プロフィール更新
  private async update(e: any) {
    var params = {
      "member": {
        "height": this.state.resource.height,
        "body_type_id": this.state.resource.body_type,
        "prefecture_id": this.state.resource.prefecture,
        "hometown_id": this.state.resource.hometown,
        "desired_dating_plan_id": this.state.resource.desired_dating_plan,
        "annual_income_id": this.state.resource.annual_income,
        "drinking_type_id": this.state.resource.drinking_type,
        "smoking_type_id": this.state.resource.smoking_type,
        "occupation_id": this.state.resource.occupation
      }
    };
    const data = await new ProfileBasicApiClient(this.props.globalStore).post(params);
    //console.log(data);
    if (data.result === true) {
        Swal.fire({
          title: '',
          text: "プロフィールを更新しました",
          type: 'success',
        })
    } else {
      Swal.fire({
        title: '',
        text: data.message,
        type: 'error',
      })
    }
  }

  // 年収
  private annual_income = () => {
    if (this.state.resource?.sex === '男性') {
      return (
        <Row className="member-profile-row-item">
           <Col xs={8} md={8}>
            年収
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
           <MasterDataSelector property="annual_income" masterDataKey="annual_incomes" value={this.state.resource?.annual_income}
            errors={this.state.errors?.annual_income} onChanged={(e) => this.changeResource(e, { type: 'number' })}/>
           </Col>
        </Row>
      );
    }
    return (<span></span>);
  }

  // サブ画像削除
  private async deleteSubImageAt(id: number) {
    //console.log(id);
    confirmAlert({
      title: '',
      message: 'この画像を削除しますか？',
      buttons: [
        {
          label: 'キャンセル',
          //onClick: () => alert('Click Yes')
        },
        {
          label: 'OK',
          onClick: async () => {
            await new ProfileSubImageApiClient(this.props.globalStore).delete(id);
            this.getMyProfile();
          }
        }
      ]
    });

  }

  // サブ画像foreach用
  private visibleSubImages(): IMemberImage[] {
    const resource = this.state.resource;
    if (!resource || !resource.sub_image) {
      return [];
    }

    const items: IMemberImage[] = [];
    resource.sub_image.forEach((o, i) => {
        items.push({ image: o, index: i });
    });

    return items;
  }

/*  private visibleSubImages(): IVisibleItem[] {
    const resource = this.state.resource;
    if (!resource || !resource.sub_images) {
      return [];
    }

    const items: IVisibleItem[] = [];
    resource.sub_images.forEach((o, i) => {
      if (!o.delete) {
        items.push({ image: o, index: i });
      }
    });

    return items;
  }*/

  private onPreview = () => {
    this.openMemberDialog();
  }

  // ダイアログを開く
  private openMemberDialog = () => {
    this.setState({isMemberDialogOpen: true});
  }

  // ダイアログからのコールバックでダイアログを閉じてあげる
  private closeMemberDialog = () => {
    this.setState({isMemberDialogOpen: false});
  }

  private toMyImagePage(e: any) {
    e.preventDefault();
    this.props.history.push('/myprofileimage');
  }

  private toMySubImagePage(e: any) {
    e.preventDefault();
    this.props.history.push('/myprofilesubimage');
  }

}

export default withRouter(AppDataStore.withStores(MyProfilePage));
