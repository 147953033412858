import { IOption } from "../../resources/IOption";
import { BaseApiClient } from "./BaseApiClient"

export class InquiryApiClient extends BaseApiClient<IOption> {

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    return 'api/v1/inquiry';
  }
}
