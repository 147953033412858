import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

class FireBaseLogEventManager {

  static getAnalytics() {
    //console.log(process.env.REACT_APP_STAGE);

    var firebaseConfig = {}

    if(process.env.REACT_APP_STAGE === "development" || process.env.REACT_APP_STAGE === "stg" ) {
      // Your web app's Firebase configuration
      // For Firebase JS SDK v7.20.0 and later, measurementId is optional
      firebaseConfig = {
        apiKey: "AIzaSyAkI4GL48Qx_HjeDpdCa0EXwqM-Et1lON8",
        authDomain: "mitsumitsu-sr.firebaseapp.com",
        databaseURL: "https://mitsumitsu-sr.firebaseio.com",
        projectId: "mitsumitsu-sr",
        storageBucket: "mitsumitsu-sr.appspot.com",
        messagingSenderId: "31162693176",
        appId: "1:31162693176:web:1b750dfd00ebbb464bd8cd",
        measurementId: "G-LTSLG5TPXP"
      };
    } else if ( process.env.REACT_APP_STAGE === "prod" ) {
      firebaseConfig = {
        apiKey: "AIzaSyAkI4GL48Qx_HjeDpdCa0EXwqM-Et1lON8",
        authDomain: "mitsumitsu-sr.firebaseapp.com",
        databaseURL: "https://mitsumitsu-sr.firebaseio.com",
        projectId: "mitsumitsu-sr",
        storageBucket: "mitsumitsu-sr.appspot.com",
        messagingSenderId: "31162693176",
        appId: "1:31162693176:web:c4e3c5c660a023654bd8cd",
        measurementId: "G-8PD3DVXYK4"
      };
    }

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    return analytics;
  }

  // https://firebase.google.com/docs/analytics/events?hl=ja&platform=web
  static sendLogEvent(event: String, parameters: any = {}) {
    //console.log(event);
    //console.log(parameters);
    try {
      logEvent(FireBaseLogEventManager.getAnalytics(), event, parameters);
    } catch(e){}
  }

}

export default FireBaseLogEventManager;
