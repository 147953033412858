import React from 'react';
import { Button, Row, Col, Form  } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import WithdrawalPageHeader from './WithdrawalPageHeader';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Utils from '../../utils/Utils';
import { WithdrawalApiClient } from '../../actions/clients/profile/WithdrawalApiClient';
import Swal from 'sweetalert2'

interface IState extends common.IState {
}

class WithdrawalPage extends common.BasePage<common.IProps<common.IParams>, IState> {

  warnings = [
    {
      "title":"MITSUMITSUの全ての有料サービスを解約しました",
      "name": ""
    },
    {
      "title":"これまでのデータ全てが削除されます（保有ポイント、有料会員情報、メッセージ履歴など）",
      "name": ""
    },
    {
      "title":"退会後10日間は再登録する事ができません",
      "name": ""
    },
    {
      "title":"再登録した場合も過去のデータを引き継ぐことは一切できません",
      "name": ""
    }
  ];

  warning_checks = [];

  reasons = [
    { "code": "got_partner",  "title": "理想の条件の人と出会えた" },
    { "code": "not_matched",  "title": "希望する条件に合う人とマッチングしない" },
    { "code": "not_found_member_meeting_condition", "title": "希望する条件に合う人がいない" },
    { "code": "too_few_members_in_my_area", "title": "住んでいるエリアの会員が少なかった" },
    { "code": "expensive", "title": "料金が高い" },
    { "code": "concerning_privacy", "title": "プライバシーに不安がある" },
    { "code": "uncomfortable", "title": "お相手とのやり取りで不快な思いをした" },
    { "code": "too_many_notifications", "title": "お知らせやPush通知が多かった" },
    { "code": "few_likes", "title": "異性からのいいねが少なかった" },
  ];

  select_reasons = [];

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = { myProfileData: Utils.loadMyProfile(), warning_checks: [], select_reasons: [], text: "" };
  }

  render() {
    return (
      <React.Fragment>
        <WithdrawalPageHeader />

        <Row>
          <Col xs={12} md={12}  className="withdrawal-item-l">
            MITSUMITSUをご利用いただき誠にありがとうございます。退会につきましては以下の内容を必ずお読みになってください。
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}  className="withdrawal-item-title">
            プライベートモードをご存知ですか？
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}  className="withdrawal-item-l">
            プライバシーに不安のある方はプライベートモードをONにすることで安心してご利用いただけます。
          </Col>
        </Row>

        <Row style={{ paddingRight: "15px"}}>
          <Col xs={12} md={12}  className="withdrawal-item-l">
            <div style={{ width: "100%", margin: "10px", padding: "2px", fontSize: "14px", backgroundColor: "#ffc107" }}>
              <div style={{ marginLeft: "48px", right: "16px", margin: "10px", padding: "8px", fontSize: "14px", backgroundColor: "#000000" }}>
                お相手の検索結果に表示されない
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ paddingRight: "15px"}}>
          <Col xs={12} md={12}  className="withdrawal-item-l">
            <div style={{ width: "100%", margin: "10px", padding: "2px", fontSize: "14px", backgroundColor: "#ffc107" }}>
              <div style={{ marginLeft: "48px", right: "16px", margin: "10px", padding: "8px", fontSize: "14px", backgroundColor: "#000000" }}>
                自分の足あとがつかない
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ paddingRight: "15px"}}>
          <Col xs={12} md={12}  className="withdrawal-item-l">
            <div style={{ width: "100%", margin: "10px", padding: "2px", fontSize: "14px", backgroundColor: "#ffc107" }}>
              <div style={{ marginLeft: "48px", right: "16px", margin: "10px", padding: "8px", fontSize: "14px", backgroundColor: "#000000" }}>
                いいねを送った相手にだけプロフィールを表示
              </div>
            </div>
          </Col>
        </Row>

        <div style={{ height: 10 }}></div>
        <Row>
          <Col xs={12} md={12}  className="withdrawal-item-l">
            一度退会されますと、10日間再登録ができませんので退会せずプライベートモードをご利用いただく事をおすすめいたします。
          </Col>
        </Row>

        <div style={{ height: 4 }}></div>

        <Row style={{ paddingRight: "15px"}}>
          <Col xs={12} md={12}  className="withdrawal-item-l">
            <Button style={{ width: "100%", margin: "10px",
                              padding: "8px",
                              fontSize: "14px" }} onClick={(e: any) => this.gotoPrivateMode(e)}>
              プライベートモードを設定する
            </Button>
          </Col>
        </Row>

        <Row style={{ paddingBottom: "5px"}}>
          <Col xs={12} md={12}  className="withdrawal-item-l">
            注意事項
          </Col>
        </Row>
        <Row style={{ paddingBottom: "15px"}}>
          <Col xs={12} md={12}  className="withdrawal-item-l">
            注意事項を全てご確認の上チェックをしてください。
          </Col>
        </Row>

        <Row style={{ paddingRight: "15px"}}>
          <Col xs={12} md={12} >
              {this.warnings.map((s, index) => (
                <div class="mycheckbox">
                  <input id={`warning_${index}`} value="1" type="checkbox" onChange={(e: any) => this.toggleWarning(e)} />
                  <label for={`warning_${index}`}>{s.title}</label><br />
                </div>
              ))}
          </Col>
        </Row>

        <Row style={{ paddingTop: "10px", paddingBottom: "5px"}}>
          <Col xs={12} md={12}  className="withdrawal-item-l">
            ポイント残高
          </Col>
        </Row>
        <Row style={{ paddingBottom: "15px"}}>
          <Col xs={6} md={6}  className="withdrawal-item-l">
            あなたの保有ポイント
          </Col>
          <Col xs={6} md={6}  className="withdrawal-item-l">
            {this.state.myProfileData?.point}ポイント
          </Col>
        </Row>

        <Row style={{ paddingTop: "10px", paddingBottom: "5px"}}>
          <Col xs={12} md={12}  className="withdrawal-item-l">
            退会理由を選択してください
          </Col>
        </Row>
        <Row style={{ paddingBottom: "15px"}}>
          <Col xs={12} md={12}  className="withdrawal-item-l">
            以下の選択肢より退会理由を選択（複数選択可）
          </Col>
        </Row>

        <Row style={{ paddingRight: "15px"}}>
          <Col xs={12} md={12} >
              {this.reasons.map((s, index) => (
                <div class="mycheckbox">
                  <input id={`reason_${index}`} value={s.code} type="checkbox" onChange={(e: any) => this.toggleReason(e)} />
                  <label for={`reason_${index}`}>{s.title}</label><br />
                </div>
              ))}
          </Col>
        </Row>

        <Row style={{ padding: "15px"}}>
          <Col xs={12} md={12} className="withdrawal-item-l">
              <Form.Label>その他、具体的な退会理由を教えてください</Form.Label>
              <Form.Control as="textarea" rows={5} name="text"
                style={{ padding: "15px"}}
                onChange={(e) => this.changeText(e)}
                value={this.state.text}
                style={{ backgroundColor: "#090d13", color: "#fff" }}
                />

          </Col>
        </Row>

        <Row style={{ paddingRight: "15px"}}>
          <Col xs={12} md={12}  className="withdrawal-item-l">
            <Button style={{ width: "100%", margin: "10px",
                              padding: "8px",
                              fontSize: "14px" }} onClick={(e: any) => this.post(e)}
                              disabled={　this.state.warning_checks.length !== this.warnings.length || this.state.select_reasons.length === 0 }
                            >
              退会する
            </Button>
          </Col>
        </Row>

        <div style={{ height: 40 }}></div>

      </React.Fragment>
    );
  }

  private toggleWarning(e: any) {
    try{
      const id = e.target.id;
      if( this.warning_checks.includes(id)) {
        this.warning_checks.shift(id);
      } else {
        this.warning_checks.unshift(id);
      }
    } catch(e){ }
    this.setState({ warning_checks: this.warning_checks });
  }

  private toggleReason(e: any) {
    try{
      const id = e.target.value;
      if( this.select_reasons.includes(id)) {
        this.select_reasons.shift(id);
      } else {
        this.select_reasons.unshift(id);
      }
    } catch(e){ }
    this.setState({ select_reasons: this.select_reasons });
  }

  private changeText(e: any) {
    this.setState({ text: e.target.value });
  }

  private post(e: any) {
    console.log(this.state);
    confirmAlert({
      title: '',
      message: '退会してよろしいですか？',
      buttons: [
        {
          label: 'キャンセル',
          //onClick: () => alert('Click Yes')
        },
        {
          label: 'OK',
          onClick: () => this.withdrawal()
        }
      ]
    });
  }

  // 退会
  private async withdrawal() {
    var params = {
      "withdrawal": {
        "reasons": this.state.select_reasons,
        "detail": this.state.text
      }
    };

    const props = this.props
    await new WithdrawalApiClient(this.props.globalStore).post(params);
    Swal.fire({
      title: '',
      text: "退会しました",
      type: 'success',
    }).then((result) => {
      Utils.clearItems();
      this.notifier.info('退会しました');
      props.history.push('/signin');
    });
  }
}

export default withRouter(AppDataStore.withStores(WithdrawalPage));
