import { BaseApiClient } from "../BaseApiClient"

export class PhoneApiClient extends BaseApiClient<IOption> {

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    //console.log('api/v1/members/phone');
    return 'api/v1/members/phone';
  }
}
