import { IResource } from "../../resources/IResource";
import { BaseApiClient } from "./BaseApiClient"

export class BlocksApiClient extends BaseApiClient<IResource> {
  private _cache: IOption | null = null;

  async get(): Promise<IOption | null> {
    //console.log(`Retrieving a resource.`);
    if (!this._cache) {
      this._cache = await super.get();
    }
    return this._cache;
  }

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    return 'api/v1/blocks';
  }
}
