import { IResource } from "../../../resources/IResource";
import { BaseApiClient } from "../BaseApiClient"

export class ProfileBasicApiClient extends BaseApiClient<IResource> {

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    //return 'api/v1/members/profile';
    return 'api/v1/members/profiles/basic';
  }
}

/*
POST
{
  "member": {
    "height": 170,
    "body_type_id": 1,
    "prefecture_id": 2,
    "hometown_id": 3,
    "desired_dating_plan_id": 3,
    "annual_income_id": 4,
    "drinking_type_id": 5,
    "smoking_type_id": 6,
    "occupation_id": 7
  }
}
*/
