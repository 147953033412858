import * as common from '../common/BaseListComponent';
import AppDataStore from '../../stores/AppDataStore';
import { BaseApiClient } from '../../actions/clients/BaseApiClient';
import { RankingApiClient } from '../../actions/clients/RankingApiClient';
import { IMember } from '../../resources/IMember';
import React from 'react';
import { Row, Col, Image, Card } from 'react-bootstrap';
import LoadingIndicator from '../common/LoadingIndicator';
import Paginator from '../common/Paginator';
import Utils from '../../utils/Utils';

interface IProps extends common.IProps<IBbsMessage> {
}

interface IState extends common.IState<IBbsMessage> {
}

class RankingList extends common.BaseListComponent<IMember, IProps, IState> {

  private type: Int = 0;
  private ranking_type: string = "monthly";
  private storageKey: String = "";  // JSON保存のキー

  constructor(props: IProps) {
    super(props);
    this.state = {　};
    this.type = +props["type"];
    this.ranking_type = this.type === 0 ? "weekly" : "monthly";
    this.storageKey = `ranking_datalist_${this.type}`
  }

  async componentDidMount() {
    // 戻ってきた場合は保存されたJSONで一覧を表示
    const json = Utils.getJson(this.storageKey);
    if ( Object.keys(json).length > 0 ) {
      this.setResponse(json);
    } else {
      await super.componentDidMount();
    }
  }

  setResponse(json: any) {
    if (json === null) return;
    this.setState({ "response": json.response });
    Utils.removeJson(this.storageKey)
  }

  render() {
    return (
      <React.Fragment>
      <LoadingIndicator watch={this.state.response}>

        <div style={{ height: 10 }}></div>

        {this.state.response?.data.map((r, index) => (
          <Card className="list_card" key={index}>
            <div>
            <Image width={60} height={60} style={{ height: "auto" }}
                 src={Utils.toImageUrl(r.profile_image)} roundedCircle id={r.id}
                 onClick={() => this.onMemberSelected(r)} />
              <span style={{ fontSize: "14px" }} onClick={() => this.onMemberSelected(r)} >
                &nbsp;&nbsp;{r.name}&nbsp;&nbsp;{r.age}歳&nbsp;&nbsp;{r.prefecture}
              </span>
              <span style={{ fontSize: "14px", display: "flex", justifyContent: "right", alignItems: "flex-end"　}}>

              </span>
            </div>
            <hr />
          </Card>
        ))}

        {this.state.response?.data.length === 0 && (
          <Row>
            <Col xs={2} md={2}></Col>
            <Col className="nodata" xs={8} md={8}>データが見つかりません。</Col>
            <Col xs={2} md={2}></Col>
          </Row>
        )}

        <Paginator currentPage={this.state.response?.meta?.current_page} totalPage={this.state.response?.meta?.total_pages} onPageSelected={(page) => this.selectPage(page)}/>
      </LoadingIndicator>
      </React.Fragment>
    );
  }

  private onMemberSelected(member?: IMember) {
    // タブキーの保存
    Utils.setItem("eventKeyRanking", this.props.eventKey);

    // 戻った場合に再建策しないように
    Utils.setJson(this.storageKey, this.state);

    this.props.onResourceSelected(member);
  }


  protected createClient(): BaseApiClient<IMember> {
    return new RankingApiClient(this.props.globalStore, this.ranking_type);
  }

  protected buildSearchCondition(page?: number) {
    return Object.assign({}, {}, { page: page });
  }

}

export default AppDataStore.withStores(RankingList);
