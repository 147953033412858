import * as common from './BasePage';
import AppDataStore from '../../stores/AppDataStore';
import { ConfigApiClient } from '../../actions/clients/ConfigApiClient';
import { ReactNode } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { IAppConfigData } from '../../stores/IAppConfigData';

interface IProps extends common.IProps<common.IParams> {
  children: ReactNode
}
interface IState extends common.IState {
  configData?: IAppConfigData;
}

class MaintenanceBoundary extends common.BasePage<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      configData: props.globalStore.get('configData')
    }
  }

  async componentWillMount() {
    await this.loadConfigData();
  }

  async componentDidMount() {
  }

  private async loadConfigData() {
    if (this.state.configData?.isLoaded) {
      //console.log('Skip loading config data because of already loaded.');
      return;
    }

    const config = await new ConfigApiClient(this.props.globalStore).get();
    if (config) {
      const data: IAppConfigData = { isLoaded: true, config: config };
      this.props.globalStore.set('configData')(data);
      this.setState({ configData: data });
    }
  }

  private getMaintenanceKey() {
    if( window.location.host.indexOf('staging') !== -1 || window.location.host.indexOf('localhost') !== -1) {
      return 'web_is_maintenance_on_develop';
    }
    return 'web_is_maintenance_on_production';
  }

  render() {
    const config = this.state.configData?.config;
    if (config && Boolean(config[this.getMaintenanceKey()]) && this.props.location.pathname !== '/maintenance') {
      return <Redirect push to={'/maintenance'}/>;
    }
    else {
      return this.props.children;
    }
  }
}

export default withRouter(AppDataStore.withStores(MaintenanceBoundary));
