import { IJob } from "../../resources/IJob";
import { ILoginJobParams } from "../../resources/ILoginJobParams";
import { BaseApiClient } from "./BaseApiClient"

export class JobApiClient extends BaseApiClient<IJob> {
  async enqueueLoginJob(params: ILoginJobParams): Promise<IJob | null> {
    return await this.save({
      job_type: 'login',
      job_params: params
    });
  }

  async enqueueJob(type: string, params: any): Promise<IJob | null> {
    return await this.save({
      job_type: type,
      job_params: params
    });
  }

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    return 'trickster/v1/jobs';
  }
}
