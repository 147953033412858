import React from "react";
import { ILastViewPopup, IPopup } from "../../resources/IPopup";
import { IProps, IState } from "../common/BaseComponent";
import Utils from "../../utils/Utils";
import { IMyProfile } from "../../resources/IMyProfile";
import PopupDialog from "./PopupDialog";
import AppDataStore from "../../stores/AppDataStore";

interface Props extends IProps {
}

interface State extends IState {
  myProfile?: IMyProfile | null;
  popups: IPopup[];
  lastView?: ILastViewPopup;
  isDialogOpen: boolean;
}

class Popup extends React.Component<Props, State> {
  private lastViewPopup: string = 'LastViewPopup';
  private intervalPopup: any = null;
  private timeoutPopup: any = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      myProfile: this.myProfile(),
      popups: [],
      isDialogOpen: false,
      lastView: Utils.getJson(this.lastViewPopup),
    }
  }

  componentDidMount() {
    this.setupPopups();
    this.setupOpenDialog();
  }

  componentWillUnmount(): void {
    this.clearIntervalPopup();
    this.cleatTimeoutPopup();
  }

  private setupPopups() {
    let popups = this.state.myProfile?.popups ?? [];
    this.setState({ popups: popups });
  }

  private myProfile() {
    const profile = this.props.globalStore.get('myProfileData').data;
    return profile ? profile : Utils.loadMyProfile();
  }

  private setupOpenDialog() {
    let popups = this.state.myProfile?.popups ?? [];
    this.clearIntervalPopup();
    this.cleatTimeoutPopup();

    if (!popups[0] || !popups[0]?.id || !popups[0].duration) return this.closeDialog();

    const open = (popup: IPopup) => {
      this.openDialog();
      this.intervalPopup = setInterval(() => {
        this.setState({ isDialogOpen: true });
      }, Utils.convertMinuteToMilliseconds(popup.duration));
    }

    if (!this.state.lastView || this.state.lastView.id !== popups[0].id) {
      open(popups[0]);
    }

    if (this.state.lastView && this.state.lastView.id === popups[0].id) {
      let duration = Utils.convertMinuteToMilliseconds(popups[0].duration);
      let nextSee = this.state.lastView.lastSeen + duration;

      if (nextSee <= Date.now()) {
        open(popups[0]);
      } else {
        this.timeoutPopup = setTimeout(() => {
          open(popups[0])
        }, nextSee - Date.now());
      }
    }
  }

  // ダイアログを開く
  private openDialog = () => {
    this.setState({ isDialogOpen: true });
  }

  // ダイアログからのコールバックでダイアログを閉じてあげる
  private closeDialog = () => {
    this.setState({ isDialogOpen: false });
  }

  private clearIntervalPopup() {
    if (this.intervalPopup) {
      clearInterval(this.intervalPopup);
    }
  }

  private cleatTimeoutPopup() {
    if (this.timeoutPopup) {
      clearTimeout(this.timeoutPopup);
    }
  }

  render() {
    if (this.state.popups[0]) {
      return <PopupDialog isOpen={this.state.isDialogOpen} onClose={this.closeDialog} popup={this.state.popups[0]} />;
    } else {
      return <></>;
    }
  }
}

export default AppDataStore.withStores(Popup);
