import React from 'react';
import * as common from '../common/BaseComponent';
import { Nav, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';

interface IProps extends common.IProps {
  selectIndex?: number;
}

interface IState extends common.IState {
}

class HelpPageFooter extends common.BaseComponent<IProps, IState> {

  render() {
    return (
      <React.Fragment>
        <nav className="navbar fixed-bottom navbar-light bg-dark" role="navigation" style={{ maxHeight: "55px" }}>
          <Nav className="w-100">
            <div className=" d-flex flex-row justify-content-around w-100">
              <Button onClick={(e: any) => this.send(e)}>お問い合わせ</Button>
            </div>
          </Nav>
        </nav>
      </React.Fragment>
    );
  }

  private async send(e: any) {
    e.preventDefault();
    this.props.history.push('/inquiry');
  }
}

export default withRouter(AppDataStore.withStores(HelpPageFooter));
