import React from 'react';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import HelpPageHeader from './HelpPageHeader';
import HelpPageFooter from './HelpPageFooter';

interface IState extends common.IState {
}

class HelpPage extends common.BasePage<common.IProps<common.IParams>, IState> {


  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = { };
  }

  async componentDidMount() {;

  }


  render() {
    return (
      <React.Fragment>
        <HelpPageHeader />
        <div style={{ height: 0 }}></div>

        <iframe className="bg-dark" title="ヘルプ" src="https://lp.mitsumitsu-app.com/help.html"
          style={{ width: "100%", height: "100vh",
                  scrollbarColor: "#6969dd #e0e0e0",
                  scrollbarWidth: "thin"
                }}  >

        </iframe>

        <HelpPageFooter selectIndex={4} />
      </React.Fragment>
    );
  }

  // ダイアログを開く
  private openDialog = () => {
    this.setState({isDialogOpen: true});
  }

  // ダイアログからのコールバックでダイアログを閉じてあげる
  private closeDialog = () => {
    this.setState({isDialogOpen: false});
  }

}

export default withRouter(AppDataStore.withStores(HelpPage));
