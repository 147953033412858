import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import SubscriptionPageHeader from './SubscriptionPageHeader';
import Utils from '../../utils/Utils';
import { AutoSubscriptionRenewalApiClient } from '../../actions/clients/profile/AutoSubscriptionRenewalApiClient';
import { MyProfileApiClient } from '../../actions/clients/MyProfileApiClient';
//import ReactHtmlParser from 'react-html-parser';
//import FireBaseLogEventManager from '../../utils/FireBaseLogEventManager';
//import FireBaseLogEvent from '../../utils/enums/FireBaseLogEvent';
import Swal from 'sweetalert2'

interface IState extends common.IState {
  isDialogOpen: boolean;
  myProfileData: IMyProfile
}

class SubscriptionPage extends common.BasePage<common.IProps<common.IParams>, IState> {

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = { myProfileData: Utils.loadMyProfile() };
  }

  async componentDidMount() {
    const myProfile = await Utils.getMyProfile(this.props.globalStore);
    this.setState({ myProfileData: myProfile });
  }


  render() {
    return (
      <React.Fragment>
        <SubscriptionPageHeader/>

        <div style={{ height: 0 }}></div>

        <Row>
          <Col xs={8} md={8} className="setting-item-l" style={{ "height": 40 }} >
            クレジットカード決済の自動更新
          </Col>

          {this.state.myProfileData.auto_subscription_renewal === true && (
            <Col xs={4} md={4} className="setting-item-r">
              <label class="todaySwitchWrapper" >
                <input type="checkbox" class="switchInput" checked={this.state.myProfileData?.auto_subscription_renewal} onChange={(e: any) => this.toggle()}/>
                <div class="todaySwitchDisplay" style={{"verticalAlign": "middle"}}>
                  <div class="switchStatus centerPlace">{this.state.myProfileData?.auto_subscription_renewal? "ON" : "OFF"}</div>
                  <div class="switchBall"></div>
                </div>
              </label>
            </Col>
          )}

          {this.state.myProfileData.auto_subscription_renewal !== true && (
            <Col xs={4} md={4} className="setting-item-r"  style={{ "height": 40 }}>
                OFF
            </Col>
          )}

        </Row>

        <div style={{ height: 20 }}></div>

      </React.Fragment>
    );
  }

  async toggle() {
    var params = {
      "member": {
         "auto_subscription_renewal": false
      }
    };
    await new AutoSubscriptionRenewalApiClient(this.props.globalStore).update(params);
    const myProfile = await new MyProfileApiClient(this.props.globalStore).get();
    //console.log(myProfile);
    this.setState({ myProfileData: myProfile });

    Swal.fire({
      title: '',
      text: 'クレジットカード決済の自動更新を無効にしました',
      type: 'success',
    }).then((result) => {
    })

  }

}

export default withRouter(AppDataStore.withStores(SubscriptionPage));
