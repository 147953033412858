import AppDataStore from '../../stores/AppDataStore';
import * as base from './BaseIntervalWizardPage';

interface IProps extends base.IProps {
}

interface IState extends base.IState {
}

class TargetIntervalWizardPage extends base.BaseIntervalWizardPage<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  protected get title(): string {
    return 'アクション対象会員間の処理インターバルを指定';
  }

  protected get note(): string {
    return 'あるサクラ会員がある異性会員にアクションを開始してから、次の異性会員にアクションを開始するまでのインターバルを設定できます。実際の各インターバルは最小、最大待ち時間の間でランダムに決まります。';
  }
}

export default AppDataStore.withStores(TargetIntervalWizardPage);
