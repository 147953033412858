import * as common from '../common/BaseListComponent';
import AppDataStore from '../../stores/AppDataStore';
import { IBbsMessage } from '../../resources/IBbsMessage';
import { BaseApiClient } from '../../actions/clients/BaseApiClient';
import { BbsMessageApiClient } from '../../actions/clients/BbsMessageApiClient';
import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import LoadingIndicator from '../common/LoadingIndicator';
import Paginator from '../common/Paginator';
import Utils from '../../utils/Utils';
import nl2br from 'react-nl2br';

interface IProps extends common.IProps<IBbsMessage> {
}

interface IState extends common.IState<IBbsMessage> {
}

class BbsList extends common.BaseListComponent<IMember, IProps, IState> {

  private storageKey: String = "BbsList";  // JSON保存のキー

  constructor(props: IProps) {
    super(props);
    this.state = {　};
  }

  /*async componentDidMount() {
    await super.componentDidMount();
    if (this.props.onSearchConditionChanged) {
      this.props.onSearchConditionChanged(this.state.condition);
    }
  }*/

  async componentDidMount() {
    // 戻ってきた場合は保存されたJSONで一覧を表示
    const json = Utils.getJson(this.storageKey);
    if ( Object.keys(json).length > 0 ) {
      this.setResponse(json);
    } else {
      await super.componentDidMount();
    }
  }
  setResponse(json: any) {
    if (json === null) return;
    this.setState({ "response": json.response });
    Utils.removeJson(this.storageKey)
  }

  render() {
    //console.log(this.state.response);
    return (
      <React.Fragment>
      <LoadingIndicator watch={this.state.response}>

        {this.state.response?.data.map((bbs, index) => (
          <div class="col-md-12" key={index} style={{ fontSize: "14px"}}>
            <div class="card p-3 mb-2 list_card_bbs">
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-row align-items-center">
                        <div class="icon">
                        <Image width={60} height={60} style={{ height: "auto" }}
                             src={Utils.toImageUrl(bbs.member.profile_image)} roundedCircle id={bbs.id}
                             onClick={() => this.onBbsSelected(bbs)}
                             />
                        </div>
                        <div class="ms-2 c-details" onClick={() => this.onBbsSelected(bbs)}>
                            &nbsp;&nbsp;{bbs.member.name}&nbsp;&nbsp;{bbs.member.age}歳&nbsp;&nbsp;{bbs.member.height}cm
                        </div>
                    </div>
                </div>
                <div style={{ height: 4 }}></div>
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-row align-items-center">
                        <div class="ms-2 c-details">
                            &nbsp;&nbsp;{bbs.desired_date}&nbsp;&nbsp;{bbs.desired_time_slot}
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-row align-items-center">
                        <div class="ms-2 c-details">
                            &nbsp;&nbsp;{bbs.desired_meeting_place_prefecture} / {bbs.desired_meeting_place_detail} / {bbs.desired_dating_plan}
                        </div>
                    </div>
                </div>
                <div style={{ height: 10 }}></div>
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-row align-items-center">
                        <div class="ms-2 c-details">
                            {nl2br(bbs.content)}
                        </div>
                    </div>
                </div>
                <div style={{ height: 10 }}></div>
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-row align-items-center">
                        <div class="ms-2 c-details">
                        {bbs.image &&
                          <Image style={{ width: "70%", height: "auto" }}
                               onClick={() => this.onBbsSelected(bbs)}
                               src={Utils.toImageUrl(bbs.image)} />
                           }
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: 20 }}></div>
          </div>

        ))}

        {this.state.response?.data.length === 0 && (
          <Row>
            <Col xs={2} md={2}></Col>
            <Col className="nodata" xs={8} md={8}>データが見つかりません。</Col>
            <Col xs={2} md={2}></Col>
          </Row>
        )}

        <Paginator currentPage={this.state.response?.meta.current_page} totalPage={this.state.response?.meta.total_pages} onPageSelected={(page) => this.selectPage(page)}/>
        <div style={{ height: 40 }}></div>
      </LoadingIndicator>
      </React.Fragment>
    );
  }

  protected onBbsSelected(bbs: IBbsMessage) {

    // 戻った場合に再建策しないように
    Utils.setJson(this.storageKey, this.state);

    this.props.onResourceSelected?.(bbs.member);
  }


  protected createClient(): BaseApiClient<IMember> {
    return new BbsMessageApiClient(this.props.globalStore);
  }

  protected buildSearchCondition(page?: number) {
    return Object.assign({}, {}, { page: page });
  }

}

export default AppDataStore.withStores(BbsList);
