import React from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
//import * as common from '../common/BasePage';
import * as common from '../common/BaseForm';
import InquiryPageHeader from './InquiryPageHeader';
import { InquiryCategoriesApiClient } from "../../actions/clients/InquiryCategoriesApiClient";
import { InquiryApiClient } from "../../actions/clients/InquiryApiClient";
import GeneralDataSelector from '../common/form/GeneralDataSelector';
import Utils from '../../utils/Utils';
import Swal from 'sweetalert2'

interface IState extends common.IState {
  isDialogOpen: boolean;
  myProfileData: IMyProfile
}

//class InquiryPage extends common.BasePage<common.IProps<common.IParams>, IState> {
class InquiryPage extends common.BaseForm<IMember, IProps, IState> {

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = { resource: {} };
  }

  async componentDidMount() {
    if( !this.state.categories ) {
      const categories = await new InquiryCategoriesApiClient(this.props.globalStore).get();
      this.setState({ categories: categories});
    }
    // プレミアム購入
    if (Utils.getItem("prmium_inquiry")){
      for(const item in this.state.categories){
        const c = this.state.categories[item];
        if( c.name.indexOf('プレミアムプラン')!== -1 && c.name.indexOf('購入')!== -1 ) {
          this.setState({ resource: { inquiry_category_id: c.id , content: "※希望するプランをご記入ください(1、3、6ヶ月)" }});
        }
      }
      Utils.removeItem("prmium_inquiry");
    }
  }

  render() {
    return (
      <React.Fragment>
        <InquiryPageHeader onPreview={this.onPreview} />

        <div style={{ height: 0 }}></div>

        <Row className="member-profile-row-item">
           <Col xs={12} md={12}>
            お問い合わせカテゴリ
           </Col>
        </Row>
        <Row className="member-profile-row-item">
           <Col xs={12} md={12} className="member-profile-col-r">
            <GeneralDataSelector property="inquiry_category_id"
            data={this.state.categories}
            value={this.state.resource?.inquiry_category_id}
            errors={this.state.errors?.inquiry_category_id} onChanged={(e) => this.changeResource(e, { type: 'number' })}/>
           </Col>
        </Row>

        <Row className="member-profile-row-item">
           <Col xs={12} md={12}>
            お問い合わせ内容
           </Col>
        </Row>
        <Row className="member-profile-row-item">
        <Form.Control as="textarea" rows={10} name="content" className="bg-dark text-light"
          onChange={(e) => this.changeResource(e)}
          value={this.state.resource?.content}
          isInvalid={!!this.state.errors?.content}/>
        </Row>

        <Row className="member-profile-row-item">
           <Col xs={12} md={12}>
            メールアドレス
           </Col>
        </Row>
        <Row className="member-profile-row-item">
        <Form.Control type="text" name="email" onChange={(e) => this.changeResource(e)}
              className="bg-dark text-light"
              value={this.state.resource?.email}
              isInvalid={!!this.state.errors?.email}/>
        </Row>

        <div style={{ height: 5 }}></div>
        <Row>
          <Col xs={12} md={12}>
            <center>
              <Button type="submit"
                disabled={!this.state.resource?.email || !this.state.resource?.inquiry_category_id || !this.state.resource?.content}
                onClick={(e: any) => this.submitInquiry(e)}>送信</Button>
            </center>
          </Col>
        </Row>

        <div style={{ height: 10 }}></div>

      </React.Fragment>
    );
  }

  private async submitInquiry(e: any) {
    e.preventDefault();

    var params = {
        "email": this.state.resource?.email,
        "inquiry_category_id": this.state.resource?.inquiry_category_id,
        "content": this.state.resource?.content,
        "model_name": window.navigator.userAgent
      };

    await new InquiryApiClient(this.props.globalStore).post(params);
    Swal.fire({
      title: '',
      text: "お問い合わせを送信しました",
      type: 'success',
    }).then((result) => {
    })
  }
}

export default withRouter(AppDataStore.withStores(InquiryPage));
