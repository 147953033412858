import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SimpleImageSlider from "react-simple-image-slider";
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import MemberPurchasePageHeader from './MemberPurchasePageHeader';
import { ConfigApiClient } from '../../actions/clients/ConfigApiClient';
import Utils from '../../utils/Utils';
import { TcApiClient } from '../../actions/clients/tc/TcApiClient';
import Alert from "../common/Alert";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import premium_btn_plan_background from '../../materials/images/premium/premium_btn_plan_background.png'

interface IState extends common.IState<IRoom> {
}

class MemberPurchasePremium extends common.BasePage<common.IProps<common.IParams>, IState> {
  images = [
    { url: "images/member/0.png" },
    { url: "images/member/1.png" },
    { url: "images/member/2.png" },
  ];

  containerStyle = {
        backgroundImage:
          'url(' +  premium_btn_plan_background + ')' ,
        width: "300px",
        height: "72px",
        position: "relative",
      };


  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = { configData: {}, pointData: [{}] };
  }

  async componentDidMount() {
    const configData = await new ConfigApiClient(this.props.globalStore).get();
    this.setState({ configData: configData });
    const key = this.getPointPriceKey();
    this.setState({ pointData: JSON.parse(configData[key]) });
  }

  private getPointPriceKey() {
    if( window.location.host.indexOf('staging') !== -1 || window.location.host.indexOf('localhost') !== -1) {
      return 'ios_member_price_json_develop';
    }
    return 'ios_member_price_json_prodution';
  }

  private selectItem(description, price, code) {
    this.props.selectItem(description, price, code);
  }

  render() {
    this.state.pointData = this.state.pointData.filter((s, index) => s.code_ios?.indexOf("premium")>-1);
    return (
      <>
      <div style={{ height: 20 }}></div>


            <Row>
              <Col xs={12} md={12}>
                <center>
                <div style={this.containerStyle} onClick={(e: any) => this.selectItem("プレミアムプラン 1ヶ月", 9800, '1m')}>
                  <div style={{ position: "absolute", height: "20px", fontSize: "18px", width: "100%", textAlign: "center", padding: "6px"}}><strong>プレミアムプラン 1ヶ月</strong></div>
                  <div style={{ position: "absolute", width: "100%",  fontSize: "14px", bottom: "0", textAlign: "center", padding: "10px" }}><strong>¥9,800</strong></div>
                </div>;
                </center>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={12}>
                <center>
                <div style={this.containerStyle}  onClick={(e: any) => this.selectItem("プレミアムプラン 3ヶ月", 24800, '3m')}>
                  <div style={{ position: "absolute", height: "20px", fontSize: "18px", width: "100%", textAlign: "center", padding: "6px"}}><strong>プレミアムプラン 3ヶ月</strong></div>
                  <div style={{ position: "absolute", width: "100%",  fontSize: "14px", bottom: "0", textAlign: "center", padding: "10px" }}><strong>¥8,270（一括払い：24,800円）</strong></div>
                </div>;
                </center>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={12}>
                <center>
                <div style={this.containerStyle} onClick={(e: any) => this.selectItem("プレミアムプラン 6ヶ月", 43400, '6m')}>
                  <div style={{ position: "absolute", height: "20px", fontSize: "18px", width: "100%", textAlign: "center", padding: "6px"}}><strong>プレミアムプラン 6ヶ月</strong></div>
                  <div style={{ position: "absolute", width: "100%",  fontSize: "14px", bottom: "0", textAlign: "center", padding: "10px" }}><strong>¥7,240（一括払い：43,400円）</strong></div>
                </div>;
                </center>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={12}>
                <center>
                <div style={this.containerStyle} onClick={(e: any) => this.selectItem("プレミアムプラン 12ヶ月", 74800, '12m')}>
                  <div style={{ position: "absolute", height: "20px", fontSize: "18px", width: "100%", textAlign: "center", padding: "6px"}}><strong>プレミアムプラン 12ヶ月</strong></div>
                  <div style={{ position: "absolute", width: "100%",  fontSize: "14px", bottom: "0", textAlign: "center", padding: "10px" }}><strong>¥6,300（一括払い：74,800円）</strong></div>
                </div>;
                </center>
              </Col>
            </Row>

      </>
    );
  }
}

export default AppDataStore.withStores(MemberPurchasePremium);
