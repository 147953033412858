import * as common from '../common/BaseListComponent';
import AppDataStore from '../../stores/AppDataStore';
import { BaseApiClient } from '../../actions/clients/BaseApiClient';
import { Image, Row, Col, Button } from 'react-bootstrap';
import LoadingIndicator from '../common/LoadingIndicator';
import Paginator from '../common/Paginator';
import { IFootprint } from '../../resources/IFootprint';
import { FootprintsApiClient } from '../../actions/clients/FootprintsApiClient';
import { DateTimeConverter } from '../../converters/DateTimeConverter';
import Utils from '../../utils/Utils';

interface IProps extends common.IProps<IFootprint> {
}

interface IState extends common.IState<IFootprint> {
}

class FootprintList extends common.BaseListComponent<IOffer, IProps, IState> {

  private storageKey: String = "FootprintList";  // JSON保存のキー

  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    // 戻ってきた場合は保存されたJSONで一覧を表示
    const json = Utils.getJson(this.storageKey);
    if ( Object.keys(json).length > 0 ) {
      this.setResponse(json);
    } else {
      await super.componentDidMount();
    }
  }

  setResponse(json: any) {
    if (json === null) return;
    this.setState({ "response": json.response });
    Utils.removeJson(this.storageKey)
  }

  render() {
    //console.log(this.state.response);
    return (
      <>
        <LoadingIndicator watch={this.state.response}>

          {this.state.response?.data.map((f,index) => (

            <article class="article_user-like" key={index}>
              <div class="wrapper">
                <ul class="article_lists">
                  <li class="article_list">
                    <div class="article_list_thumb article_list_thumb">
                      <div class=" middle">
                        <a onClick={(e:any) => this.onMemberSelected(f)}>
                        <Image style={{ height: "auto" }}
                             src={Utils.toImageUrl(f.profile_image)} id={f.id}
                             onClick={(e:any) => this.onMemberSelected(f)} />
                        </a>
                    </div>
                    </div>
                    <div class="article_list_content article_list_content">
                      <i class="content_time ">{DateTimeConverter.convert(f.footprinted_at)}</i>
                      <div class="article_list_content_info">
                        <p class="content_name">
                          &nbsp;&nbsp;{f.name}&nbsp;&nbsp;{f.age}歳&nbsp;&nbsp;{f.prefecture}
                        </p>
                      </div>
                      <div class="article_list_content_btns">
                        <div class="flex" style={{ justifyContent: "flex-end" }}>
                            <p class="like_thanks">
                              <Button style={{ width: "100%", fontSize: "10px" }}
                               onClick={(e:any) => this.onMemberSelected(f)}>
                                プロフィール確認
                              </Button>
                            </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </article>

          ))}

          {this.state.response?.data.length === 0 && (
            <Row>
              <Col xs={2} md={2}></Col>
              <Col className="nodata" xs={8} md={8}>データが見つかりません。</Col>
              <Col xs={2} md={2}></Col>
            </Row>
          )}

          <Paginator currentPage={this.state.response?.meta.current_page} totalPage={this.state.response?.meta.total_pages} onPageSelected={(page) => this.selectPage(page)}/>
          <div style={{ height: 40 }}></div>
        </LoadingIndicator>
      </>
    );
  }

  protected createClient(): BaseApiClient<IOffer> {
    return new FootprintsApiClient(this.props.globalStore);
  }

  protected buildSearchCondition(page?: number) {
    return Object.assign({}, {}, { page: page });
  }

  private onMemberSelected(member?: IMember) {
    // 戻った場合に再建策しないように
    Utils.setJson(this.storageKey, this.state);

    this.props.onResourceSelected(member);
  }
}

export default AppDataStore.withStores(FootprintList);
