import React from 'react';
import { Form } from 'react-bootstrap';
import AppDataStore from '../../../stores/AppDataStore';
import * as common from '../BaseComponent';
import ValidationErrorFeedback from '../ValidationErrorFeedback';

interface IProps extends common.IProps {
  property: string;
  label: string;
  data: any;
  errors?: any;
  onChanged: (e: any) => void;
}

interface IState extends common.IState {
  errors?: any;
}

class GeneralCheckBox extends common.BaseComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      errors: props.errors
    };
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({ value: this.props.value, errors: this.props.errors });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Form.Check name={this.props.property} label={this.props.label} onClick={(e) => this.props.onClick(e)} className="bg-dark text-light" />
        <ValidationErrorFeedback errors={this.state.errors}/>
      </React.Fragment>
    )
  }

}

export default AppDataStore.withStores(GeneralCheckBox);
