import React from 'react';
import { Button, Col, Form, Jumbotron, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { UserSession } from '../../actions/session/UserSession';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BaseForm';
import Utils from '../../utils/Utils';
import SystemNotification from '../common/SystemNotification';
import { RequestToWithdrawaApiClient } from "../../actions/clients/RequestToWithdrawaApiClient";
import RequestToWithdrawaPageHeader from './RequestToWithdrawaPageHeader';
import querystring from 'querystring';
import Swal from 'sweetalert2'

interface IParams extends common.IParams {
}

interface IProps extends common.IProps<IParams> {
}

interface IState extends common.IState {
}

class RequestToWithdrawaPage extends common.BaseForm<IParams, IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = { resource: {} };
  }

  async componentDidMount() {
  }

  render() {
    return (
      <React.Fragment>
      <RequestToWithdrawaPageHeader onPreview={this.onPreview} />
      <Row>
        <Col xs={12} md={12}  className="withdrawal-item-l">
          MITSUMITSUをご利用いただき誠にありがとうございます。退会につきましては、退会希望内容（必須：ユーザー情報、退会希望時期など）、連絡先の電話番号かメールアドレスのいずれかを入力して送信してください。
        </Col>
      </Row>

      <Row className="member-profile-row-item">
         <Col xs={12} md={12}>
          退会希望内容（必須）
         </Col>
      </Row>
      <Row className="member-profile-row-item">
      <Form.Control as="textarea" rows={5} name="content" className="bg-dark text-light"
        onChange={(e) => this.changeResource(e)}
        value={this.state.resource?.content}
        isInvalid={!!this.state.errors?.content}/>
      </Row>

      <Row className="member-profile-row-item">
         <Col xs={12} md={12}>
          電話番号（いずれか必須）
         </Col>
      </Row>
      <Row className="member-profile-row-item">
      <Form.Control type="text" name="tel" onChange={(e) => this.changeResource(e)}
            className="bg-dark text-light"
            value={this.state.resource?.tel}
            isInvalid={!!this.state.errors?.tel}/>
      </Row>

      <Row className="member-profile-row-item">
         <Col xs={12} md={12}>
          メールアドレス（いずれか必須）
         </Col>
      </Row>
      <Row className="member-profile-row-item">
      <Form.Control type="text" name="email" onChange={(e) => this.changeResource(e)}
            className="bg-dark text-light"
            value={this.state.resource?.email}
            isInvalid={!!this.state.errors?.email}/>
      </Row>

      <div style={{ height: 5 }}></div>
      <Row>
        <Col xs={12} md={12}>
          <center>
            <Button type="submit"
              disabled={!this.state.resource?.content || !(this.state.resource?.email || this.state.resource?.tel)}
              onClick={(e: any) => this.submitInquiry(e)}>送信</Button>
          </center>
        </Col>
      </Row>

      <div style={{ height: 10 }}></div>

      </React.Fragment>
    )
  }

  private async submitInquiry(e: any) {
    e.preventDefault();

    var params = {
      "request_to_withdraw": {
        "email": this.state.resource?.email,
        "phone_number": this.state.resource?.tel,
        "content": this.state.resource?.content,
        "model_name": window.navigator.userAgent
       }
      };

    await new RequestToWithdrawaApiClient(this.props.globalStore).post(params);
    Swal.fire({
      title: '退会希望を送信しました',
      text: "送信内容に基づいて退会処理を進めます。運営からの連絡をお待ちください。",
      type: 'success',
    }).then((result) => {
      //console.log(result);
    })

  }

}

export default withRouter(AppDataStore.withStores(RequestToWithdrawaPage));
