import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import RankingListPageHeader from './RankingListPageHeader';
import RankingMemberPopUp from './RankingMemberPopUp';
import RankingList from './RankingList';
import Utils from '../../utils/Utils';
import FireBaseLogEventManager from '../../utils/FireBaseLogEventManager';
import FireBaseLogEvent from '../../utils/enums/FireBaseLogEvent';
import FireBaseLogEventProfileScreenType from '../../utils/enums/FireBaseLogEventProfileScreenType';
import { IMyProfile } from '../../resources/IMyProfile';

interface IState extends common.IState {
  eventKey: any;
  myProfileData: IMyProfile | null;
}

class RankingListPage extends common.BasePage<common.IProps<common.IParams>, IState> {

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = {
      eventKey: Utils.getItem("eventKeyRanking") ? Utils.getItem("eventKeyRanking") : "first",
      myProfileData: Utils.loadMyProfile(),
    };
  }

  async componentDidMount() {;
    const myProfile = await Utils.getMyProfile(this.props.globalStore);
    this.setState({ myProfileData: myProfile });
  }

  render() {
    return (
      <React.Fragment>
        <RankingListPageHeader onReload={(e) => this.onReload(e)} />
        <div style={{ height: 0 }}></div>

        <RankingMemberPopUp onGoToSearch={() => this.goToSearch()} onGoToMemberPurchase={() => this.goToMemberPurchase()} />

        <Tabs defaultActiveKey={this.state.eventKey} style={{ display: "flex", flexWrap: "nowrap", alignItems: "stretch" }}>
          <Tab eventKey="first" title="週間" tabClassName="nav-item-offer">
            <RankingList eventKey="first" page={this.state.page} onPageSelected={(page) => this.selectPage(page)}
              onResourceSelected={(member) => this.onMemberSelected(member)} type="0" />
          </Tab>
          <Tab eventKey="second" title="月間" tabClassName="nav-item-offer">
            <RankingList eventKey="second" page={this.state.page} onPageSelected={(page) => this.selectPage(page)}
              onResourceSelected={(member) => this.onMemberSelected(member)} type="1" />
          </Tab>
        </Tabs>

      </React.Fragment>
    );
  }

  public onMemberSelected(member?: any) {

    // log event
    FireBaseLogEventManager.sendLogEvent(FireBaseLogEvent.profileDetailClick, { "profile_screen_type" : FireBaseLogEventProfileScreenType.ranking });

    this.props.history.push({
      pathname: `/members/${member?.id}`,
      state: { member: member }
    });
  }

  // ページ更新
  private onReload(e: any) {
    window.location.reload();
  }

  // 検索画面への戻り
  private goToSearch() {
    this.props.history.push("/search");
  }

  private goToMemberPurchase() {
    if (this.state.myProfileData?.sex === '男性') {
      this.props.history.push("/memberpurchase");
    }
  }

}

export default withRouter(AppDataStore.withStores(RankingListPage));
