import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import PrivatemodePageHeader from './PrivatemodePageHeader';
import private_mode_image from '../../materials/images/privatemode/private_mode_image@2x.png'
import { PrivateModeApiClient } from '../../actions/clients/profile/PrivateModeApiClient';
import Swal from 'sweetalert2'

interface IState extends common.IState {
}

class PrivatemodePage extends common.BasePage<common.IProps<common.IParams>, IState> {

  containerStyle = {
        backgroundImage:
          'url(' + private_mode_image + ')' ,
        width: "300px",
        aspectRatio: "750/360",
        position: "relative",
      };

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = { private_mode: false, switchText: "ON", switchChecked: true };
  }

  async componentDidMount() {
    const setting = await new PrivateModeApiClient(this.props.globalStore).get();
    if(setting) {
      this.setState({private_mode: setting.member.private_mode})

      this.setState({ switchText: setting.member.private_mode ? "ON" : "OFF" });
    }
  }

  render() {
    return (
      <React.Fragment>
        <PrivatemodePageHeader />

        <Row>
          <Col xs={12} md={12}  className="privatemode-top">
          プライベートモードを設定することで、安心してお相手探しができるようになります
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <center>
            <div style={this.containerStyle} onClick={(e: any) => this.selectItem(s)}>
              <div style={{ position: "absolute", height: "100%", textAlign: "left", padding: "16px", paddingTop: "24px" }}><strong>プライベートモードの</strong></div>
              <div style={{ position: "absolute", height: "100%", width: "100%", textAlign: "left",
                              paddingLeft: "20px", paddingTop: "60px", fontSize: "28px", color: "#ffc107" }}><strong>2つの特徴</strong></div>
            </div>;
            </center>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <div className="privatemode-item">
              <div className="privatemode-title-header">
                <center><div class="sample-box-05">特徴１</div></center>
              </div>
              <div className="privatemode-title-middle">
                友人や知人に表示されたくない
              </div>
              <div className="privatemode-title-low">
                異性の検索結果画面に表示されない為、あなたのプロフィールを不特定多数のお相手から閲覧される事はありません
              </div>
            </div>
          </Col>
        </Row>

        <div style={{ height: 20 }}></div>

        <Row>
          <Col xs={12} md={12}>
            <div className="privatemode-item">
              <div className="privatemode-title-header">
                <center><div class="sample-box-05">特徴２</div></center>
              </div>
              <div className="privatemode-title-middle">
              特定のユーザーだけに プロフィールを表示させたい
              </div>
              <div className="privatemode-title-red">
                いいねを送ったお相手、メッセージのやりとりを行っているお相手にだけご自身のプロフィールが表示されます。
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <div className="item">
              <div className="privatemode-title-middle">
              プライベートモードを設定
              </div>
            </div>
              <center>
                <label class="switchWrapper">
                  <input type="checkbox" class="switchInput" checked={this.state.private_mode} onChange={(e: any) => this.togglePrivatemode()}/>
                  <div class="switchDisplay">
                    <div class="switchStatus centerPlace">{this.state.switchText}</div>
                    <div class="switchBall"></div>
                  </div>
                </label>
              </center>
          </Col>
        </Row>



      </React.Fragment>
    );
  }
  private click(e: any) {
    this.setState({ switchText: this.state.switchText === "ON" ? "OFF" : "ON" });
    this.setState({ switchChecked: !this.state.switchChecked });
  }
  private async togglePrivatemode() {
    var params = {
      "member": {
        "private_mode": !this.state.private_mode
      }
    };
    const api  =  new PrivateModeApiClient(this.props.globalStore);

    try {
      await api.update(params);
      Swal.fire({
        title: '',
        text: this.state.private_mode ? "プライベートモードを解除しました" : "プライベートモードを設定しました" ,
        type: 'success'
      }).then((result) => {
        this.componentDidMount();
      });
    } catch(e) {}
  }

}

export default withRouter(AppDataStore.withStores(PrivatemodePage));
