import React from 'react';
import { Row, Col, Tab, Tabs, Image } from 'react-bootstrap';
import SimpleImageSlider from "react-simple-image-slider";
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import MemberPurchasePageHeader from './MemberPurchasePageHeader';
import { ConfigApiClient } from '../../actions/clients/ConfigApiClient';
import Utils from '../../utils/Utils';
import { TcApiClient } from '../../actions/clients/tc/TcApiClient';
import Alert from "../common/Alert";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import premium_btn_plan_background from '../../materials/images/premium/premium_btn_plan_background.png'
import MemberPurchaseBasic from './MemberPurchaseBasic';
import MemberPurchasePremium from './MemberPurchasePremium';
import { IResource } from '../../resources/IResource';
import { IMyProfile } from '../../resources/IMyProfile';

interface IState extends common.IState {
  configData: IResource | null;
  myProfileData: IMyProfile | null;
}

class MemberPurchasePage extends common.BasePage<common.IProps<common.IParams>, IState> {

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = { configData: {}, myProfileData: Utils.loadMyProfile() };
  }

  async componentDidMount() {
    const myProfile = await Utils.getMyProfile(this.props.globalStore);
    this.setState({ myProfileData: myProfile });

    if (myProfile?.sex !== '男性') {
      return this.props.history.push('/');
    }
  }

  render() {
    return (
      <React.Fragment>
        <MemberPurchasePageHeader />
        <div style={{ height: 0 }}></div>

        <Alert type="error" message="iOSの場合は、［設定アプリ］-［Safari］-［ポップアップロック］をOFFにして購入してください。" />

        <div class="purchase_box">
          <Image width="100%"  src="images/purchase/purchase_title.png" />
          <p class="purchase_top-right">
            <strong>有料会員になると<br></br>
            すぐに女性とのメッセージが<br></br>
            し放題になります！</strong>
          </p>
        </div>

        <div style={{ height: 10 }}></div>

        <Tabs defaultActiveKey={this.state.eventKey} style={{ display: "flex", flexWrap: "nowrap", alignItems: "stretch" }}>
          <Tab eventKey="first" title="ベーシックプラン" tabClassName="nav-item-purchase">
            <MemberPurchaseBasic eventKey="first"
              selectItem={(description, price, code) => this.selectItem(description, price, code)}/>
          </Tab>
          <Tab eventKey="second" title="プレミアムプラン" tabClassName="nav-item-purchase">
            <MemberPurchasePremium eventKey="second"
              selectItem={(description, price, code) => this.selectItem(description, price, code)} />
          </Tab>
        </Tabs>

        <Image width="100%"  src="images/purchase/plan_list_1.png" />

        <div style={{ height: 10 }}></div>

        <div class="purchase_band"></div>
        <div class="purchase_title"><strong>ベーシックプランでできること</strong></div>
        <div class="purchase_band"></div>

        <div style={{ height: 10 }}></div>

        <Row>
          <Col xs={12} md={12}>
            <center>
              <p class="purchase_image_title"><strong>1.いいね送り放題</strong></p>
              <p class="purchase_image_description">
                ポイント不要でいいねが送り放題！<br></br>気になる女性に積極的にいいねを送ることができます！
              </p>
              <Image width="70%"  src="images/purchase/basic_ui_1.png" />
            </center>
          </Col>
        </Row>

        <div style={{ height: 40 }}></div>

        <Row>
          <Col xs={12} md={12}>
            <center>
              <p class="purchase_image_title"><strong>2.メッセージし放題</strong></p>
              <p class="purchase_image_description">
                有料会員期間中は何人でも<br></br>
                マッチングした相手とのメッセージの<br></br>
                やり取りが可能です！
              </p>
              <Image width="70%"   src="images/purchase/basic_ui_2.png" />
            </center>
          </Col>
        </Row>

        <div style={{ height: 40 }}></div>

        <Row>
          <Col xs={12} md={12}>
            <center>
              <p class="purchase_image_title"><strong>3.人気会員を見放題</strong></p>
              <p class="purchase_image_description">
                MITSUMITSUで人気の相手を<br></br>ランキング形式で閲覧できます。
              </p>
              <Image width="70%"   src="images/purchase/basic_ui_3.png" />
            </center>
          </Col>
        </Row>

        <div style={{ height: 40 }}></div>

        <Row>
          <Col xs={12} md={12}>
            <center>
              <p class="purchase_image_title"><strong>4.プロフィールの上位表示</strong></p>
              <p class="purchase_image_description">
              あなたのプロフィールが優先的に表示されるので、<br></br>
              いいねをたくさんもらえたり<br></br>
              あなたのいいねがすぐに見られる確率が上がります。
              </p>
              <Image width="70%"   src="images/purchase/basic_ui_4.png" />
            </center>
          </Col>
        </Row>

        <div style={{ height: 20 }}></div>

        <Image width="100%"  src="images/purchase/plan_list_2.png" />

        <div style={{ height: 10 }}></div>

        <div class="purchase_band"></div>
        <div class="purchase_title"><strong>プレミアムプラン限定機能</strong></div>
        <div class="purchase_band"></div>

        <div style={{ height: 10 }}></div>

        <Row>
          <Col xs={12} md={12}>
            <center>
              <p class="purchase_image_title"><strong>1.メッセージ既読表示</strong></p>
              <p class="purchase_image_description">
              ポイント不要でいいねが送り放題！<br></br>
              気になる女性に積極的にいいねを<br></br>
              送ることができます！
              </p>
              <Image width="100%"   src="images/purchase/premium_ui_1.png" />
            </center>
          </Col>
        </Row>

        <div style={{ height: 40 }}></div>

        <Row>
          <Col xs={12} md={12}>
            <center>
              <p class="purchase_image_title"><strong>2.メッセージ上位表示</strong></p>
              <p class="purchase_image_description">
              あなたのメッセージが優先的に上位に表示され<br></br>返信率がアップします。
              </p>
              <Image width="100%"   src="images/purchase/premium_ui_2.png" />
            </center>
          </Col>
        </Row>

        <div style={{ height: 40 }}></div>

        <Row>
          <Col xs={12} md={12}>
            <center>
              <p class="purchase_image_title"><strong>3.クオリティチェック無制限</strong></p>
              <p class="purchase_image_description">
              女性の違反報告履歴が分かるクオリティチェックが<br></br>
              無制限で利用できます。<br></br>
              ※通常1人につき20ポイント(約250円)
              </p>
              <Image width="100%"   src="images/purchase/premium_ui_3.png" />
            </center>
          </Col>
        </Row>

        <div style={{ height: 10 }}></div>


        <Tabs defaultActiveKey={this.state.eventKey} style={{ display: "flex", flexWrap: "nowrap", alignItems: "stretch" }}>
          <Tab eventKey="first" title="ベーシックプラン" tabClassName="nav-item-purchase">
            <MemberPurchaseBasic
               selectItem={(description, price, code) => this.selectItem(description, price, code)} />
          </Tab>
          <Tab eventKey="second" title="プレミアムプラン" tabClassName="nav-item-purchase">
            <MemberPurchasePremium
              selectItem={(description, price, code) => this.selectItem(description, price, code)} />
          </Tab>
        </Tabs>

        <div class="purchase_text">
        ・決済方法に問わず、更新目前に解約のお手続きをなされた場合、残りの期間についての月割り、日割りのご返金は行っておりません。
        <br></br><br></br>
        </div>

      </React.Fragment>
    );
  }

  private selectItem(description: any, price: any, code: any) {

    let myProfile = Utils.loadMyProfile();
    if ( myProfile['age_verification'] !== Utils.ageVerificationStatus.verified) {
      alert('マイページから年齢確認を行ってください。');
      return;
    }

    confirmAlert({
      //title: s.description,
      //label: s.description,
      childrenElement: () => <div><strong><br />クレジット決済<br />{description}　　{price?.toLocaleString()}円</strong></div>,
      message: '有料会員に登録します。よろしいですか？',
      buttons: [
        {
          label: 'キャンセル',
          //onClick: () => alert('Click Yes')
        },
        {
          label: '購入する',
          onClick: () => this.buyPoint(code)
        }
      ]
    });
  }

  private async buyPoint(s: any) {
    var params = {
      "transaction": {
        "subscription_plan": s,
        "disable_auto_renew": false
      }
    };
    const api = new TcApiClient(this.props.globalStore)
    const data = await api.post(params);
    //console.log(data);
    if (data.result === true) {
      window.open(api.getWebURL(data.data.transaction_id));
    }
  }
}

export default withRouter(AppDataStore.withStores(MemberPurchasePage));
