import React from 'react';
import { Button, Col, Form, Jumbotron, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { UserSession } from '../../actions/session/UserSession';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import SystemNotification from '../common/SystemNotification';
import querystring from 'querystring';
import FireBaseLogEventManager from '../../utils/FireBaseLogEventManager';
import FireBaseLogEvent from '../../utils/enums/FireBaseLogEvent';

interface IParams extends common.IParams {
}

interface IProps extends common.IProps<IParams> {
}

interface IState extends common.IState {
  email: string;
  password: string;
}

class SignInPage extends common.BasePage<IProps, IState> {
  private _session: UserSession;

  constructor(props: IProps) {
    super(props);
    this._session = new UserSession(this.props.globalStore);
    this.state = { email: '', password: '' };
  }

  componentDidMount() {
    const query = querystring.parse(this.props.location.search.substr(1));
    switch (query.reason) {
      case 'unauthenticated':
        this.notifier.error('未ログイン状態のためアクセスできません。');
    }
  }

  render() {
    return (
      <Row>
        <Col xs={12} md={12}>
          <Jumbotron>
            <SystemNotification/>
            <Form className="mt-5">
              <Form.Group>
                <Form.Label>電話番号</Form.Label>
                <Form.Control type="email" name="email" onChange={(e) => this.changeEmail(e)} value={this.state.email}/>
              </Form.Group>
              <Form.Group>
                <Form.Label>パスワード</Form.Label>
                <Form.Control type="password" name="password" onChange={(e) => this.changePassword(e)} value={this.state.password}/>
                <div style={{ height: 8 }}></div>
                <Button type="submit" variant="primary" size="lg" block disabled={!this.state.email || !this.state.password} onClick={(e) => this.signin(e)}>ログイン</Button>
              </Form.Group>
            </Form>
            <Button type="submit" variant="primary" size="lg" block onClick={(e) => this.goToRegist() }>新規登録</Button>
            <center>
              <a style={{ textDecoration: 'underline' }} onClick={() => this.goToResetPassword()}>
                パスワードの再設定はこちらから
              </a>
            </center>
          </Jumbotron>
        </Col>
      </Row>
    );
  }

  private goToResetPassword() {
    this.props.history.push('/reset_password');
  }

  private goToRegist() {

    // log event
    FireBaseLogEventManager.sendLogEvent(FireBaseLogEvent.signUpButtonClick);

    this.props.history.push('/register')
  }

  private changeEmail(e: any) {
    this.setState({ email: e.target.value });
  }

  private changePassword(e: any) {
    this.setState({ password: e.target.value });
  }

  private async signin(e: any) {
    e.preventDefault();
    //if (await this._session.signIn({ email: this.state.email, password: this.state.password })) {
    if (await this._session.signIn(
                                    {
                                      "auth": {
                                        "phone_number": this.state.email,
                                        "password": this.state.password
                                      }
                                    }
    )) {
      this.notifier.info('ログインしました。');
      this.props.history.push('/');
    }
  }
}

export default withRouter(AppDataStore.withStores(SignInPage));
