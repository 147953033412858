import React from 'react';
import * as common from '../common/BasePage';
import { Nav, Navbar, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faRedo } from "@fortawesome/free-solid-svg-icons";

interface IProps extends common.IProps<common.IParams> {
}

interface IState extends common.IState {
}

class NotificationListPageHeader extends common.BasePage<IProps, IState> {
  private title = "お知らせ";

  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
      //document.title = this.title;;
  }

  render() {
    return (
      <React.Fragment>
        <Navbar className="justify-content-center" fixed="top" bg="dark" variant="dark">
          <Navbar.Collapse className="container-fluid">
            <Nav>
              <Button className="bg-dark" onClick={(e: any) => this.toMyPage(e)}>
                <FontAwesomeIcon icon={faChevronLeft} size="sm" style={{ color: "#A1A1A1" }} />
              </Button>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Brand>{this.title}</Navbar.Brand>
          <Navbar.Collapse className="container-fluid">
            <Nav className="ml-auto">
              <Button onClick={(e: any) => this.onReload(e)} className="bg-dark" style={{marginRight: '-1.0em', marginTop: '0.2em'}}>
                <FontAwesomeIcon icon={faRedo} style={{ color: "#ffc107" }} size="sm"  style={{ color: "#ffc107" }} />
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </React.Fragment>
    );
  }

  private async toMyPage(e: any) {
    e.preventDefault();
    this.props.history.push('/mypage');
  }

  // ページ更新
  private onReload = (e: any) => {
    //console.log(e);
    this.props.onReload?.();
  }

}

export default withRouter(AppDataStore.withStores(NotificationListPageHeader));
