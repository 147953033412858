import React from 'react';
import { IFootprintJobParams } from '../../resources/IFootprintJobParams';
import { IJobInterval } from '../../resources/IJobInterval';
import { ILoginJobParams } from '../../resources/ILoginJobParams';
import AppDataStore from '../../stores/AppDataStore';
import * as base from './BaseJobWizardModal';
import MemberIntervalWizardPage from './MemberIntervalWizardPage';

interface IProps extends base.IProps<ILoginJobParams> {
}

interface IState extends base.IState {
  params: ILoginJobParams;
}

class FootprintJobWizardModal extends base.BaseJobWizardModal<IProps, IState, ILoginJobParams> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      currentStep: 1,
      params: props.value,
      showModal: true,
      totalStep: 1
    };
  }

  protected get commitValue(): IFootprintJobParams {
    return this.state.params;
  }

  protected get jobType(): string {
    return 'login';
  }

  protected get pages(): JSX.Element[] {
    return [
      <MemberIntervalWizardPage value={this.state.params.member_interval!} onValueChanged={(value) => this.onMemberIntervalChanged(value)}/>
    ];
  }

  protected get title(): string {
    return '一括ログイン';
  }

  private onMemberIntervalChanged(value: IJobInterval) {
    const params = this.state.params;
    params.member_interval = value;
    this.setState({ params: params });
  }
}

export default AppDataStore.withStores(FootprintJobWizardModal);
