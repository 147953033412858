import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BaseForm';
import AgeverificationImageEditPageHeader from './AgeverificationImageEditPageHeader';
import { AgeVerificationApiClient } from '../../actions/clients/AgeVerificationApiClient';
import Swal from 'sweetalert2'
// https://sweetalert2.github.io/
import 'react-image-crop/dist/ReactCrop.css'
//https://codesandbox.io/s/vmvjl2q023?file=/src/index.js:59-165

interface IState extends common.IState {
  isDialogOpen: boolean;
}

class AgeverificationImageEditPage extends common.BaseForm<IMember, IProps, IState> {

  private style = {
    border: '1px solid gray',
    backgroundColor: 'white',
    aspectRatio: "1"
  };

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = { drawing: false, imageUrl: props.location.state.imageUrl };

  }

  componentDidMount() {
    //React.initializeTouchEvents(true);
    this.loadImage();
  }

  componentWillMount() {
    //React.initializeTouchEvents(true);
  }

  getContext() {
    return this.refs.canvas.getContext('2d');
  }

  startDrawing(x, y) {
    this.setState({ drawing: true });
    const ctx = this.getContext();
    ctx.moveTo(x, y);
  }

  draw(x, y) {
    if (!this.state.drawing) {
      return;
    }
    const ctx = this.getContext();
    ctx.lineWidth = 20;
    ctx.lineTo(x, y);
    ctx.stroke();
  }

  endDrawing() {
    this.setState({ drawing: false });
  }

  clear(e: any) {
    const ctx = this.getContext();
    ctx.clearRect(0, 0, this.refs.canvas.width, this.refs.canvas.height);
    ctx.restore();
    ctx.beginPath();
    this.loadImage();
  }

  // キャンバスの背景
  async loadImage() {
    console.log(this.state.imageUrl);
    const ctx = this.getContext();

    this.refs.canvas.width  = window.innerWidth * 0.80;
    this.refs.canvas.height  = window.innerWidth * 0.80;

    ctx.canvas.width  = window.innerWidth * 0.80;
    ctx.canvas.height  = window.innerWidth * 0.80;
    const chara = new Image();
    chara.src = this.state.imageUrl;  // 画像のURLを指定
    chara.onload = () => {
      ctx.drawImage(chara, 0, 0, chara.naturalWidth, chara.naturalHeight, 0, 0, ctx.canvas.width, ctx.canvas.height);
    };

  }

  startDrawingTouch(event) {
    const canvas = this.refs.canvas;
    const {pageX, pageY} = event.touches ? event.touches[0] : event;
    const x = pageX - canvas.offsetLeft;
    const y = pageY - canvas.offsetTop;

    this.setState({ drawing: true });
    const ctx = this.getContext();
    ctx.moveTo(x, y);
  }

  drawTouch(event) {
    if (!this.state.drawing) {
      return;
    }
    const canvas = this.refs.canvas;
    const {pageX, pageY} = event.touches ? event.touches[0] : event;
    const x = pageX - canvas.offsetLeft;
    const y = pageY - canvas.offsetTop;

    const ctx = this.getContext();
    ctx.lineWidth = 20;
    ctx.lineTo(x, y);
    ctx.stroke();
  }

  render() {
    return (
      <React.Fragment>
        <AgeverificationImageEditPageHeader onPreview={this.onPreview} />

        <div style={{ height: 10 }}></div>

        <Row>
          <Col xs={12} md={12} style={{ textAlign: "center" }}>
            <canvas
              ref="canvas"
              onMouseDown={e => this.startDrawing(e.nativeEvent.offsetX, e.nativeEvent.offsetY)}
              onMouseUp={() => this.endDrawing()}
              onMouseLeave={() => this.endDrawing()}
              onMouseMove={e => this.draw(e.nativeEvent.offsetX, e.nativeEvent.offsetY)}
              ontouchstart={e => this.startDrawingTouch(e)}
              ontouchend={() => this.endDrawing()}
              ontouchmove={e => this.drawTouch(e)}
              style={this.style}
            />
          </Col>
        </Row>
        <Row className="member-profile-row">
          <Col xs={6} md={6} style={{ textAlign: "center" }}>
             <Button style={{ width: "1000" }} onClick={ (e: any) => this.props.history.push('/ageverificationimageselect') }>戻る</Button>
          </Col>
           <Col xs={6} md={6} style={{ textAlign: "center" }}>
              <Button style={{ width: "1000" }} onClick={ (e: any) => this.uploadImage(e) }>送信</Button>
           </Col>
        </Row>
      </React.Fragment>
    );
  }

  // メイン画像更新
  async uploadImage(e: any) {
    const globalStore = this.props.globalStore
    const props = this.props

    var params = {
      "age_verification": {
        "image": this.refs.canvas.toDataURL().replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", "")
      }
    };
    //const data = new AgeVerificationApiClient(globalStore).post(params);
    new AgeVerificationApiClient(globalStore).post(params);
    //console.log(data);

    Swal.fire({
      title: '',
      text: "画像をアップロードしました",
      type: 'success',
    }).then((result) => {
      props.history.push('/mypage');
    })
  }

}

export default withRouter(AppDataStore.withStores(AgeverificationImageEditPage));
