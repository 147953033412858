
export class PointHistorySatusConverter {
  static convert(value?: string): string {
    switch(value) {
      /*
      case 'offered':
        return '未成立';
      case 'complete':
        return '成立';
      case 'reoffered':
        return '再オファー';
      default:
        return '';
      */
      case 'purchase':
          return "ポイント購入";
      case 'bonus_register':
          return "会員登録ボーナス";
      case 'bonus_complete_profile':
          return "プロフィール入力完了ボーナス";
      case 'used_create_offer':
          return "いいね送信";
      case 'used_create_chat':
          return "チャット開始";
      case 'increment_admin':
          return "管理画面からの付与";
      case 'decrement_admin':
          return "管理画面での減算";
      case 'used_for_sending_text_message':
          return "テキストメッセージ送信";
      case 'used_for_sending_image_message':
          return "画像送信";
      case 'expired':
          return "ポイント失効";
      case 'used_for_offering_with_message':
          return "メッセージ付きいいね送信";
      case 'used_for_enabling_violation_reported_counts_reference':
          return "クオリティのチェック";
      case 'used_for_additional_offering_message':
          return "みてね！送信";
      case 'bonus_login':
          return 'ログインボーナス'
      default:
          return '';
    }
  }
}
