import React from 'react';
import { Form } from 'react-bootstrap';
import { IOption } from '../../../resources/IOption';
import AppDataStore from '../../../stores/AppDataStore';
import * as common from '../BaseComponent';
import ValidationErrorFeedback from '../ValidationErrorFeedback';

interface IProps extends common.IProps {
  value?: string;
  errors?: any;
  onChanged: (e: any) => void;
  readonly?: boolean;
}

interface IState extends common.IState {
  value?: string;
  errors?: any;
}

class SexSelector extends common.BaseComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      value: props.value,
      errors: props.errors
    };
  }

  render() {
    const option = this.props.globalStore.get('masterData').option || ({} as IOption);
    return (
      <React.Fragment>
        <Form.Control as="select" custom name="sex" disabled={this.props.readonly} onChange={(e) => this.onChanged(e)} value={this.state.value} isInvalid={!!this.state.errors}>
          <option key="sex_female" value="female">女性</option>
          <option key="sex_male" value="male">男性</option>
        </Form.Control>
        <ValidationErrorFeedback errors={this.state.errors}/>
      </React.Fragment>
    )
  }

  private onChanged(e: any) {
    this.setState({ value: e.target.value });
    this.props.onChanged(e);
  }
}

export default AppDataStore.withStores(SexSelector);
