import React from 'react';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import PointHistoryListPageHeader from './PointHistoryListPageHeader';
import PointHistoryList from './PointHistoryList';

interface IState extends common.IState {
}

class PointHistoryListPage extends common.BasePage<common.IProps<common.IParams>, IState> {


  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = { member: {} };
  }

  async componentDidMount() {;

  }

  render() {
    return (
      <React.Fragment>
        <PointHistoryListPageHeader onReload={(e) => this.onReload(e)} />

        <div style={{ height: 0 }}></div>

        <PointHistoryList page={this.state.page} />

      </React.Fragment>
    );
  }

  // ページ更新
  private onReload = () => {
    window.location.reload();
  }

}

export default withRouter(AppDataStore.withStores(PointHistoryListPage));
