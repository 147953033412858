import { Col, Form } from 'react-bootstrap';
import { IMemberCondition } from '../../resources/IMemberCondition';
import AppDataStore from '../../stores/AppDataStore';
import ActionButton from '../common/ActionButton';
import * as common from '../common/BaseComponent';
import MasterDataSelector from '../common/form/MasterDataSelector';
import SexSelector from '../common/form/SexSelector';
import { InputEventReflector } from '../common/InputEventReflector';

interface IProps extends common.IProps {
  condition?: IMemberCondition;
  hideSexField?: boolean;
  onSearchRequested: (condition: IMemberCondition) => void;
}

interface IState extends common.IState {
  condition: IMemberCondition;
}

class MemberSearchForm extends common.BaseComponent<IProps, IState> {
  private _reflector: InputEventReflector;

  constructor(props: IProps) {
    super(props);
    this._reflector = new InputEventReflector();
    this.state = { condition: this.props.condition || {} };
  }

  render() {
    return (
      <Form>
        <Form.Row>
          {!this.props.hideSexField && (
            <Form.Group as={Col} md="3">
              <Form.Label>性別</Form.Label>
              <SexSelector value={this.state.condition?.sex} onChanged={(e) => this.changeSex(e)}/>
            </Form.Group>
          )}
          <Form.Group as={Col} md="3">
            <Form.Label>体型</Form.Label>
            <MasterDataSelector property="body_type" masterDataKey="body_types" value={this.state.condition?.body_type} onChanged={(e) => this.changeCondition(e)}/>
          </Form.Group>
          <Form.Group as={Col} md="3">
          <Form.Label>住んでいる地域</Form.Label>
            <MasterDataSelector property="prefecture" masterDataKey="prefectures" value={this.state.condition?.prefecture} onChanged={(e) => this.changeCondition(e)}/>
          </Form.Group>
          <Form.Group as={Col} md="3">
          <Form.Label>出身地</Form.Label>
            <MasterDataSelector property="hometown" masterDataKey="prefectures" value={this.state.condition?.hometown} onChanged={(e) => this.changeCondition(e)}/>
          </Form.Group>
          <Form.Group as={Col} md="3">
            <Form.Label>年収</Form.Label>
            <MasterDataSelector property="annual_income" masterDataKey="annual_incomes" value={this.state.condition?.annual_income} onChanged={(e) => this.changeCondition(e)}/>
          </Form.Group>
          <Form.Group as={Col} md="3">
            <Form.Label>お酒</Form.Label>
            <MasterDataSelector property="drinking_type" masterDataKey="drinking_types" value={this.state.condition?.drinking_type} onChanged={(e) => this.changeCondition(e)}/>
          </Form.Group>
          <Form.Group as={Col} md="3">
            <Form.Label>タバコ</Form.Label>
            <MasterDataSelector property="smoking_type" masterDataKey="smoking_types" value={this.state.condition?.smoking_type} onChanged={(e) => this.changeCondition(e)}/>
          </Form.Group>
          <Form.Group as={Col} md="3">
            <Form.Label>職業</Form.Label>
            <MasterDataSelector property="occupation" masterDataKey={`occupations.${this.state.condition.sex}`} value={this.state.condition.occupation} onChanged={(e) => this.changeCondition(e)}/>
          </Form.Group>
          <Form.Group as={Col} md="3">
            <Form.Label>電話番号</Form.Label>
            <Form.Control type="text" name="phone_number" onChange={(e) => this.changeCondition(e)} value={this.state.condition?.phone_number} isInvalid={!!this.state.errors?.phone_number}/>
          </Form.Group>
          <Form.Group as={Col} md="3">
            <Form.Label>ニックネーム</Form.Label>
            <Form.Control type="text" name="name" onChange={(e) => this.changeCondition(e)} value={this.state.condition?.name} isInvalid={!!this.state.errors?.name}/>
          </Form.Group>
          <Form.Group as={Col} md="3">
            <Form.Label>一緒に行きたい場所</Form.Label>
            <MasterDataSelector property="desired_dating_plan" masterDataKey="desired_dating_plans" value={this.state.condition?.desired_dating_plan} onChanged={(e) => this.changeCondition(e)}/>
          </Form.Group>
        </Form.Row>
        <ActionButton type="submit" onClick={(e: any) => this.submit(e)}>検索</ActionButton>
      </Form>
    );
  }

  private changeSex(e: any) {
    const condition = this.state.condition;
    if (condition) {
      delete condition.occupation;
      this.setState({ condition: condition });
    }
    this.changeCondition(e);
  }

  private changeCondition(e: any) {
    const condition = this.state.condition;
    if (condition) {
      const result = this._reflector.execute(condition!, e);
      if (result) {
        this.setState({ condition: result || condition });
      }
    }
    //console.log(condition)
  }

  private submit(e: any) {
    e.preventDefault();
    this.props.onSearchRequested(this.state.condition);
  }
}

export default AppDataStore.withStores(MemberSearchForm);
