import React from 'react';
import * as common from '../common/BasePage';
import { Nav, Navbar, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
//import { UserSession } from '../../actions/session/UserSession';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";

interface IProps extends common.IProps<common.IParams> {
}

interface IState extends common.IState {
}

class BbsListPageHeader extends common.BasePage<IProps, IState> {
  private title = "BBS";

  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
      //document.title = this.title;;
  }

  render() {
    return (
      <React.Fragment>
        <Navbar className="justify-content-center bg-dark" fixed="top" bg="dark" variant="dark">
          <Navbar.Collapse className="container-fluid">
            <Nav>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Brand>{this.title}</Navbar.Brand>
          <Navbar.Collapse className="container-fluid">
            <Nav className="ml-auto">
              <Button onClick={(e: any) => this.signOut(e)} className="bg-dark" style={{marginRight: '-1.0em', marginTop: '0.2em'}}>
                <FontAwesomeIcon icon={faRedo} style={{ color: "#ffc107" }} size="sm" />
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </React.Fragment>
    );
  }

  private async signOut(e: any) {
    e.preventDefault();
  }
}

export default withRouter(AppDataStore.withStores(BbsListPageHeader));
