import { IFootprint } from "../../resources/IFavorite";
import { BaseApiClient } from "./BaseApiClient"

export class FavoritesApiClient extends BaseApiClient<IFootprint> {
  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    return 'api/v1/favorites';
  }
}
