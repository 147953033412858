import { ReactNode } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Subscription } from 'rxjs';
import { OptionApiClient } from '../../actions/clients/OptionApiClient';
import { UserSession } from '../../actions/session/UserSession';
import AppDataStore from '../../stores/AppDataStore';
import * as common from './BasePage';
import { MyProfileApiClient } from '../../actions/clients/MyProfileApiClient';
import Utils from '../../utils/Utils';

interface IProps extends common.IProps<common.IParams> {
  children: ReactNode
}

class AuthBoundary extends common.BasePage<IProps, common.IState> {
  private _optionAPiClient: OptionApiClient;
  private _myProfileAPiClient: MyProfileApiClient;
  private _session: UserSession;
  private _subscription?: Subscription;

  constructor(props: IProps) {
    super(props);
    this._optionAPiClient = new OptionApiClient(props.globalStore);
    this._myProfileAPiClient = new MyProfileApiClient(props.globalStore);
    this._session = new UserSession(this.props.globalStore);
  }

  componentDidMount() {
    this.loadMasterData();

    this._subscription = this.props.globalStore.on('authorized').subscribe((authorized: boolean) => {
      this.loadMasterData();
      if (!authorized) {
        this.props.history.push('/signin');
      }
    });
  }

  componentWillUnmount() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  render() {
    if (this._session.isAuthenticated) {
      return this.props.children;
    }
    else {
      return <Redirect to={'/signin?reason=unauthenticated'}/>;
    }
  }

  private async loadMasterData() {
    if (!this._session.isAuthenticated) {
      //console.log('Skip loading master data because of unauthorized.');
      return;
    }

    // 自分のプロフィールを取得して保存
    const profile = this.props.globalStore.get('myProfileData');
    if (!profile.isLoaded) {
      const response = await Utils.getMyProfile(this.props.globalStore);
      if (response) {
        this.props.globalStore.set('myProfileData')({
          isLoaded: true,
          data: response
        });
      }
    }

    console.log('this.props.globalStore', this.props.globalStore);
    const masterData = this.props.globalStore.get('masterData');
    if (masterData.isLoaded) {
      //console.log('Skip loading master data because of already loaded.');
      return;
    }

    const option = await this._optionAPiClient.get();
    if (option) {
      ////console.log(option);
      this.props.globalStore.set('masterData')({
        isLoaded: true,
        option: option
      });
    }
  }
}

export default withRouter(AppDataStore.withStores(AuthBoundary));
