import { IFootprint } from "../../resources/IFootprint";
import { BaseApiClient } from "./BaseApiClient"

export class FootprintApiClient extends BaseApiClient<IFootprint> {

  private _memberId: number;

  constructor(store: Store<IAppGlobalStore>, memberId: number) {
    super(store);
    this._memberId = memberId;
  }

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    //console.log(`/api/v1/members/${this._memberId}/footprints`);
    return `api/v1/members/${this._memberId}/footprints`;
  }
}
