import * as common from '../common/BaseListComponent';
import AppDataStore from '../../stores/AppDataStore';
import { BaseApiClient } from '../../actions/clients/BaseApiClient';
import { Row, Col } from 'react-bootstrap';
import LoadingIndicator from '../common/LoadingIndicator';
import Paginator from '../common/Paginator';
import { INotification } from '../../resources/INotification';
import { NotificationApiClient } from '../../actions/clients/NotificationApiClient';
import { NotificationReadApiClient } from '../../actions/clients/NotificationReadApiClient';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

interface IProps extends common.IProps<INotification> {
}

interface IState extends common.IState<INotification> {
}

class NotificationList extends common.BaseListComponent<IOffer, IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <LoadingIndicator watch={this.state.response}>

          <Accordion allowZeroExpanded>
            {this.state.response?.data.map((f, index)  => (
                <AccordionItem key={f.id}>
                    <AccordionItemHeading onClick={(e: any) => this.clickRow(f)}>
                        <AccordionItemButton>
                          {f.unread ? <b style={{ color: "#fff" }}>{f.title}</b> : <span>{f.title}</span> }
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div dangerouslySetInnerHTML={{__html: f.content}}></div>
                    </AccordionItemPanel>
                </AccordionItem>
            ))}
          </Accordion>

          {this.state.response?.data.length === 0 && (
            <Row>
              <Col xs={2} md={2}></Col>
              <Col className="nodata" xs={8} md={8}>データが見つかりません。</Col>
              <Col xs={2} md={2}></Col>
            </Row>
          )}

          <Paginator currentPage={this.state.response?.meta.current_page} totalPage={this.state.response?.meta.total_pages} onPageSelected={(page) => this.selectPage(page)}/>
        </LoadingIndicator>
      </>
    );
  }

  private async clickRow(f: any) {
    if(f.unread === false) {
      return;
    }
    // 既読へ
    await new NotificationReadApiClient(this.props.globalStore, f.id).post({});

    // 表示切り替え あまりよろしくないが暫定的にこうする
    var data = this.state.response?.data;
    for(const r in data) {
      const item = data[r];
      if (item.id === f.id) {
        item.unread = false;
        data[r] = item;
        this.setState({response:{
          result: this.state.response?.result,
          data: data,
          meta: this.state.response?.meta
        }});
      }
    }

  }

  protected createClient(): BaseApiClient<IOffer> {
    return new NotificationApiClient(this.props.globalStore);
  }

  protected buildSearchCondition(page?: number) {
    return Object.assign({}, {}, { page: page });
  }
}

export default AppDataStore.withStores(NotificationList);
