import React from 'react';
import { IFootprintJobParams } from '../../resources/IFootprintJobParams';
import { IJobInterval } from '../../resources/IJobInterval';
import { IMemberCondition } from '../../resources/IMemberCondition';
import AppDataStore from '../../stores/AppDataStore';
import * as base from './BaseJobWizardModal';
import MemberIntervalWizardPage from './MemberIntervalWizardPage';
import MemberListWizardPage from './MemberListWizardPage';
import TargetIntervalWizardPage from './TargetIntervalWizardPage';

interface IProps extends base.IProps<IFootprintJobParams> {
}

interface IState extends base.IState {
  params: IFootprintJobParams;
}

class FootprintJobWizardModal extends base.BaseJobWizardModal<IProps, IState, IFootprintJobParams> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      currentStep: 1,
      params: props.value,
      showModal: true,
      totalStep: 3
    };
  }

  protected get commitValue(): IFootprintJobParams {
    return this.state.params;
  }

  protected get jobType(): string {
    return 'footprint';
  }

  protected get pages(): JSX.Element[] {
    return [
      <MemberIntervalWizardPage value={this.state.params.member_interval!} onValueChanged={(value) => this.onMemberIntervalChanged(value)}/>,
      <MemberListWizardPage value={this.state.params.target!} onValueChanged={(value) => this.onTargetChanged(value)}/>,
      <TargetIntervalWizardPage value={this.state.params.target_interval!} onValueChanged={(value) => this.onTargetIntervalChanged(value)}/>
    ];
  }

  protected get title(): string {
    return 'あしあと一括送信';
  }

  private onMemberIntervalChanged(value: IJobInterval) {
    const params = this.state.params;
    params.member_interval = value;
    this.setState({ params: params });
  }

  private onTargetChanged(value: IMemberCondition) {
    const params = this.state.params;
    params.target = value;
    this.setState({ params: params });
  }

  private onTargetIntervalChanged(value: IJobInterval) {
    const params = this.state.params;
    params.target_interval = value;
    this.setState({ params: params });
  }
}

export default AppDataStore.withStores(FootprintJobWizardModal);
