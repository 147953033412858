import React from 'react';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BaseForm';
import AgeverificationImageSelectPageHeader from './AgeverificationImageSelectPageHeader';
import { AgeVerificationApiClient } from '../../actions/clients/AgeVerificationApiClient';
import Swal from 'sweetalert2'
import { isMobile } from "react-device-detect"
// https://sweetalert2.github.io/
//import 'react-image-crop/dist/ReactCrop.css'
//https://codesandbox.io/s/vmvjl2q023?file=/src/index.js:59-165
import ImageCropper from '../imagecropper/ImageCropper';

interface IState extends common.IState {
}

class AgeverificationImageSelectPage extends common.BaseForm<IMember, IProps, IState> {

  private buttonName: String = "送信";

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = {};

    this.buttonName = isMobile ? "送信" : "次へ";
  }

  async componentDidMount() {
  }

  render() {
    return (
      <React.Fragment>
        <AgeverificationImageSelectPageHeader onPreview={this.onPreview} />

        <div style={{ height: 10 }}></div>

          <ImageCropper imageToCrop={this.state.src} buttonName={this.buttonName}
            onPostImageUrl={(imageUrl: any) => this.goToEdit(imageUrl)}
          />

      </React.Fragment>
    );
  }

  private goToEdit(imageUrl: any) {
    if (isMobile) {
      this.uploadImage(imageUrl);
    }
    // 次の編集画面へ モバイル／タブレット以外
    else {
      const globalStore = this.props.globalStore
      const props = this.props
      this.props.history.push({
        pathname: `/ageverificationimageedit`,
        state: { imageUrl: imageUrl }
      });
    }
  }

  // 年齢認証画像送信
  async uploadImage(imageUrl: any) {
    const globalStore = this.props.globalStore
    const props = this.props

    var xhr = new XMLHttpRequest;
    xhr.responseType = 'blob';

    xhr.onload = function() {
       var recoveredBlob = xhr.response;

       var reader = new FileReader;

       reader.onload = function() {
         var blobAsData = reader.result;

         var params = {
           "age_verification": {
             "image": blobAsData.replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", "")
           }
         };
         const data = new AgeVerificationApiClient(globalStore).post(params);
         //console.log(data);

         Swal.fire({
           title: '',
           text: "画像をアップロードしました",
           type: 'success',
         }).then((result) => {
           props.history.push('/myprofile');
         })

       };
       reader.readAsDataURL(recoveredBlob);
    };

    xhr.open('GET', imageUrl);
    xhr.send();

  }

}

export default withRouter(AppDataStore.withStores(AgeverificationImageSelectPage));
