import { Store } from "undux";
import { IMember } from "../../resources/IMember";
import { IAppGlobalStore } from "../../stores/IAppGlobalStore";
import { BaseApiClient } from "./BaseApiClient"

enum SearchTabType {
    recommended,
    new,
    login,
    today
}

export class SearchMembersApiClient extends BaseApiClient<IMember> {
  private _type: Int;

  constructor(store: Store<IAppGlobalStore>, type: Int = SearchTabType.recommended) {
    super(store);
    this._type = type;
  }

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    var api_url = "api/v1/members";
    switch (this._type) {
      case 1:
        api_url = "api/v1/members?new_only=true";
        break;
      case 2:
        api_url = "api/v1/members?order=login";
        break;
      case 3:
        api_url = "api/v1/members?q%5Btoday_ok_eq%5D=1";
        break;
      default:
        break;
    }
    return api_url;
  }
}
