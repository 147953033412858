import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import SignInPage from './components/auth/SignInPage';
import AuthBoundary from './components/common/AuthBoundary';
import SystemNotification from './components/common/SystemNotification';
import ScrollToTop from './components/common/ScrollToTop';
import MemberDetailPage from './components/member/MemberDetailPage';
import MemberListPage from './components/member/MemberListPage';
import TopPage from './components/top/TopPage';
import AppDataStore from './stores/AppDataStore';
import RoomListPage from './components/room/RoomListPage';
import RoomDetailPage from './components/room/RoomDetailPage';
import OfferListPage from './components/offer/OfferListPage';
import SearchListPage from './components/search/SearchListPage';
import MyPage from './components/mypage/MyPage';
import BbsListPage from './components/bbs/BbsListPage';
import SettingPage from './components/setting/SettingPage';
import HelpPage from './components/help/HelpPage';
import NotificationListPage from './components/notification/NotificationListPage';
import FavoriteListPage from './components/favorite/FavoriteListPage';
import FootprintListPage from './components/footprint/FootprintListPage';
import PointPage from './components/point/PointPage';
import RankingListPage from './components/ranking/RankingListPage';
import MyProfilePage from './components/myprofile/MyProfilePage';
import MyProfileImagePage from './components/myprofile/MyProfileImagePage';
import MyProfileSubImagePage from './components/myprofile/MyProfileSubImagePage';
import MyProfileNamePage from './components/myprofile/MyProfileNamePage';
import MyProfilePlacePage from './components/myprofile/MyProfilePlacePage';
import MyProfileTweetPage from './components/myprofile/MyProfileTweetPage';
import MyProfileIntroPage from './components/myprofile/MyProfileIntroPage';
import InquiryPage from './components/inquiry/InquiryPage'
import RegisterPhonePage from './components/register/RegisterPhonePage'
import RegisterSmsPage from './components/register/RegisterSmsPage'
import RegisterInfoPage from './components/register/RegisterInfoPage'
import MemberPurchasePage from './components/memberpurchase/MemberPurchasePage'
import PremiumPurchasePage from './components/premiumpurchase/PremiumPurchasePage'
import AgeverificationImageSelectPage from './components/ageverification/AgeverificationImageSelectPage'
import AgeverificationImageEditPage from './components/ageverification/AgeverificationImageEditPage'
import PointHistoryListPage from './components/pointhistory/PointHistoryListPage'
import BlockListPage from './components/block/BlockListPage'
import WithdrawalPage from './components/withdrawal/WithdrawalPage'
import PrivatemodePage from './components/privatemode/PrivatemodePage'
import SubscriptionPage from './components/subscription/SubscriptionPage'
import RequestToWithdrawPage from './components/requesttowithdraw/RequestToWithdrawPage'
import MaintenancePage from './components/maintenance/MaintenancePage';
import MaintenanceBoundary from './components/common/MaintenanceBoundary';
import ResetPasswordSmsPage from './components/resetPassword/ResetPasswordSmsPage';
import ResetPasswordPage from './components/resetPassword/ResetPasswordPage';
import ResetPasswordInfoPage from './components/resetPassword/ResetPasswordInfoPage';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import FireBaseLogEventManager from './utils/FireBaseLogEventManager';
import FireBaseLogEvent from './utils/enums/FireBaseLogEvent';

import { Helmet } from 'react-helmet';

class App extends React.Component {

  componentDidMount() {

    // https://zenn.dev/magicmoment/articles/react-ga4-202109
    process.nextTick(() => {
      window.gtag('event', 'page_view', {
        page_path: window.location.pathname,
      });
    });
  }

  render() {

    // log event
    FireBaseLogEventManager.sendLogEvent(FireBaseLogEvent.inAppMessageIsReady);

    return (
      <AppDataStore.Container>
        <Container fluid>
        {process.env.REACT_APP_STAGE === "prod" && (
          <Helmet>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=G-27T68TQ5Y2`}></script>
            <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-27T68TQ5Y2', { send_page_view: false });
              `}
            </script>
          </Helmet>
          )}
          <Row className="mt-4 pt-5">
            <Col>
              <Router>
                <MaintenanceBoundary>
                  <Switch>
                    <Route path="/maintenance">
                      <MaintenancePage />
                    </Route>
                    <Route path="/signin">
                      <SignInPage/>
                    </Route>
                    <Route path="/register">
                      <RegisterPhonePage />
                    </Route>
                    <Route path="/registersms">
                      <RegisterSmsPage />
                    </Route>
                    <Route path="/registerinfo">
                      <RegisterInfoPage />
                    </Route>
                    <Route path="/reset_password">
                      <ResetPasswordPage />
                    </Route>
                    <Route path="/reset_password_sms">
                      <ResetPasswordSmsPage />
                    </Route>
                    <Route path="/reset_password_info">
                      <ResetPasswordInfoPage />
                    </Route>
                    <Route path="/requesttowithdraw">
                      <RequestToWithdrawPage />
                    </Route>
                    <AuthBoundary>
                      <ScrollToTop />
                      <SystemNotification/>
                      <Switch>
                        <Route path="/members/:id">
                          <MemberDetailPage/>
                        </Route>
                        <Route path="/members">
                          <MemberListPage/>
                        </Route>
                        <Route path="/rooms/:id">
                          <RoomDetailPage/>
                        </Route>
                        <Route path="/rooms">
                          <RoomListPage/>
                        </Route>
                        <Route path="/offers">
                          <OfferListPage/>
                        </Route>
                        <Route path="/search">
                          <SearchListPage/>
                        </Route>
                        <Route path="/mypage">
                          <MyPage/>
                        </Route>
                        <Route path="/bbs">
                          <BbsListPage/>
                        </Route>
                        <Route path="/setting">
                          <SettingPage/>
                        </Route>
                        <Route path="/help">
                          <HelpPage/>
                        </Route>
                        <Route path="/notification">
                          <NotificationListPage/>
                        </Route>
                        <Route path="/favorite">
                          <FavoriteListPage/>
                        </Route>
                        <Route path="/footprint">
                          <FootprintListPage/>
                        </Route>
                        <Route path="/point">
                          <PointPage/>
                        </Route>
                        <Route path="/ranking">
                          <RankingListPage/>
                        </Route>
                        <Route path="/myprofile">
                          <MyProfilePage />
                        </Route>
                        <Route path="/myprofileimage">
                          <MyProfileImagePage />
                        </Route>
                        <Route path="/myprofilesubimage">
                          <MyProfileSubImagePage />
                        </Route>
                        <Route path="/myprofilename">
                          <MyProfileNamePage />
                        </Route>
                        <Route path="/myprofileplace">
                          <MyProfilePlacePage />
                        </Route>
                        <Route path="/myprofiletweet">
                          <MyProfileTweetPage />
                        </Route>
                        <Route path="/myprofileintro">
                          <MyProfileIntroPage />
                        </Route>
                        <Route path="/inquiry">
                          <InquiryPage />
                        </Route>
                        <Route path="/memberpurchase">
                          <MemberPurchasePage />
                        </Route>
                        <Route path="/premiumpurchase">
                          <PremiumPurchasePage />
                        </Route>
                        <Route path="/ageverificationimageselect">
                          <AgeverificationImageSelectPage />
                        </Route>
                        <Route path="/ageverificationimageedit">
                          <AgeverificationImageEditPage />
                        </Route>
                        <Route path="/pointhistory">
                          <PointHistoryListPage />
                        </Route>
                        <Route path="/block">
                          <BlockListPage />
                        </Route>
                        <Route path="/withdrawal">
                          <WithdrawalPage />
                        </Route>
                        <Route path="/privatemode">
                          <PrivatemodePage />
                        </Route>
                        <Route path="/subscription">
                          <SubscriptionPage />
                        </Route>
                        <Route path="/">
                          <TopPage/>
                        </Route>
                      </Switch>
                    </AuthBoundary>
                  </Switch>
                </MaintenanceBoundary>
              </Router>
            </Col>
          </Row>
        </Container>
      </AppDataStore.Container>
    );
  }
}

export default App;
