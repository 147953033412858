import React from 'react';
import { Row, Col, Image  } from 'react-bootstrap';
import SimpleImageSlider from "react-simple-image-slider";
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import PremiumPurchasePageHeader from './PremiumPurchasePageHeader';
import { ConfigApiClient } from '../../actions/clients/ConfigApiClient';
import { TcApiClient } from '../../actions/clients/tc/TcApiClient';
import Utils from '../../utils/Utils';
import premium_btn_plan_background from '../../materials/images/premium/premium_btn_plan_background.png'
import premium_banner from '../../materials/images/premium/premium_banner.png'


interface IState extends common.IState {
}

class PremiumPurchasePage extends common.BasePage<common.IProps<common.IParams>, IState> {

  images = [
    { url: "images/premium/merit01.png" },
    { url: "images/premium/merit02.png" },
    { url: "images/premium/merit03.png" },
  ];

  containerStyle = {
        backgroundImage:
          'url(' +  premium_btn_plan_background + ')' ,
        width: "300px",
        height: "72px",
        position: "relative",
      };

  bannerStyle = {
        backgroundImage:
          'url(' +  premium_banner + ')' ,
        width: "320px",
        height: "92px",
      };

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = { configData: {}, pointData: [{}] };
  }

  async componentDidMount() {;
    const configData = await new ConfigApiClient(this.props.globalStore).get();
    this.setState({ configData: configData });
    const key = this.getPointPriceKey();
    this.setState({ pointData: JSON.parse(configData[key]) });
  }

  render() {
    return (
      <React.Fragment>
        <PremiumPurchasePageHeader />
        <div style={{ height: 0 }}></div>
        <div>
          <center>
            <Image width={380} src="images/premium/premium_text_threemerit.png" />
          </center>
        </div>
        <div style={{ height: 10 }}></div>
        <div>
          <center>
            <SimpleImageSlider
              width={320} height={330}
              images={this.images}
              showBullets={true}
              showNavs={true}
              navStyle={2}
              autoPlay={true}
            />
          </center>
        </div>

        <div style={{ height: 10 }}></div>

        {this.state.pointData?.map((s, index) => (
          <Row key={index}>
            <Col xs={12} md={12}>
              <center>
              <div style={this.containerStyle} onClick={(e: any) => this.selectItem(s)}>
                <div style={{ position: "absolute", height: "100%", textAlign: "left", padding: "24px",  }}><strong>{s.description}</strong></div>
                <div style={{ position: "absolute", height: "100%", width: "100%", textAlign: "right", padding: "24px" }}><strong>¥{s.price?.toLocaleString()}</strong></div>
              </div>;
              </center>
            </Col>
          </Row>
        ))}

        <Row >
          <Col xs={12} md={12}>
            <center>
              <div style={this.bannerStyle}></div>
            </center>
          </Col>
        </Row>

        <div style={{ height: 20 }}></div>

      </React.Fragment>
    );
  }

  private selectItem(s: any) {

    let myProfile = Utils.loadMyProfile();
    if ( myProfile['age_verification'] !== Utils.ageVerificationStatus.verified) {
      alert('マイページから年齢確認を行ってください。');
      return;
    }

    Utils.setItem("prmium_inquiry", true);
    this.props.history.push('/inquiry');
  }

  private async buyPoint(s: any) {
    //console.log(s);
    var params = {
      "transaction": {
        "subscription_plan": s.code_ios,
        "disable_auto_renew": false
      }
    };
    const api = new TcApiClient(this.props.globalStore)
    const data = await api.post(params);
    //console.log(data);
    if (data.result === true) {
      window.open(api.getWebURL(data.data.transaction_id));
    }
  }

  private getPointPriceKey() {
    if( window.location.host.indexOf('staging') !== -1 || window.location.host.indexOf('localhost') !== -1) {
      return 'premium_member_price_json_develop';
    }
    return 'premium_member_price_json_production';
  }
}

export default withRouter(AppDataStore.withStores(PremiumPurchasePage));
