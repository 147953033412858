import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import RoomHeader from './RoomHeader';
import Footer from '../nav/Footer';
import RoomList from './RoomList';
import Utils from '../../utils/Utils';

interface IState extends common.IState {
}

class RoomListPage extends common.BasePage<common.IProps<common.IParams>, IState> {
  private _client: JobApiClient;
  private hash = "";

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = {
      eventKey: Utils.getItem("eventKeyRoom") ? Utils.getItem("eventKeyRoom") : "first"
    }
    this.hash     = props.location.hash !== "" ?  props.location.hash : "tab1";
  }

  componentDidMount() {
    this.setState({ eventKey: Utils.getItem("eventKeyRoom") ? Utils.getItem("eventKeyRoom") : "first" });
  }

  render() {
    return (
      <React.Fragment>
        <RoomHeader onReload={(e) => this.onReload(e)} />
        <Tabs defaultActiveKey={this.state.eventKey} style={{ display: "flex", flexWrap: "nowrap", alignItems: "stretch" }}>
          <Tab eventKey="first" title="マッチング済" tabClassName="nav-item-room">
            <RoomList eventKey="first" page={this.state.page} onPageSelected={(page) => this.selectPage(page)} status="matching_only"
              onMessageSelected={(room) => this.onMessageSelected(room)} />
          </Tab>
          <Tab eventKey="second" title="やりとり中" tabClassName="nav-item-room">
            <RoomList eventKey="second"  page={this.state.page} onPageSelected={(page) => this.selectPage(page)} status="contacted"
               onMessageSelected={(room) => this.onMessageSelected(room)} />
          </Tab>
          <Tab eventKey="third" title="未返信" tabClassName="nav-item-room">
            <RoomList eventKey="third" page={this.state.page} onPageSelected={(page) => this.selectPage(page)} status="not_reply"
               onMessageSelected={(room) => this.onMessageSelected(room)} />
          </Tab>
        </Tabs>
        <div style={{ height: 60 }}></div>
        <Footer selectIndex={2} />
      </React.Fragment>
    );
  }

  private onMessageSelected(room?: IRoom) {
    this.props.history.push({
      pathname: `/rooms/${room?.id}`,
      state: { room: room }
    });
  }

  private onMessagePosted () {
    //console.log('onMessagePosted');
  }

  // ページ更新
  private onReload(e: any) {
    window.location.reload();
  }
}

export default withRouter(AppDataStore.withStores(RoomListPage));
