import { Store } from "undux";
import { IMessage } from "../../resources/IMessage";
import { IAppGlobalStore } from "../../stores/IAppGlobalStore";
import { BaseApiClient } from "./BaseApiClient"

export class MessageApiClient extends BaseApiClient<IMessage> {
  private _roomId: number;

  constructor(store: Store<IAppGlobalStore>, roomId: number) {
    super(store);
    this._roomId = roomId;
  }

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    return `api/v1/message_rooms/${this._roomId}/messages`;
  }
}
