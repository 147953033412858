import { IMessage } from "../../resources/IBbsMessage";
import { BaseApiClient } from "./BaseApiClient"

export class BbsMessageApiClient extends BaseApiClient<IMessage> {
  /*
  constructor(store: Store<IAppGlobalStore>) {
    super(store);
  }
  */

  protected get endpoint(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost';
  }

  protected basePath(): string {
    return `api/v1/bbs_messages`;
  }
}
