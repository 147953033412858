import React from "react";
import { Modal, Form, Button } from 'react-bootstrap';

// https://react-bootstrap.github.io/components/modal/

import { Subscription } from 'rxjs';
import AppDataStore from '../../stores/AppDataStore';
import { IAppUiLocker } from '../../stores/IAppUiLocker';
import * as common from '../common/BaseComponent';
//import * as common from '../common/BaseForm';
import { IMember } from '../../resources/IResource';
import ValidationErrorFeedback from '../common/ValidationErrorFeedback';
import Utils from '../../utils/Utils';
import FireBaseLogEventManager from '../../utils/FireBaseLogEventManager';
import FireBaseLogEvent from '../../utils/enums/FireBaseLogEvent';

interface IProps extends common.IProps {
};

interface IState extends common.IState {
}

class OfferWithMessagePopUp extends common.BaseComponent<IProps, IState> {
  private subscription: Subscription | null = null;
  private messageText = "";

  constructor(props: IProps) {
    super(props);
    this.state = {
      show: false,
      myProfile: Utils.loadMyProfile(),
    };
    this.messageText = Utils.loadMyProfile().paid ? "" : "メッセージ付きいいねを送信する場合<br>10ポイントを消費します";
  }

  private handleShow() {
    this.setState({ show: true });
  }

  private handleClose() {
    this.setState({ show: false });
  }

  render() {
    return (
      <>
        <Button variant="primary" onClick={() => this.handleShow()} >
          メッセージ ＋ いいね
        </Button>

        <Modal show={this.state.show} >
          <Modal.Header closeButton onClick={() => this.handleClose()}>
            <Modal.Title>メッセージ付きいいねを送る</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ testAlign: "center"}}>
            <center>
              ＊有料会員は送り放題です
              <div id="login_duration_description" dangerouslySetInnerHTML={{ __html: this.messageText }}></div>
            </center>
            <span style={{ fontSize: "12px", display: "flex", justifyContent: "right", alignItems: "flex-end"　}}>
              200文字以内
            </span>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control as="textarea" rows={3} name="message" onChange={(e) => this.changeResource(e)}
                              value={this.state.message}
                              placeholder="例：はじめまして！都内で会社員をしています○○○といいます。おいしいゴハン、ワイン大好きです！よろしくおねがいします！"
                               />
                <ValidationErrorFeedback errors={this.state.errors?.message}/>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleClose()}>
              キャンセル
            </Button>
            <Button variant="primary"
              disabled={!this.state.message || this.state.message.length > 200 }
              onClick={() => this.onSubmit()}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  protected changeResource(e: any, options?: InputEventReflectorOptions) {

    // log event
    FireBaseLogEventManager.sendLogEvent(FireBaseLogEvent.offerButtonClick);

    // 変数名なので[]をつける
    this.setState({ [e.target.name] : e.target.value });
  }

  private onSubmit() {
    this.props.onOfferWithMessage?.(this.state.message);
    this.handleClose();
  }
}

export default AppDataStore.withStores(OfferWithMessagePopUp);
