import React from 'react';
import { Button, Col, Form, Jumbotron, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import SystemNotification from '../common/SystemNotification';
import FireBaseLogEventManager from '../../utils/FireBaseLogEventManager';
import FireBaseLogEvent from '../../utils/enums/FireBaseLogEvent';
import { ResetPasswordPhoneApiClient } from '../../actions/clients/resetPassword/ResetPasswordPhoneApiClient';

interface IParams extends common.IParams {
}

interface IProps extends common.IProps<IParams> {
}

interface IState extends common.IState {
  phone: string;
  sms: string;
}

class ResetPasswordSmsPage extends common.BasePage<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      phone: this.props.location?.state?.phone || '',
      sms: '',
    };
  }

  componentDidMount() {

  }

  render() {
    return (
      <Row>
        <Col xs={12} md={12}>
          <Jumbotron>
            <SystemNotification/>
            <Form className="mt-5">
              <Form.Group>
                <Form.Label>SMSを入力してください<span style={{ fontSize: "12px" }}></span></Form.Label>
                <Form.Control type="phone" name="sms" onChange={(e) => this.changeSms(e)} value={this.state.sms}/>
              </Form.Group>
              <Form.Group>
                <Form.Label><span style={{ fontSize: "12px" }}></span></Form.Label>
                <Button type="submit" variant="primary" size="lg" block disabled={!this.state.sms} onClick={(e) => this.sendSms(e)}>次へ</Button>
              </Form.Group>
            </Form>
            <Button type="submit" variant="primary" size="lg" block onClick={(e) => this.props.history.push('/signin')}>戻る</Button>
          </Jumbotron>
        </Col>
      </Row>
    );
  }

  private changeSms(e: any) {
    this.setState({ sms: e.target.value });
  }

  private async sendSms(e: any) {
    e.preventDefault();

    // log event
    FireBaseLogEventManager.sendLogEvent(FireBaseLogEvent.telAuthButtonClick);

    var params = {
      "member": {
        "phone_number": this.state.phone,
        "sms": this.state.sms
      }
    };
    const data = await new ResetPasswordPhoneApiClient(this.props.globalStore).post(params);

    if (data?.result === true) {
      this.props.history.push({
        pathname: `/reset_password_info`,
        state: {
                phone: this.state.phone,
                sms: this.state.sms,
              }
      });
    }
  }
}

export default withRouter(AppDataStore.withStores(ResetPasswordSmsPage));
