import React from 'react';
import { ListGroup, Button, Row, Col  } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import SettingPageHeader from './SettingPageHeader';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { UserSession } from '../../actions/session/UserSession';
import Utils from '../../utils/Utils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

interface IState extends common.IState {
}

class SettingPage extends common.BasePage<common.IProps<common.IParams>, IState> {
  settings = [
    /*
    {
      "title":"お知らせ",
      "name": ""
    },
    {
      "title":"各種設定",
      "name": ""
    },
    {
      "title": "通知設定",
      "name": ""
    },
    {
      "title":"メッセージテンプレート",
      "name": ""
    },*/
    {
      "title": "プライベートモード設定",
      "name": "privatemode"
    },
    /*
    {
      "title":"パスコード設定",
      "name": ""
    },
    {
      "title": "パスワードの変更",
      "name": ""
    },
    {
      "title":"ポイント",
      "name": ""
    },
    */
    {
      "title":"ポイント履歴",
      "name": "pointhistory"
    },
    /*
    {
      "title":"ブロック",
      "name": ""
    },*/
    {
      "title":"ブロックリスト",
      "name": "block"
    },
    /*
    {
      "title":"電話番号でブロック",
      "name": ""
    },
    {
      "title":"その他",
      "name": ""
    },
    */
    ]

  settings2 = [
    {
      "title":"会社概要",
      "name": "company"
    },
    {
      "title":"利用規約",
      "name": "rule"
    },
    {
      "title":"プライバシーポリシー",
      "name": "privacy"
    },
    {
      "title":"特定商取引法に基づく表示",
      "name": "law"
    },

    {
      "title":"退会",
      "name": "withdrawal"
    },

    {
      "title":"ログアウト",
      "name": "logout"
    },
  ]

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const myProfile = await Utils.getMyProfile(this.props.globalStore);
    //console.log(myProfile);
    this.setState({ myProfileData: myProfile });
  }

  render() {
    return (
      <React.Fragment>
        <SettingPageHeader />

        <ListGroup>
        {this.settings.map((s, index) => (
          <ListGroup.Item key={index} onClick={(e:any) => this.onSelectMenu(s)}>
          <Row>
          <Col className="setting-item-l">
           {s.title}
          </Col>
            <Col className="setting-item-r">
             <Button className="bg-dark text-light" style={{ backgroundColor: "#ffffff" }}>
               <FontAwesomeIcon icon={faChevronRight}  size="sm" style={{ color: "#A1A1A1" }}/>
             </Button>
            </Col>
            </Row>
          </ListGroup.Item>
        ))}

      {this.state.myProfileData?.sex === '男性' && (
        <ListGroup.Item onClick={() => this.onSelectSubscription()}>
        <Row>
        <Col className="setting-item-l">
          クレジットカード決済の自動更新
        </Col>
          <Col className="setting-item-r">
           <Button className="bg-dark text-light" style={{ backgroundColor: "#ffffff" }}>
             <FontAwesomeIcon icon={faChevronRight}  size="sm" style={{ color: "#A1A1A1" }}/>
           </Button>
          </Col>
          </Row>
        </ListGroup.Item>
      )}

        {this.settings2.map((s, index) => (
          <ListGroup.Item key={index} onClick={(e:any) => this.onSelectMenu(s)}>
          <Row>
          <Col className="setting-item-l">
           {s.title}
          </Col>
            <Col className="setting-item-r">
             <Button className="bg-dark text-light" style={{ backgroundColor: "#ffffff" }}>
               <FontAwesomeIcon icon={faChevronRight}  size="sm" style={{ color: "#A1A1A1" }}/>
             </Button>
            </Col>
            </Row>
          </ListGroup.Item>
        ))}

        </ListGroup>

      </React.Fragment>
    );
  }

  private onSelectMenu(s?: any) {
    //e.preventDefault();
    //console.log(s);
    switch (s['name']) {
     case "logout":
       confirmAlert({
         title: '',
         message: 'ログアウトしますか？',
         buttons: [
           {
             label: 'キャンセル',
             //onClick: () => alert('Click Yes')
           },
           {
             label: 'OK',
             onClick: () => this.signOut()
           }
         ]
       });
       break;
     case "company": // 会社概要
       window.open("https://lp.mitsumitsu-app.com/company.html");
       break;
     case "rule": // 利用客
       window.open("https://lp.mitsumitsu-app.com/rule.html");
       break;
     case "privacy": // プライバシーポリシー
       window.open("https://lp.mitsumitsu-app.com/privacy.html");
       break;
     case "law":  // 特定商取引法
       window.open("https://lp.mitsumitsu-app.com/law.html");
       break;
     default:
       this.props.history.push(s['name']);
       break;
    }
  }

  private onSelectSubscription() {
    this.props.history.push("subscription");
  }

  // ログアウト
  private async signOut() {
    const session = new UserSession(this.props.globalStore);
    await session.signOut();
    Utils.clearItems();
    this.notifier.info('ログアウトしました。');
    this.props.history.push('/signin');
  }
}

export default withRouter(AppDataStore.withStores(SettingPage));
