import React from 'react';
import { Alert } from 'react-bootstrap';
import { IMemberCondition } from '../../resources/IMemberCondition';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BaseComponent';
import MemberList from '../member/MemberList';

interface IProps extends common.IProps {
  value: IMemberCondition;
  onValueChanged: (value: IMemberCondition) => void;
}

interface IState extends common.IState {
  page: number;
  value: IMemberCondition;
}

class MemberListWizardPage extends common.BaseComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      page: 1,
      value: Object.assign({}, props.value, { limit: 5 })
    };
  }

  render() {
    return (
      <>
        <h6>アクション対象の会員を指定</h6>
        <Alert className="my-3" variant="secondary">
          アクションを行う対象の会員を設定できます。なお、アクションを実行できるかどうかはアクション対象会員のプライベートモード設定や、サクラ会員とアクション対象会員間のブロック関係によって異なるため、必ずしも全てのサクラ会員がここで指定された全ての会員に対してアクションを実行できるわけではありません。
        </Alert>
        <MemberList condition={this.state.value} hideSexCondition={true} page={this.state.page} onPageSelected={(page) => this.selectPage(page)} onSearchConditionChanged={(condition) => this.onSearchConditionChanged(condition)}/>
      </>
    );
  }

  private onSearchConditionChanged(condition: IMemberCondition) {
    this.setState({ value: condition });
    this.props.onValueChanged(condition);
  }

  private selectPage(page: number) {
    this.setState({ page });
  }
}

export default AppDataStore.withStores(MemberListWizardPage);
