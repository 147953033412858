import React from 'react';
import moment from "moment";
import { Button, Col, Form, Jumbotron, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
//import * as common from '../common/BasePage';
import * as common from '../common/BaseForm';
import SystemNotification from '../common/SystemNotification';
import ValidationErrorFeedback from '../common/ValidationErrorFeedback';
import { OptionApiClient } from '../../actions/clients/OptionApiClient';
import { UserSession } from '../../actions/session/UserSession';
import FireBaseLogEventManager from '../../utils/FireBaseLogEventManager';
import FireBaseLogEvent from '../../utils/enums/FireBaseLogEvent';
import { MemberResetPasswordApiClient } from '../../actions/clients/resetPassword/MemberResetPasswordApiClient';

interface IParams extends common.IParams {
}

interface IProps extends common.IProps<IParams> {
}

interface IState extends common.IState {
  phone: string;
  sms: string;
  resouce: {
    password: string;
    password_confirm: string;
  }
}

class ResetPasswordInfoPage extends common.BaseForm<IProps, IState> {

  private _session: UserSession;

  constructor(props: IProps) {
    super(props);

    this._session = new UserSession(this.props.globalStore);

    this.state = {
      phone: this.props.location.state.phone,
      sms: this.props.location.state.sms,
      resource: {
        password: "",
        password_confirm: "",
      }
    };
  }

  async componentDidMount() {
    if (!this.props.globalStore.get('masterData')?.option) {
      const option = await new OptionApiClient(this.props.globalStore).get();
      if (option) {
        this.props.globalStore.set('masterData')({
          isLoaded: true,
          option: option
        });
      }
    }
  }

  render() {
    return (
      <Row>
        <Col sx={12} md={12}>
          <Jumbotron>
            <SystemNotification/>
            <Form className="mt-5">
              <Form.Group>
                <Form.Label>パスワードを入力</Form.Label>
                <Form.Control type="password" name="password" onChange={(e) => this.changeResource(e)} value={this.state.resource?.password} isInvalid={!!this.state.errors?.password}/>
                <ValidationErrorFeedback errors={this.state.errors?.password}/>
              </Form.Group>

              <Form.Group>
                <Form.Label>パスワードを再入力</Form.Label>
                <Form.Control type="password" name="password_confirm" onChange={(e) => this.changeResource(e)} value={this.state.resource?.password_confirm} isInvalid={!!this.state.errors?.password_confirm}/>
                <ValidationErrorFeedback errors={this.state.errors?.password_confirm}/>
              </Form.Group>

              <Form.Group>
                <Form.Label><span style={{ fontSize: "12px" }}></span></Form.Label>
                <Button type="submit" variant="primary" size="lg" block
                  disabled={!this.state.resource?.password || !this.state.resource?.password_confirm}
                   onClick={(e) => this.handleSubmit(e)}>登録</Button>
              </Form.Group>
            </Form>
            <Button type="submit" variant="primary" size="lg" block onClick={(e) => this.props.history.push('/signin')}>戻る</Button>
          </Jumbotron>
        </Col>
      </Row>
    );
  }

  private async handleSubmit(e: any) {
    e.preventDefault();
    console.log(this.state)

    // log event
    FireBaseLogEventManager.sendLogEvent(FireBaseLogEvent.signUpCompleteButtonClick);

    var params = {
      "member": {
        "phone_number": this.state.phone,
        "sms": this.state.sms,
        "password": this.state.resource.password,
        "password_confirmation": this.state.resource.password_confirm
      }
    };

    const data = await new MemberResetPasswordApiClient(this.props.globalStore).updateWithoutID(params);
    console.log('data', data)
    if (data?.result === true) {
      this.goSigninPage();
    }
  }

  private async goSigninPage() {
    this.notifier.info('パスワードを再設定しました');
    this.props.history.push('/signin');
  }
}

export default withRouter(AppDataStore.withStores(ResetPasswordInfoPage));
