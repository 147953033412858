import React from 'react';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BaseForm';
import MyProfileImagePageHeader from './MyProfileImagePageHeader';
import { ProfileImageApiClient } from '../../actions/clients/profile/ProfileImageApiClient';
import Swal from 'sweetalert2'
// https://sweetalert2.github.io/
import 'react-image-crop/dist/ReactCrop.css'
//https://codesandbox.io/s/vmvjl2q023?file=/src/index.js:59-165
import ImageCropper from '../imagecropper/ImageCropper';

interface IState extends common.IState {
  isDialogOpen: boolean;
}

class MyProfileImagePage extends common.BaseForm<IMember, IProps, IState> {

  constructor(props: common.IProps<common.IParams>) {
    super(props);
    this.state = { isDialogOpen: false, isMemberDialogOpen: false };
  }

  async componentDidMount() {
  }

  render() {
    return (
      <React.Fragment>
        <MyProfileImagePageHeader onPreview={this.onPreview} />

        <div style={{ height: 10 }}></div>

          <ImageCropper  imageToCrop={this.state.src}
            onPostImageUrl={(imageUrl: any) => this.updateImage(imageUrl)}
          />
      </React.Fragment>
    );
  }

  // メイン画像更新
  async updateImage(imageUrl: any) {
    const globalStore = this.props.globalStore
    const props = this.props

    var xhr = new XMLHttpRequest;
    xhr.responseType = 'blob';

    xhr.onload = function() {
       var recoveredBlob = xhr.response;

       var reader = new FileReader;

       reader.onload = function() {
         var blobAsData = reader.result;

         var params = {
           "member": {
             "image": blobAsData.replace("data:image/jpeg;base64,", "")
           }
         };
         const data = new ProfileImageApiClient(globalStore).update(params);
         //console.log(data);

         Swal.fire({
           title: '',
           text: "画像をアップロードしました",
           type: 'success',
         }).then((result) => {
           props.history.push('/myprofile');
         })

       };
       reader.readAsDataURL(recoveredBlob);
    };

    xhr.open('GET', imageUrl);
    xhr.send();
  }

}

export default withRouter(AppDataStore.withStores(MyProfileImagePage));
