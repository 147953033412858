import React from 'react';
import { Row, Col, Image, DropdownButton, Dropdown, Nav, Navbar, Button, Modal, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AppDataStore from '../../stores/AppDataStore';
import * as common from '../common/BasePage';
import { MemberDetailApiClient } from '../../actions/clients/MemberDetailApiClient';
import { FootprintApiClient } from '../../actions/clients/FootprintApiClient';
import { FavoriteApiClient } from '../../actions/clients/FavoriteApiClient';
import { OfferApiClient } from '../../actions/clients/OfferApiClient';
import { BlockAddApiClient } from '../../actions/clients/BlockAddApiClient';
import { OffersMatchedApiClient } from '../../actions/clients/OffersMatchedApiClient';
import { WatchThisApiClient } from '../../actions/clients/WatchThisApiClient';
import { ViolationCategoriesApiClient } from '../../actions/clients/ViolationCategoriesApiClient';
import { ViolationReportApiClient } from '../../actions/clients/ViolationReportApiClient';

import Utils from '../../utils/Utils';
import Slick from "react-slick";
import nl2br from 'react-nl2br';
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faBars } from "@fortawesome/free-solid-svg-icons";
import ActionButton from '../common/ActionButton';
import { OfferRelationConverter } from '../../converters/OfferRelationConverter';
import WatchThisPopUp from './WatchThisPopUp'
import OfferWithMessagePopUp from './OfferWithMessagePopUp'
import FireBaseLogEventManager from '../../utils/FireBaseLogEventManager';
import FireBaseLogEvent from '../../utils/enums/FireBaseLogEvent';
import GeneralDataSelector from '../common/form/GeneralDataSelector';

interface IParams extends common.IParams {
}

class MemberDetailPage extends common.BasePage<common.IProps<IParams>, common.IState> {

  settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  constructor(props: common.IProps<IParams>) {
    super(props);
    this.state = { profileData: props.location.state?.member, report_show: false , violation_category_id: 0, content: "" };
  }

  async componentDidMount() {
    const profile = await new MemberDetailApiClient(this.props.globalStore, this.props.match.params.id).get();
    this.setState({ profileData: profile });
    Utils.setItem("selectedMemberId", profile.id);

    const myProfile = Utils.loadMyProfile();

    if(parseInt(this.props.match.params.id) !== parseInt(myProfile.id)) {
      new FootprintApiClient(this.props.globalStore, this.props.match.params.id).post({});
    }

    const violation_categories = await new ViolationCategoriesApiClient(this.props.globalStore).get();
    this.setState({ violation_categories: violation_categories});
  }

  render() {
    return (
      <React.Fragment>

        <Navbar className="justify-content-center" fixed="top" bg="dark" variant="dark">
          <Navbar.Collapse className="container-fluid">
            <Nav>
              <Button onClick={(e: any) => window.history.back()} className="bg-dark">
                <FontAwesomeIcon icon={faChevronLeft} size="sm"  style={{ color: "#A1A1A1" }}/>
              </Button>
              {this.menu_button()}
            </Nav>
          </Navbar.Collapse>
          <Navbar.Brand><span style={{ fontSize: "12px" }}>{this.state.profileData?.name}</span></Navbar.Brand>
          <Navbar.Collapse className="container-fluid">
            <Nav className="ml-auto">
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <ul className="slider">
          <Slick {...this.settings}>

            {this.state.profileData?.profile_image && <li><center><Image src={Utils.toImageUrl(this.state.profileData?.profile_image)} style={{ height: "auto" }} /></center></li> }

            {this.state.profileData?.sub_image?.map((image, index) => (
              <li key={index}><center><Image src={Utils.toImageUrl(image)} style={{ height: "auto" }} /></center></li>
            ))}

          </Slick>
        </ul>

        <div style={{ height: 10 }}></div>

        <Row>
          {this.state.profileData?.profile_image && <Col width={80} height={80}><center><Image width={60} height={60} src={Utils.toImageUrl(this.state.profileData?.profile_image)} /></center></Col> }
          {this.state.profileData?.sub_image?.map((image, index) => (
          <Col width={80} height={80} key={index}>
            <center><Image width={60} style={{ height: "auto" }} src={Utils.toImageUrl(image)} /></center>
          </Col>
          ))}
        </Row>

        <Row className="member-profile-row">
           <Col xs={8} md={8}>
            {this.state.profileData?.age}歳 {this.state.profileData?.prefecture}
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
            {this.state.profileData?.login_status}
           </Col>
        </Row>
        <Row className="member-profile-row">
           <Col xs={4} md={4}>
            自己紹介
           </Col>
           <Col xs={8} md={8} className="member-profile-col-r">
            {nl2br(this.state.profileData?.introduction)}
           </Col>
        </Row>
        <Row className="member-profile-row">
           <Col xs={4} md={4}>
            つぶやき
           </Col>
           <Col xs={8} md={8} className="member-profile-col-r">
            {nl2br(this.state.profileData?.tweet)}
           </Col>
        </Row>
        <Row className="member-profile-row">
           <Col xs={8} md={8}>
            身長
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
            {this.state.profileData?.height}
           </Col>
        </Row>
        <Row className="member-profile-row">
           <Col xs={8} md={8}>
            体型
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
            {this.state.profileData?.body_type}
           </Col>
        </Row>
        <Row className="member-profile-row">
           <Col xs={8} md={8}>
            住んでいる地域
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
            {this.state.profileData?.prefecture}
           </Col>
        </Row>
        <Row className="member-profile-row">
           <Col xs={8} md={8}>
            出身地
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
            {this.state.profileData?.hometown}
           </Col>
        </Row>
        <Row className="member-profile-row">
           <Col xs={8} md={8}>
            会いたい場所
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
            {this.state.profileData?.desired_meeting_place}
           </Col>
        </Row>
        <Row className="member-profile-row">
           <Col xs={8} md={8}>
            希望のデートプラン
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
            {this.state.profileData?.desired_dating_plan}
           </Col>
        </Row>
        { this.annual_income() }
        <Row className="member-profile-row">
           <Col xs={8} md={8}>
            お酒
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
            {this.state.profileData?.drinking_type}
           </Col>
        </Row>
        <Row className="member-profile-row">
           <Col xs={8} md={8}>
            タバコ
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
            {this.state.profileData?.smoking_type}
           </Col>
        </Row>
        <Row className="member-profile-row">
           <Col xs={8} md={8}>
            職業
           </Col>
           <Col xs={4} md={4} className="member-profile-col-r">
            {this.state.profileData?.occupation}
           </Col>
        </Row>
        <center>
          { this.offer_button() }
        </center>
        <div style={{ height: 30 }}></div>

        <Modal show={this.state.report_show}>
          <Modal.Header closeButton onClick={() => this.handleReportClose()} style={{ color: "#ffffff" , backgroundColor: "#000000", border: "1px solid #ced4da" }}>
            <Modal.Title className="member-profile-row" style={{ color: "#ffffff" }}>違反報告</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ testAlign: "center", color: "#ffffff", backgroundColor: "#000000", border: "1px solid #ced4da" }} className="dark">
            <center>
            {this.state.profileData?.profile_image && <Col width={80} height={80}><center><Image width={60} height={60} src={Utils.toImageUrl(this.state.profileData?.profile_image)} /></center></Col> }
              {this.state.profileData?.name}&emsp;{this.state.profileData?.age}歳<br /><br />
              MITSUMITSUの利用規約に違反した事項について<br />以下の内容を入力してください。
              <div id="login_duration_description" dangerouslySetInnerHTML={{ __html: this.messageText }}></div>
            </center>
            <Row className="member-profile-row-item">
               <Col xs={12} md={12}>
                違反報告カテゴリ
               </Col>
            </Row>
            <Row className="member-profile-row-item">
               <Col xs={12} md={12} className="member-profile-col-r">
                <GeneralDataSelector property="violation_category_id"
                data={this.state.violation_categories}
                value={this.state.violation_category_id}
                errors={this.state.errors?.violation_category_id} onChanged={(e) => this.changeResource(e, { type: 'number' })}/>
               </Col>
            </Row>
            <Row className="member-profile-row-item">
               <Col xs={12} md={12}>
                お問い合わせ内容
               </Col>
            </Row>
            <Row className="member-profile-row-item">
            <Form.Control as="textarea" rows={10} name="violation_content" className="bg-dark text-light"
              onChange={(e) => this.changeResource(e)}
              value={this.state.violation_content}
              isInvalid={!!this.state.errors?.violation_content}/>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <center>
                  <Button type="button" variant="secondary"
                    onClick={() => this.handleReportClose()}>キャンセル</Button>
                  <Button type="button"
                    disabled={!(this.state.violation_category_id > 0) || !this.state.violation_content}
                    onClick={(e: any) => this.submitViolation(e)}>送信</Button>
                </center>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

      </React.Fragment>
    );
  }

  private menu_button = () => {
    if (!this.state.profileData?.id) {
      return (<span></span>);
    }
    const myProfile = Utils.loadMyProfile();
    if(parseInt(this.props.match.params.id) === parseInt(myProfile.id)) {
      return (<span></span>);
    }

    // 他メンバー
    if(parseInt(this.props.match.params.id) !== parseInt(myProfile.id)) {
      return (
        <DropdownButton id="dropdown-basic-button" title=" ≡ ">
          <Dropdown.Item onClick={(e: any) => this.addFavorite(e)}>{ this.favorite_title() }</Dropdown.Item>
          <Dropdown.Item onClick={(e: any) => this.showReport(e)}>違反報告する</Dropdown.Item>
          <Dropdown.Item onClick={(e: any) => this.block(e)}>ブロックする</Dropdown.Item>
        </DropdownButton>
      );
    }
    return (<span></span>);
  }

  private offer_button = () => {
    // データなし
    if (!this.state.profileData?.id) {
      return (<span></span>);
    }
    // チャットルーム
    if(this.props.location.pathname.startsWith('/rooms/')) {
      return (<ActionButton style={{ width: "1000" }} onClick={() => this.handleClose()}>閉じる</ActionButton>);
    }

    const myProfile = Utils.loadMyProfile();

    // 他メンバー
    if (this.state.profileData?.id !== myProfile.id) {
      const buttonTitle = OfferRelationConverter.convert(this.state.profileData?.offer_relation);

      switch(this.state.profileData?.offer_relation) {
        case 0:
          return (<>
                    <ActionButton style={{ width: "1000" }} onClick={(e: any) => this.submit(e)}>{buttonTitle}</ActionButton>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <OfferWithMessagePopUp onOfferWithMessage={(message: string) => this.onOfferWithMessage(message)} />
                  </>
                  );

        case 1: //'みてね';
          return (<WatchThisPopUp onWatchThis={(message: string) => this.onWatchThis(message)} />);

        case 2: // OKする
          return (<ActionButton style={{ width: "1000" }} onClick={(e: any) => this.submit(e)}>{buttonTitle}</ActionButton>);

         case 3: // メッセージを送る
          return (<ActionButton style={{ width: "1000" }} onClick={(e: any) => this.submit(e)}>{buttonTitle}</ActionButton>);
        default:
          break;
      }
    }

    // 自分のプロフィールなど
    return (<span></span>);
  }

  private async submit(event: any) {
    //console.log(this.state.profileData);
    //console.log(OfferRelationConverter.convert(this.state.profileData?.offer_relation));

    switch(this.state.profileData?.offer_relation) {
      case 0: // 'いいね';

        // log event
        FireBaseLogEventManager.sendLogEvent(FireBaseLogEvent.offerButtonClick);

        var params = {
          "offer": {
            "meeting_type": 0
          }
        };
        const api  = new OfferApiClient(this.props.globalStore, this.state.profileData?.id);
        try {
          const data = await api.post(params);
          // プロフィール取り直し
          const profile = await new MemberDetailApiClient(this.props.globalStore, this.props.match.params.id).get();
          this.setState({ profileData: profile });

          if (data.result === true) {
              Swal.fire({
                title: '',
                text: "いいねを送信しました",
                type: 'success',
              }, async function(){
                const profile = await new MemberDetailApiClient(this.props.globalStore, this.props.match.params.id).get();
                this.setState({ profileData: profile });
              });
          } else {　// ここには行かないので catch 以下で処理を行う
            Swal.fire({
              title: '',
              text: data.error.message,
              type: 'error',
            })
          }
        } catch(e) {
          try {
            Swal.fire({
              title: '',
              text: this.props.globalStore.state.notifications[0].message,
              type: 'error',
            })
          } catch(e) {
            Swal.fire({
              title: '',
              text: 'エラーが発生しました',
              type: 'error',
            })
          }
          //console.log(api);
        }
      case 1:
        //return 'みてね';
        break;
      case 2:
        //return 'OKする'
        //console.log(this.state.profileData);
        try {
          const data = await new OffersMatchedApiClient(this.props.globalStore, this.state.profileData?.offer?.id).post({});
          //console.log(data);
          // プロフィール取り直し
          const profile = await new MemberDetailApiClient(this.props.globalStore, this.props.match.params.id).get();
          this.setState({ profileData: profile });
          //console.log(profile);

          if (data.result === true) {
              Swal.fire({
                title: '',
                text: "マッチングが成立しました",
                type: 'success',
              }, async function(){
                const profile = await new MemberDetailApiClient(this.props.globalStore, this.props.match.params.id).get();
                this.setState({ profileData: profile });
              });
          } else {　// ここには行かないので catch 以下で処理を行う
            Swal.fire({
              title: '',
              text: data.error.message,
              type: 'error',
            })
          }
        } catch(e) {
          try {
            Swal.fire({
              title: '',
              text: this.props.globalStore.state.notifications[0].message,
              type: 'error',
            })
          } catch(e) {
            Swal.fire({
              title: '',
              text: 'エラーが発生しました',
              type: 'error',
            })
          }
          //console.log(api);
        }
        break;
      case 3: //'メッセージを送る';
        const room = this.state.profileData?.room;
        this.props.history.push({
          pathname: `/rooms/${room.id}`,
          state: { room: room }
        });
        break;
      default:
        break;
    }
  }

  // メッセージ付きいいね
  private async onOfferWithMessage(message: string) {
    var params = {
      "offer": {
        "meeting_type": 0,
        "message": message
      }
    };
    const api  = new OfferApiClient(this.props.globalStore, this.state.profileData?.id);
    try {
      const data = await api.post(params);
      // プロフィール取り直し
      const profile = await new MemberDetailApiClient(this.props.globalStore, this.props.match.params.id).get();
      this.setState({ profileData: profile });

      if (data.result === true) {
          Swal.fire({
            title: '',
            text: "メッセージ付きいいねを送信しました",
            type: 'success',
          }, async function(){
            const profile = await new MemberDetailApiClient(this.props.globalStore, this.props.match.params.id).get();
            this.setState({ profileData: profile });
          });
      } else {　// ここには行かないので catch 以下で処理を行う
        Swal.fire({
          title: '',
          text: data.error.message,
          type: 'error',
        })
      }
    } catch(e) {
      try {
        Swal.fire({
          title: '',
          text: this.props.globalStore.state.notifications[0].message,
          type: 'error',
        })
      } catch(e) {
        Swal.fire({
          title: '',
          text: 'エラーが発生しました',
          type: 'error',
        })
      }
    }
  }

  // みてね
  private async onWatchThis(message: string) {
    var params = {
      "offer": {
        "additional_message": message
      }
    };
    const api  = new WatchThisApiClient(this.props.globalStore, this.state.profileData?.offer?.id);

    try {
      const data = await api.update(params);
      //console.log(data);
      Swal.fire({
        title: '',
        text: "みてね！を送信しました",
        type: 'success',
      }, async function(){
        const profile = await new MemberDetailApiClient(this.props.globalStore, this.props.match.params.id).get();
        this.setState({ profileData: profile });
      });
    } catch(e) { }
  }

  private annual_income = () => {
    if (this.state.profileData?.sex === '男性') {
      return (
        <Row className="member-profile-row">
           <Col xs={4} md={4}>
            年収
           </Col>
           <Col xs={8} md={8} className="member-profile-col-r">
            {this.state.profileData?.annual_income}
           </Col>
        </Row>
      );
    }
    return (<span></span>);
  }

  private favorite_title = () => {
    return this.state.profileData?.is_favorite === true ? "お気に入り削除" : "お気に入り追加"
  }

  // お気に入り追加
  private async addFavorite(e: any) {
    if (this.state.profileData?.is_favorite === true) {
      await new FavoriteApiClient(this.props.globalStore, this.state.profileData?.id).delete();
      this.opendeletealert();
    } else {
      await new FavoriteApiClient(this.props.globalStore, this.state.profileData?.id).save({});
      this.opensweetalert();
    }
    const profile = await new MemberDetailApiClient(this.props.globalStore, this.state.profileData?.id).get();
    this.setState({ profileData: profile });
  }

  opensweetalert()
  {
    Swal.fire({
      title: '',
      text: "お気に入りに追加しました",
      type: 'success',
    })
  }

  opendeletealert()
  {
    Swal.fire({
      title: '',
      text: "お気に入りから削除しました",
      type: 'success',
    })
  }

  openblockalert()
  {
    Swal.fire({
      title: '',
      text: "ブロックしました",
      type: 'success',
    }).then((result) => {
      //this.props.onReload?.();
      Utils. clearLists();
      window.history.back();
    })
  }

  private async block(e: any) {
    await new BlockAddApiClient(this.props.globalStore, this.state.profileData?.id).save({});
    this.openblockalert();
  }

  private showReport(e: any) {
    this.setState({ report_show: true });
  }

  private handleReportClose() {
    this.setState({ report_show: false });
  }

  protected changeResource(e: any, options?: InputEventReflectorOptions) {
    // 変数名なので[]をつける
    this.setState({ [e.target.name] : e.target.value });

  }

  protected async submitViolation(e: any) {
    console.log(this.state);

      var params = {
        "violation_report": {
          "violation_category_id": this.state.violation_category_id,
          "content": this.state.violation_content
        }
      };
      const api = new ViolationReportApiClient(this.props.globalStore, this.props.match.params.id);

      try {
        const data = await api.post(params);
        console.log(data);

        this.handleReportClose();
        this.setState({ violation_category_id: 0 });
        this.setState({ violation_content: "" });

        Swal.fire({
          title: '',
          text: "違反報告を送信しました",
          type: 'success',
        }, async function(){
          const profile = await new MemberDetailApiClient(this.props.globalStore, this.props.match.params.id).get();
          this.setState({ profileData: profile });
        });
      } catch(e) { }
  }

}

export default withRouter(AppDataStore.withStores(MemberDetailPage));
