import * as common from '../common/BaseListComponent';
import AppDataStore from '../../stores/AppDataStore';
import { BaseApiClient } from '../../actions/clients/BaseApiClient';
import { Image, Row, Col, Button } from 'react-bootstrap';
import LoadingIndicator from '../common/LoadingIndicator';
import Paginator from '../common/Paginator';
import { IRoom } from '../../resources/IRoom';
import { MessageRoomsApiClient } from '../../actions/clients/MessageRoomsApiClient';
import Utils from '../../utils/Utils';
import ReactHtmlParser from 'react-html-parser';

interface IProps extends common.IProps<IRoom> {
}

interface IState extends common.IState<IRoom> {
  condition: IOfferCondition;
}

class RoomList extends common.BaseListComponent<IOffer, IProps, IState> {
  private status: String = "";
  private storageKey: String = "";  // JSON保存のキー

  constructor(props: IProps) {
    super(props);

    this.state = {};
    this.status = props["status"]
    this.storageKey = `room_datalist_${this.props.eventKey}`
  }

  async componentDidMount() {
    // 戻ってきた場合は保存されたJSONで一覧を表示
    const json = Utils.getJson(this.storageKey);
    if ( Object.keys(json).length > 0 ) {
      this.setResponse(json);
    } else {
      await super.componentDidMount();
    }
  }

  setResponse(json: any) {
    if (json === null) return;
    this.setState({ "response": json.response });
    Utils.removeJson(this.storageKey)
  }

  private showUnreadBadge(room) {
    if( room.unread_count > 0 ) {
      try{
        return ReactHtmlParser('<p class="room_unread_badge">' + room.unread_count + '</p>');
      } catch(e) {
        console.log(e);
      }
    }
    return "";
  }

  render() {
    //console.log(this.state.response);
    return (
      <>
        <LoadingIndicator watch={this.state.response}>

          <div style={{ height: 10 }}></div>

          {this.state.response?.data.map((room, index) => (

            <article class="article_user-like" key={index}>
              <div class="wrapper">
                <ul class="article_lists">
                  <li class="article_list">
                    <div class="article_list_thumb article_list_thumb">
                      <div class=" middle">
                        <a onClick={() => this.onMessageSelected(room)}>
                        <Image style={{ height: "auto" }}
                             src={Utils.toImageUrl(room.image)} id={room.id}
                             onClick={() => this.onMessageSelected(room)} />
                        </a>
                    </div>
                    </div>
                    <div class="article_list_content article_list_content">
                      <i class="content_time ">{room.time}</i>
                      <div class="article_list_content_info">
                        <p class="content_name">
                        &nbsp;&nbsp;{room.member?.name}&nbsp;&nbsp;{room.member?.age}歳&nbsp;&nbsp;{room.member?.height}cm &nbsp;&nbsp;&nbsp;&nbsp;{this.showUnreadBadge(room)}
                        </p>
                      </div>
                      <div class="article_list_content_btns">
                        <div class="flex" style={{ justifyContent: "flex-end" }}>
                            <p class="like_thanks">
                              <Button style={{ width: "100%", fontSize: "10px" }}
                               onClick={(e:any) => this.onMessageSelected(room)}>
                                メッセージを送る
                              </Button>
                            </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </article>

          ))}

          {this.state.response?.data.length === 0 && (
            <Row>
              <Col xs={2} md={2}></Col>
              <Col className="nodata" xs={8} md={8}>データが見つかりません。</Col>
              <Col xs={2} md={2}></Col>
            </Row>
          )}

          <Paginator currentPage={this.state.response?.meta.current_page} totalPage={this.state.response?.meta.total_pages} onPageSelected={(page) => this.selectPage(page)}/>
          <div style={{ height: 40 }}></div>
        </LoadingIndicator>
      </>
    );
  }

  protected onMessageSelected(room: IRoom) {
    Utils.setItem("eventKeyRoom", this.props.eventKey);

    // 戻った場合に再建策しないように
    Utils.setJson(this.storageKey, this.state);

    this.props.onMessageSelected(room);
  }

  protected createClient(): BaseApiClient<IOffer> {
    return new MessageRoomsApiClient(this.props.globalStore, this.status);
  }

  protected buildSearchCondition(page?: number) {
    return Object.assign({}, this.state.condition, { page: page });
  }
/*
  private async accept(offer: IOffer) {
    await this.client.update(offer);
    await this.loadResources(this.props.page);
  }

  private async search(condition: IOfferCondition) {
    this.setState({ condition });
    await this.loadResources(1);
  }
*/
}

export default AppDataStore.withStores(RoomList);
